import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { UnknownObject } from '../../../../../react-app-env';
import { pluralise } from '../../../../../utils/stringUtils';

import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../../layouts/General';
import ReadOnlyBlock from '../../../../../components/ReadOnlyBlock';

import { selectLabUserInitQuery, LabUser, LabAssignment, Lab } from './queries';


const ViewLabUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const { lab_id, lab_user_id } = useParams();

  const [lab, setLab] = useState<Lab | UnknownObject>({});
  const [user, setUser] = useState<LabUser | UnknownObject>({});
  const [assignedLabs, setAssignedLabs] = useState<string[]>([]);

  const { data: labUserInitQuery } = useQuery(selectLabUserInitQuery(lab_id!, lab_user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && labUserInitQuery) {
      setLab(labUserInitQuery.labs_by_pk);
      setUser(labUserInitQuery.users_receiver_by_pk);
      setAssignedLabs(labUserInitQuery.users_receiver_by_pk.labs.map((i: LabAssignment) => i.lab.name));
    }
    return () => { mounted = false; };
  }, [labUserInitQuery]);

  const handleEdit = () => {
    navigate(`/admin/labs/${lab_id}/users/${lab_user_id}/edit`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Labs', link: '/admin/labs' },
        { label: lab.name, link: `/admin/labs/${lab_id}` },
        { label: 'Users', link: `/admin/labs/${lab_id}/users` },
        { label: user.full_name },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <>
            <ReadOnlyBlock
              items={[
                { label: 'Name', value: user.full_name },
                { label: 'Email', value: user.email },
                { label: 'User role', value: `${user.enum_receiver_type?.description}` },
                { label: `${assignedLabs.length} ${pluralise('Assigned Lab', assignedLabs.length)}`, list: assignedLabs }
              ]}
              handleEdit={handleEdit} />
          </>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ViewLabUser;
