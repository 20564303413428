import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

const insertSenderUserMutation = gql`
  mutation ($object: users_sender_insert_input!) {
    insert_users_sender_one(object: $object) {
      id
    }
  }
`;

const insertUserOrganisationAssignmentMutation = gql`
  mutation ($assignments: [user_organisation_assignments_insert_input!]!) {
    insert_user_organisation_assignments(objects: $assignments) {
      returning {
        id
      }
    }
  }
`;

export const createSenderUser = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_users_sender_one: {
      id: string,
    }
  }>({ mutation: insertSenderUserMutation!, variables });
  return res.data?.insert_users_sender_one.id;
}

export const createUserOrganisationAssignments = async (assignments: object[]) => {
  return await gqlClient.mutate<{
    insert_user_organisation_assignments: {
      returning: [{
        id: string,
      }],
    }
  }>({ mutation: insertUserOrganisationAssignmentMutation!, variables: { assignments } });
}

