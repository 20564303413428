import { types, query, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import gqlClient, { getWhereKeyValue } from '../../../../../utils/apolloClient';
import { UnknownObject } from '../../../../../react-app-env';

const _getCaseQuery = (where: UnknownObject) => ({
  cases: params({ where }, [
    {
      id: types.string,
      accession_id: types.string,
      created_at: types.string,
      mrn: types.string,
      note: types.string,
      enum_case_priority: {
        description: types.string,
      },
      patient: {
        id: types.string,
        nhs_id: types.string,
        dob: types.string,
      },
      organisation: {
        id: types.string,
        name: types.string,
      },
      lab: {
        id: types.string,
        name: types.string,
      },
      surgical_pathway_version: {
        surgical_pathways: {
          name: types.string,
          category: {
            icon: types.string,
          },
        },
      },
      surgical_pathways: types.string,
      pots: params({ order_by: { name: 'asc' } }, [
        {
          id: types.string,
          name: types.string,
          pathway: types.string,
          note: types.string,
          queries: params({ where: { reply_to_id: { _is_null: true } } }, [
            {
              message: types.string,
            },
          ]),
          enum_pot_status: {
            status: types.string,
            description: types.string,
          },
        },
      ]),
      case_events: params(
        {
          limit: 1,
          where: { event_type: { _eq: 'CASE_DATA' } },
        },
        [
          {
            event_data: types.custom<{ patient: { first_name: string; last_name: string } }>(),
          },
        ]
      ),
    },
  ]),
});

export const getCaseQuery = async (queries: UnknownObject[]) => {
  const where = {
    _or: queries.map((q) => getWhereKeyValue(q.field, q.value)),
  };

  const res = await gqlClient.query<{
    cases: [
      {
        id: string;
        accession_id: string;
        created_at: string;
        mrn: string;
        enum_case_priority: {
          description: string;
        };
        patient: {
          id: string;
          nhs_id: string;
          dob: string;
        };
        organisation: {
          id: string;
          name: string;
        };
        lab: {
          id: string;
          name: string;
        };
        surgical_pathway_version: {
          surgical_pathways: {
            name: string;
            category: {
              icon: string;
            };
          };
        };
        surgical_pathways: string;
        pots: [
          {
            id: string;
            name: string;
            pathway: string;
            enum_pot_status: {
              status: string;
              description: string;
            };
          }
        ];
      }
    ];
  }>({
    query: gql`
      ${query(_getCaseQuery(where))}
    `!,
  });
  return res.data.cases[0];
};

const _caseCheckInInitQuery = {
  enum_pot_status: [
    {
      status: types.string,
      description: types.string,
    },
  ],
};

export const caseCheckInInitQuery = gql`
  ${query(_caseCheckInInitQuery)}
`;
