import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../react-app-env';
import { pluralise } from '../../../../../utils/stringUtils';
import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../../layouts/General';
import ReadOnlyBlock from '../../../../../components/ReadOnlyBlock';

import { selectOrganisationUserInitQuery, OrganisationUser, OrganisationAssignment, Organisation } from './queries';


const ViewOrganisationUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const { organisation_id, organisation_user_id } = useParams();

  const [organisation, setOrganisation] = useState<Organisation | UnknownObject>({});
  const [user, setUser] = useState<OrganisationUser | UnknownObject>({});
  const [assignedOrganisations, setAssignedOrganisations] = useState<string[]>([]);

  const { data: organisationUserInitQuery } = useQuery(selectOrganisationUserInitQuery(organisation_id!, organisation_user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setOrganisation(organisationUserInitQuery.organisations_by_pk);
      setUser(organisationUserInitQuery.users_sender_by_pk);
      setAssignedOrganisations(organisationUserInitQuery.users_sender_by_pk.organisations.map((i: OrganisationAssignment) => i.organisation.name));
    }
    return () => { mounted = false; };
  }, [organisationUserInitQuery]);

  const handleEdit = () => {
    navigate(`/admin/organisations/${organisation_id}/users/${organisation_user_id}/edit`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Users', link: `/admin/organisations/${organisation_id}/users` },
        { label: user.full_name },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <>
            <ReadOnlyBlock
              items={[
                { label: 'Name', value: user.full_name },
                { label: 'Email', value: user.email },
                { label: 'User role', value: `${user.enum_sender_type?.description}` },
                { label: `${assignedOrganisations.length} ${pluralise('Assigned organisation', assignedOrganisations.length)}`, list: assignedOrganisations }
              ]}
              handleEdit={handleEdit} />
          </>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ViewOrganisationUser;
