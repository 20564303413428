
import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _labsInitQuery = {
  labs: params(
    { order_by: { name: 'asc' }},
    [{
      id: types.string,
      name: types.string,
      cases_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }],
  )
};

type X = typeof _labsInitQuery
export type Lab = X['labs'][0];

export const labsInitQuery = gql`${query(_labsInitQuery)}`;
