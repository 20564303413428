import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import GridCard from '../../../../components/Grid/GridCard';
import GridActionCard from '../../../../components/Grid/GridActionCard';

import { labsInitQuery, Lab } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const Labs = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const [labs, setLabs] = useState<Lab[]>([]);

  const { data: labsInitData } = useQuery(labsInitQuery);

  useEffect(() => {
    let mounted = true;
    if (mounted && labsInitData) {
      setLabs(labsInitData.labs);
    }
    return () => { mounted = false; };
  }, [labsInitData]);

  const handleCreate = () => {
    navigate('/admin/labs/create');
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/labs/${id}/edit`);
  };

  const handleView = (id: string) => {
    navigate(`/admin/labs/${id}`);
  };

  return (
    <GeneralLayout currentPage="Labs">
      <Grid container spacing={4} alignItems="stretch">
        <GridActionCard
          fill
          gridSize={4}
          onClick={handleCreate} />
        {labs.map((lab: Lab) => (
          <GridCard
            key={lab.id}
            gridSize={4}
            title={lab.name}
            counter={lab.cases_aggregate.aggregate.count}
            counterLabel="Cases"
            handleEdit={() => handleEdit(lab.id)}
            handleView={() => handleView(lab.id)} />
        ))}
      </Grid>
    </GeneralLayout>
  );
};

export default withStyles(styles)(Labs);
