import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../react-app-env';
import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';

import GeneralLayout from '../../../../../layouts/General';

import CustomBackdrop from '../../../../../components/CustomBackdrop';
import { receiverUserReducer } from '../Common';
import Steps from '../Common/components/Steps';

import styles from './styles';
import { createLabUserInitQuery, EnumReceiverType, Lab } from './queries';
import { createLabUser, createUserLabAssignments } from './mutations';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}


const CreateLabUser = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { lab_id } = useParams();

  const [lab, setLab] = useState<Lab | UnknownObject>({});

  const [user, dispatchUser] = useReducer(receiverUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    receiver_type: '',
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [labs, setLabs] = useState<Lab[]>([]);
  const [assignedLabs, setAssignedLabs] = useState<Lab[]>([]);
  const [receiverTypes, setReceiverTypes] = useState<EnumReceiverType[]>([]);

  const { data: createLabUserInitData } = useQuery(createLabUserInitQuery(lab_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createLabUserInitData) {
      setLab(createLabUserInitData.labs_by_pk);
      setReceiverTypes(createLabUserInitData.enum_receiver_types);
      setLabs(createLabUserInitData.labs);
      const assignedLab = createLabUserInitData.labs.find((i: Lab) => i.id === lab_id);
      if (assignedLab) {
        setAssignedLabs([assignedLab]);
      }
    }
    return () => { mounted = false; };
  }, [createLabUserInitData, lab_id]);

  const handleSave = async () => {
    setSaving(true);

    const createVariables = {
      object: user,
    };
    const user_id: string | undefined = await createLabUser(createVariables);
    if (user_id) {
      if (assignedLabs.length > 0) {
        const organisationLabAssignments = assignedLabs.map((lab: Lab) => ({ lab_id: lab.id, user_id }));
        await createUserLabAssignments(organisationLabAssignments);
      }
      setSaving(false);
      handleFinish(user_id);
    }
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/labs/${lab_id}/users/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Labs', link: '/admin/labs' },
          { label: lab.name, link: `/admin/labs/${lab_id}` },
          { label: 'Users', link: `/admin/labs/${lab_id}/users` },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          user={user}
          userTypes={receiverTypes}
          labs={labs.map((i: Lab) => ({ id: i.id, label: i.name }))}
          assignedLabs={assignedLabs.map((i: Lab) => ({ id: i.id, label: i.name }))}
          setAssignedLabs={setAssignedLabs}
          dispatch={dispatchUser}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default withStyles(styles)(CreateLabUser);
