import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import CustomTransferList, { CustomTransferListItem } from '../../../../../../components/CustomTransferList';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  organisations: CustomTransferListItem[],
  assignedOrganisations: CustomTransferListItem[],
  handleOrganisationAssignment: (left: CustomTransferListItem[], right: CustomTransferListItem[]) => void,
}

const StepOrganisationAssignment = ({ classes, organisations, assignedOrganisations, handleOrganisationAssignment }: Props): React.ReactElement => {
  return (
    <>
      <CustomTransferList
        labelLeft="Unassigned Organisation"
        labelRight="Assigned Organisation"
        description="Assign organisations to the lab that it will receive cases from"
        allItems={organisations}
        currentItems={assignedOrganisations}
        handleTransfer={handleOrganisationAssignment} />
    </>
  );
};

export default withStyles(styles)(StepOrganisationAssignment);
