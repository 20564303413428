import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import CustomStepper from '../../../../../../components/CustomStepper';
import { CustomTransferListItem } from '../../../../../../components/CustomTransferList';
import { Lab, validationErrors, ValidationErrors, Organisation, LabReducerAction } from '../../../Common';

import StepDetails from '../StepDetails';
import StepOrganisationAssignment from '../StepOrganisationAssignment';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  lab: Lab,
  assignedOrganisations: Organisation[],
  organisations: CustomTransferListItem[],
  dispatch: React.Dispatch<{ type: LabReducerAction, value: any }>,
  setAssignedOrganisations: React.Dispatch<React.SetStateAction<Organisation[]>>,
  handleSave: () => void,
}

const LabSteps = ({ classes, completeLabel, lab, organisations, assignedOrganisations, dispatch, setAssignedOrganisations, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    address_line_1: false,
    address_city: false,
    address_postcode: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = !lab.name ? validationErrors.name : false;
      errorItems.address_line_1 = !lab.address_line_1 ? validationErrors.address_line_1 : false;
      errorItems.address_city = !lab.address_city ? validationErrors.address_city : false;
      errorItems.address_postcode = !lab.address_postcode ? validationErrors.address_postcode : false;
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [lab, errors]);

  const handleOrganisationAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    setAssignedOrganisations(assigned.map((i) => ({ id: i.id, name: i.label })));
  }

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                lab={lab}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Organisation assignment',
            gridSize: 8,
            component:
              <StepOrganisationAssignment
                organisations={organisations}
                assignedOrganisations={assignedOrganisations.map((org: Organisation) => ({ id: org.id, label: org.name }))}
                handleOrganisationAssignment={handleOrganisationAssignment} />
          },
          {
            label: 'Summary',
            component:
              <StepReview
                lab={lab}
                assignedOrganisations={assignedOrganisations.map((org: Organisation) => org.name)}
                setStep={setStep} />
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(LabSteps);
