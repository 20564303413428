import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectCaseInitQuery = (id: string) => ({
  cases_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      accession_id: types.string,
      created_at: types.string,
      mrn: types.string,
      note: types.string,
      enum_case_priority: {
        description: types.string,
      },
      created_by_user: {
        full_name: types.string,
      },
      received_by_user: {
        full_name: types.string,
      },
      patient: {
        id: types.string,
        nhs_id: types.string,
        dob: types.string,
      },
      organisation: {
        id: types.string,
        name: types.string,
      },
      lab: {
        id: types.string,
        name: types.string,
      },
      surgical_pathway_version: {
        surgical_pathways: {
          name: types.string,
          category: {
            icon: types.string,
          },
        },
      },
      surgical_pathways: types.string,
      pots: [
        {
          id: types.string,
          name: types.string,
          pathway: types.string,
          note: types.string,
          queries: params({ where: { reply_to_id: { _is_null: true } } }, [
            {
              message: types.string,
            },
          ]),
        },
      ],
      case_events: params(
        {
          limit: 1,
          where: { event_type: { _eq: 'CASE_DATA' } },
        },
        [
          {
            event_data: types.custom<{ patient: { first_name: string; last_name: string } }>(),
          },
        ]
      ),
    }
  ),
});

type X = Unpacked<typeof _selectCaseInitQuery>;
export type Case = X['cases_by_pk'];

export const selectCaseInitQuery = (id: string) =>
  gql`
    ${query(_selectCaseInitQuery(id))}
  `;
