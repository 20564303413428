import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';
import { pluralise } from '../../../../utils/stringUtils';
import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';

import {
  Grid,
  Typography
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import GridCard from '../../../../components/Grid/GridCard';

import styles from './styles';
import { selectLabInitQuery, Lab, OrganisationAssignment } from './queries';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}


const ViewLab = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { lab_id } = useParams();
  const [lab, setLab] = useState<Lab | UnknownObject>({});
  const [assignedOrganisations, setAssignedOrganisations] = useState<string[]>([]);

  const { data: labsInitData } = useQuery(selectLabInitQuery(lab_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && labsInitData) {
      setLab(labsInitData.labs_by_pk);
      setAssignedOrganisations(labsInitData.labs_by_pk.organisation_assignments.map((i: OrganisationAssignment) => i.organisation.name));
    }
    return () => { mounted = false; };
  }, [labsInitData]);

  const handleEdit = () => {
    navigate(`/admin/labs/${lab_id}/edit`);
  };

  const handleViewUsers = () => {
    navigate(`/admin/labs/${lab_id}/users`);
  };

  const handleCreateUser = () => {
    navigate(`/admin/labs/${lab_id}/users/create`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Labs', link: '/admin/labs' },
        { label: lab.name },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.header}>
                  <Typography className={classes.title} variant="h5" component="h2">{lab.name}</Typography>
                </div>
              </Grid>
            </Grid>
            <ReadOnlyBlock
            gridSize={3}
              items={[
                { label: 'Lab name', value: lab.name },
                { label: 'Address', list: [
                  lab.address_line_1,
                  lab.address_line_2,
                  lab.address_city,
                  lab.address_postcode,
                ]},
                { label: 'Contact', value: lab.contact || 'No contact information found' },
                { label: `${assignedOrganisations.length} ${pluralise('Assigned organisation', assignedOrganisations.length)}`, list: assignedOrganisations }
              ]}
              handleEdit={handleEdit} />
          </>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <GridCard
                fill
                gridSize={12}
                counter={lab?.users_aggregate?.aggregate.count}
                key="Users"
                title="Users"
                subTitle="Lab users"
                editLabel="Create"
                handleEdit={() => handleCreateUser()}
                handleView={() => handleViewUsers()} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default withStyles(styles)(ViewLab);
