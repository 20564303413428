import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';
import { numbersToWords, pluralise } from '../../../../../../../utils/stringUtils';

import {
  Button,
  FormControlLabel,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import {
  Add as AddIcon,
  HighlightOffOutlined as DeleteIcon,
} from '@mui/icons-material';

import { PathwayStep, PathwayStepOption, PathwayReducerAction, ValidationErrorsStep, validationErrorsStep, ValidationErrors, EnumPathwayStepComopnentType, } from '../../../../../Common';

import styles from './styles';
import PathwayStepOptionElement from '../PathwayStepOptionElement';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  stepCount: number,
  data: PathwayStep,
  showSpecimenQualifier: boolean,
  handleSetErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: PathwayReducerAction, value: any }>,
}

const PathwayStepElement = ({ classes, stepCount, data, showSpecimenQualifier, handleSetErrors, dispatch }: Props): React.ReactElement => {
  const [errors, setErrors] = useState<ValidationErrorsStep>({
    name: false,
    description: false,
    options: false,
  });

  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'name') {
      errorItems.name = !data.name ? validationErrorsStep.name : false;
    }
    if (field === 'description') {
      errorItems.description = !data.description ? validationErrorsStep.description : false;
    }
    if (field === 'options') {
      errorItems.options = data.options.some((i) => !i.label) ? validationErrorsStep.options : false;
    }
    const errs = {...errors, ...errorItems};
    setErrors(errs);
    handleSetErrors((i) => ({ ...i, steps: errs.name || errs.description || errs.options }));
  }, [data, errors, setErrors, handleSetErrors]);

  const generateNumbers = () => {
    const numbers: string[] = numbersToWords(stepCount - 1);
    numbers.push('Last');
    return numbers;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerLists}>
          {stepCount > 1 && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="order-index-select">Order</InputLabel>
              <Select
                id={`order-index-select-${data.order_index}`}
                label="Order"
                value={data.order_index}
                inputProps={{
                  name: 'order',
                  id: 'order-index-select',
                }}
                onChange={(event) => dispatch({ type: PathwayReducerAction.STEP_ORDER_INDEX, value: { stepId: data.id, order_index: event.target.value as number } })}>
                  {generateNumbers().map((i: string, index: number) => <MenuItem key={index} value={index}>{i}</MenuItem>)}
              </Select>
            </FormControl>
          )}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="component-type-select">Component type</InputLabel>
            <Select
              id={`component-type-select-${data.order_index}`}
              label="Component type"
              value={data.component_type}
              inputProps={{
                name: 'Component type',
                id: 'component-type-select',
              }}
              onChange={(event) => dispatch({ type: PathwayReducerAction.COMPONENT_TYPE, value: { stepId: data.id, type: event.target.value as EnumPathwayStepComopnentType } })}>
                {Object.keys(EnumPathwayStepComopnentType).map((i: string, index: number) => <MenuItem key={index} value={i}>{i}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              className={classes.switch}
              label="Mandatory"
              labelPlacement="start"
              control={<Switch id={`mandatory-checkbox-${data.order_index}`} checked={data.mandatory} color="primary" onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PathwayReducerAction.STEP_MANDATORY, value: { stepId: data.id, mandatory: event.target.checked as boolean } })} />} />
          </FormControl>
        </div>
        {stepCount > 1 && (
          <Button className={classes.remove} variant="outlined" color="primary" startIcon={<DeleteIcon />} onClick={() => dispatch({ type: PathwayReducerAction.REMOVE_STEP, value: { stepId: data.id } })}>Remove</Button>
        )}
      </div>
      <Divider />
      <div className={classes.details}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={data.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PathwayReducerAction.STEP_NAME, value: { stepId: data.id, name: event.target.value || '' } })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')} />
        <TextField
          className={classes.textField}
          data-qa="description-textfield"
          required
          multiline
          label="Description"
          variant="outlined"
          error={errors.description as boolean}
          helperText={errors.description}
          value={data.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PathwayReducerAction.STEP_DESCRIPTION, value: { stepId: data.id, description: event.target.value || '' } })}
          onKeyUp={() => validate('description')}
          onBlur={() => validate('description')} />
      </div>
      <Divider />

      {/* {showSpecimenQualifier && (
        <FormControl className={classes.formControl}>
        <FormControlLabel
          className={classes.switch}
          label="Specimen qualifier"
          labelPlacement="start"
          control={<Switch id={`specimen-qualifier-checkbox-${data.order_index}`} checked={data.specimen_qualifier} color="primary" onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PathwayReducerAction.STEP_SHOW_SPECIMEN_QUALIFIER, value: { stepId: data.id, specimen_qualifier: event.target.checked as boolean } })} />} />
        </FormControl>
      )} */}

      {showSpecimenQualifier && (
        <TextField
        className={classes.textField}
        data-qa="specimen-qualifier-textfield"
        label="Specimen qualifier"
        variant="outlined"
        value={data.specimen_qualifier}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PathwayReducerAction.STEP_SPECIMEN_QUALIFIER, value: { stepId: data.id, specimen_qualifier: event.target.value || '' } })} />
      )}
    
      <div className={classes.options}>
        <div className={classes.optionsHeader}>
          <Typography className={classes.label} variant="subtitle2">{data.options.length} {pluralise('Option', data.options.length)}:</Typography>
          {errors.options && (
            <Typography className={classes.error} variant="caption">{errors.options}</Typography>
          )}
        </div>
        {data.options.map((option: PathwayStepOption, index: number) =>
          <PathwayStepOptionElement
            key={option.id}
            stepId={data.id}
            componentType={data.component_type}
            option={option}
            index={index}
            showSpecimenQualifier={showSpecimenQualifier}
            handleValidateStep={validate}
            dispatch={dispatch} />)}
      </div>
      <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => dispatch({ type: PathwayReducerAction.ADD_OPTION, value: { stepId: data.id } })}>Add Option</Button>
    </div>
  );
};

export default withStyles(styles)(PathwayStepElement);
