import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useSubscription } from '@apollo/client';

import {
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import { limsSubscription } from './subscriptions';
import { CaseEvent } from '../Common';


const Lims = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [caseEvent, setCaseEvent] = useState<CaseEvent | null>(null);

  const { data: limsSubData } = useSubscription(limsSubscription, { shouldResubscribe: true, fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      document.title = "LIMS waiting";
      if (limsSubData) {
        setCaseEvent(limsSubData['case_events'][0]);
      }
    }
    return () => { mounted = false; };
  }, [limsSubData]);

  useEffect(() => {
    let mounted = true;
    if (mounted && caseEvent) {
      if (caseEvent.event_type === 'NEW_CASE') {
        navigate('/admin/lims/new-case', {state: caseEvent});
      } 
      if (caseEvent.event_type === 'CASE_DATA') {
        navigate('/admin/lims/case-data', {state: caseEvent});
      }
    }
    return () => { mounted = false; };
  }, [caseEvent, location, navigate]);

  return (
    <GeneralLayout currentPage="LIMS">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3">Waiting for case event</Typography>
          <CircularProgress />
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default Lims;
