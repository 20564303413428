import React, { useState, useEffect } from 'react';

import { types, query } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { camelToSpaces } from '../../utils/stringUtils';

interface Props {
  gender: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

interface EnumGender {
  gender: string,
  description: string,
}

const GendersRadioGroup = ({ gender, onChange }: Props): React.ReactElement => {
  const [genders, setGenders] = useState<EnumGender[]>([]);

  const q = {
    enum_genders: {
      gender: types.string,
      description: types.string,
    }
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const gens: EnumGender[] = data.enum_genders;
      if (gens) {
        setGenders(gens);
      }
    }
    return () => { mounted = false; };
  }, [data]);

  return (
    <RadioGroup value={gender} onChange={onChange}>
      {genders.map((g: EnumGender) => <FormControlLabel key={g.gender} value={g.gender} control={<Radio color="primary" />} label={camelToSpaces(g.description)} />)}
    </RadioGroup>
  );
};
export default GendersRadioGroup;
