
import { types, query, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _specialitiesInitQuery = {
  specialities: params(
    { order_by: { order_index: 'asc' }},
    [{
      id: types.string,
      name: types.string,
      description: types.string,
      icon: types.string,
      order_index: types.number,
      categories_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }],
  )
};

type X = Unpacked<typeof _specialitiesInitQuery>
export type Speciality = X['specialities'][0];

export const specialitiesInitQuery = gql`${query(_specialitiesInitQuery)}`;
