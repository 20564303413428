import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    specialityDetails: {
      marginTop: theme.spacing(2),
    },
    potContainer: {
      marginBottom: theme.spacing(2),
    },
    stepValue: {
      marginLeft: theme.spacing(1),
    },
    qr: {
      width: '100%',
      borderColor: theme.palette.common.black,
      borderWidth: 1,
      borderRadius: 10,
      borderStyle: 'solid',
      overflow: 'hidden',
    },
    reviewDetailsContainer: {
      marginTop: theme.spacing(3),
    },
  });

export default styles;
