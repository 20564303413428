import { gql } from '@apollo/client';
import gqlClient from '../../../../../../utils/apolloClient';

const insertPotPathwayMutation = gql`
  mutation ($object: pot_pathways_insert_input!) {
    insert_pot_pathways_one(object: $object) {
      id
    }
  }
`;

const insertPotPathwayStepsMutation = gql`
  mutation ($objects: [pot_pathway_steps_insert_input!]!) {
    insert_pot_pathway_steps(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const createPotPathway = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    insert_pot_pathways_one: {
      id: string,
    }
  }>({ mutation: insertPotPathwayMutation!, variables });
  return res.data?.insert_pot_pathways_one.id;
}

export const createPotPathwaySteps = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_pot_pathway_steps: {
      id: string,
    }
  }>({ mutation: insertPotPathwayStepsMutation!, variables });
}
