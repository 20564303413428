
import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _createOrganisationInitQuery = {
  enum_organisation_types: [{
    organisation_type: types.string,
    description: types.string,
  }],
  labs: [{
    id: types.string,
    name: types.string,
  }],
};

type X = typeof _createOrganisationInitQuery
export type Lab = X['labs'][0];

export const createOrganisationInitQuery = gql`${query(_createOrganisationInitQuery)}`;
