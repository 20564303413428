import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client/react';
import { WithStyles, withStyles } from '@mui/styles';

import { CssBaseline } from '@mui/material';

import AppRoutes from '../../Routes';
import AuthProvider from '../AuthProvider';
import StateProvider from '../StateProvider';
import ApolloClient from '../../utils/apolloClient';


import styles from './styles';


interface Props extends WithStyles<typeof styles> {}

const App = ({ classes }: Props): React.ReactElement => {
  return (
    <AuthProvider>
      <ApolloProvider client={ApolloClient}>
        <>
          <CssBaseline />
            <StateProvider>
              <div className={classes.root}>
                <BrowserRouter >
                  <AppRoutes />
                </BrowserRouter>
              </div>
            </StateProvider>
        </>
      </ApolloProvider>
    </AuthProvider>
  );
};

export default withStyles(styles)(App);
