import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

const updateLabUserMutation = gql`
  mutation ($pk_columns: users_receiver_pk_columns_input!, $set: users_receiver_set_input!) {
    update_users_receiver_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

const updateUserLabAssignmentMutation = gql`
  mutation ($assignments: [user_lab_assignments_insert_input!]!, $remove: [uuid!]!) {
    insert_user_lab_assignments(objects: $assignments) {
      returning {
        id
      }
    }
    delete_user_lab_assignments(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const updateLabUser = async (variables: object) => {
  return await gqlClient.mutate<{
    update_users_receiver_by_pk: {
      id: string,
    }
  }>({ mutation: updateLabUserMutation!, variables });
}

export const updateUserLabAssignments = async (assignments: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    update_user_lab_assignments: {
      returning: [{
        id: string,
      }],
    }
    delete_user_lab_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateUserLabAssignmentMutation!, variables: { assignments, remove } });
}
