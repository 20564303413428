import React, { useEffect, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';
import moment from 'moment';

import { useQuery } from '@apollo/client';

import { Grid, Typography } from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import SpecialityCard from '../../../../components/SpecialityCard';
import PotQR from '../../../../components/QrCodes/PotQR';

import { Case, selectCaseInitQuery } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
}

const ViewCase = ({ classes }: Props): React.ReactElement => {
  const { case_id } = useParams();

  const [caseData, setCase] = useState<Case | UnknownObject>({});

  const { data: caseInitData } = useQuery(selectCaseInitQuery(case_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && caseInitData) {
      setCase(caseInitData.cases_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [caseInitData]);

  const getPatientInfoFromEvents = (caseData: Case | UnknownObject) => {
    if (caseData?.case_events?.length) {
      const caseDataEvent = caseData.case_events[0];

      return `${caseDataEvent.event_data?.patient.first_name} ${caseDataEvent.event_data?.patient.last_name}`;
    }

    return '';
  };

  const patientName = getPatientInfoFromEvents(caseData);
  const patientNhsNumber = caseData?.patient?.nhs_id || '';
  const patientDob = moment(caseData?.patient?.dob).format('DD/MM/yyyy');

  return (
    <GeneralLayout breadcrumbs={[{ label: 'Cases', link: '/lab/cases' }, { label: caseData.accession_id }]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <ReadOnlyBlock
                fill
                title='Patient Details'
                items={[
                  { label: 'Name', value: patientName },
                  { label: 'NHS number', value: patientNhsNumber },
                  { label: 'Medical Record Number', value: caseData?.mrn },
                  { label: 'Date of Birth', value: patientDob },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <ReadOnlyBlock
                fill
                title='Sender Details'
                items={[
                  { label: 'Organisation', value: caseData?.organisation?.name },
                  { label: 'Lab', value: caseData?.lab?.name },
                  { label: 'Requester', value: caseData?.created_by_user?.full_name },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <ReadOnlyBlock
                fill
                center
                components={[
                  <PotQR
                    patientInformation={{
                      name: patientName,
                      nhsNumber: patientNhsNumber,
                      dob: patientDob,
                    }}
                    rawData={caseData.id}
                  />,
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <ReadOnlyBlock
                fill
                title='Clinical Details'
                components={[
                  <div className={classes.reviewDetailsContainer}>
                    <SpecialityCard
                      small
                      divider
                      gridSize={12}
                      title={caseData?.surgical_pathway_version?.surgical_pathways?.name.split('|')[0]}
                      description={caseData?.surgical_pathway_version?.surgical_pathways?.name.split('|')[1]}
                      icon={caseData?.surgical_pathway_version?.surgical_pathways?.category?.icon}
                    />
                    <div className={classes.specialityDetails}>
                      <Grid container spacing={2}>
                        {caseData?.surgical_pathways?.map((pathway: UnknownObject) => (
                          <Grid key={pathway.id} item xs={6}>
                            <div className={classes.specialityStep}>
                              <Typography variant='subtitle2' color='textSecondary'>
                                {pathway.title}
                              </Typography>
                              <Typography className={classes.stepValue} variant='body1'>
                                {pathway.label}
                              </Typography>
                            </div>
                          </Grid>
                        ))}
                        {caseData.note && (
                          <Grid item xs={12}>
                            <Typography variant='body1' color='textSecondary'>
                              Additional information: {caseData.note}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </div>,
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyBlock
                fill
                title='Pot Details'
                components={[
                  <div className={classes.reviewDetailsContainer}>
                    {caseData?.pots?.map((pot: UnknownObject) => (
                      <div key={pot.id} className={classes.potContainer}>
                        <Typography variant='subtitle1' color='textSecondary'>
                          {pot.name}
                        </Typography>
                        <Typography className={classes.stepValue} variant='body1'>
                          {pot.pathway.map((i: UnknownObject) => i.label).join(', ')}
                        </Typography>
                        {pot.note && (
                          <Typography className={classes.stepValue} variant='subtitle2' color='textSecondary'>
                            Additional information: {pot.note}
                          </Typography>
                        )}
                      </div>
                    ))}
                  </div>,
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default withStyles(styles)(ViewCase);
