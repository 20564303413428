import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertAdminUserMutation = gql`
  mutation ($object: users_admin_insert_input!) {
    insert_users_admin_one(object: $object) {
      id
    }
  }
`;

export const createAdminUser = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_users_admin_one: {
      id: string,
    }
  }>({ mutation: insertAdminUserMutation!, variables });
  return res.data?.insert_users_admin_one.id;
}
