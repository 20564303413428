import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    height: '100%',
  },
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    width: theme.spacing(48),
  },
  content: {
    padding: theme.spacing(3),
  },
  logo: {
    width: theme.spacing(30),
    alignSelf: 'center',
  },
  password: {
    marginTop: `${theme.spacing(2)}px !important`,
    marginBottom: `${theme.spacing(1)}px !important`,
  },
  forgotPassowrd: {
    textTransform: 'none',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    flexDirection: 'column',
    flex: '1 1 auto',
    justifyContent: 'flex-end',
    minHeight: theme.spacing(5),
    '& button': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& button:last-child': {
      marginBottom: 0,
    },
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  progressText: {
    marginTop: theme.spacing(1),
  },
});

export default styles;
