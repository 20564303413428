import React, { useContext, useEffect, useState } from 'react';
import { getKeyValue } from '../../../../utils/stringUtils';
import { getWeeksPast } from '../../../../utils/dateUtils';

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import {
  Chip,
  Grid,
  Tooltip,
} from '@mui/material';

import {
  Help,
} from '@mui/icons-material';

import { AuthContext } from '../../../../components/AuthProvider';
import GeneralLayout from '../../../../layouts/General';
import GridCard from '../../../../components/Grid/GridCard';
import SortableTable, { SortableTableHeader } from '../../../../components/SortableTable';

import { casesInitQuery, Case } from './queries';

const lastWeek = getWeeksPast(1).toISOString();

const Cases = (): React.ReactElement => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }
  const { user } = authContext;

  const [cases, setCases] = useState<Case[]>([]);

  const { data: casesInitData } = useQuery(casesInitQuery(user?.id || '', lastWeek), { fetchPolicy: 'cache-first' });

  useEffect(() => {
    let mounted = true;
    if (mounted && casesInitData) {
      setCases(casesInitData.cases);
    }
    return () => { mounted = false; };
  }, [casesInitData]);

  const handleView = (id: string) => {
    navigate(`/lab/cases/${id}`);
  };

  const handleCheckIn = () => {
    navigate(`/lab/cases/check-in`);
  };

  const handleSearch = () => {
    navigate(`/lab/cases/search`);
  };

  const cleanObject = (data: any) => JSON.parse(JSON.stringify(data));

  const tableHeaders: SortableTableHeader[] = [
    { key: 'accession_id', label: 'Accession Id' },
    { key: 'patient.nhs_id', label: 'NHS number' },
    { key: 'mrn', label: 'MRN' },
    { key: 'pots_aggregate.aggregate.count', label: 'Pots' },
    { key: 'organisation.name', label: 'Organisation' },
    { key: 'enum_case_priority.description', label: 'Priority' },
    { key: 'received_at', label: 'Received at', format: 'ddd MMM Mo HH:mm' }
  ];

  return (
    <GeneralLayout currentPage="Cases">
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12}>
          <Grid container spacing={4} alignItems="stretch">
            <GridCard
              fill
              gridSize={6}
              title="Check-in new case"
              description="New received case"
              viewLabel="Start"
              handleView={() => handleCheckIn()} />
            <GridCard
              fill
              gridSize={6}
              title="Search cases"
              description="Search for any case"
              viewLabel="Search"
              handleView={() => handleSearch()} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12}>
              <SortableTable
                title="Cases received by you"
                counter={cases.length}
                headers={tableHeaders}
                orderdBy="received_at"
                rows={cases.map((c: Case) => ({
                  key: c.id,
                  actions: [
                    // {
                    //   label: 'Check in',
                    //   onClick: () => handleView(c.id),
                    // },
                    {
                      label: 'View',
                      onClick: () => handleView(c.id),
                    }
                  ],
                  columns: tableHeaders.map((t) => ({
                    key: t.key,
                    format: t.format,
                    label: getKeyValue(c, t.key),
                    component: t.key === 'enum_case_priority.description' ? <Chip size="small" label={getKeyValue(c, t.key)} variant="outlined" /> : undefined,
                  })),
                  collapse: {
                    title: 'Pots',
                    headers: [
                      {
                        key: 'name',
                        label: 'Name',
                      },
                      {
                        key: 'status',
                        label: 'Status',
                      },
                      ...cleanObject(c.pots[0].pathway).map((pathway: { id: string, title: string }) => ({
                        key: pathway.id,
                        label: pathway.title,
                      }))
                    ],
                    rows: c.pots.map((pot) => ({
                      key: pot.id,
                      columns: [
                        {
                          key: 'name',
                          label: pot.name,
                        },
                        {
                          key: 'status',
                          label: pot.enum_pot_status?.description || 'Initial',
                          component:
                            pot.queries[0]?.message
                            ? <Tooltip title={pot.queries[0].message} placement="right" arrow>
                                <Chip
                                  size="small"
                                  label={pot.enum_pot_status?.description || 'Initial'}
                                  variant="outlined"
                                  deleteIcon={<Help />}
                                  onDelete={() => {} } />
                              </Tooltip>
                            : <Chip size="small" label={pot.enum_pot_status?.description || 'Initial'} variant="outlined" />,
                        },
                        ...cleanObject(pot.pathway).map((pathway: { id: string, label: string }) => ({
                          key: pathway.id,
                          label: pathway.label,
                        })),
                      ],
                    })),
                }}))} />
              </Grid>
            </Grid>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default Cases;
