import React, { useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import Pathway from '../../../../../../components/PathwayBuilder/Common';
import PathwayView from '../../../../../../components/PathwayBuilder/View';
import SpecialityCard from '../../../../../../components/SpecialityCard';
import { Pot, PotsReducerAction, ValidationErrors } from '../../pots';
import { Speciality } from '../../speciality';

import styles from './styles';
import GridActionCard from '../../../../../../components/Grid/GridActionCard';
import { CheckCircleOutlined, ErrorOutlineOutlined } from '@mui/icons-material';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  pathway: Pathway,
  pots: Pot[],
  speciality: Speciality,
  errors?: ValidationErrors,
  validate: () => void,
  // setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: PotsReducerAction, value: any}>,
}

const StepPots = ({ classes, pathway, pots, speciality, errors, validate, dispatch }: Props): React.ReactElement => {
  const getSelectedPot = () => pots.find((i) => i.selected);

  const potsPathwayReducer = (state: {}, action: { type: string, id: string, value: string }): {} =>  {
    const pot = getSelectedPot();
    if (pot) {
      switch (action.type) {
        case 'PATHWAY':
          const pathway: UnknownObject = { ...pot.pathway };
          pathway[action.id] = action.value;
          dispatch({ type: PotsReducerAction.UPDATE_POT, value: { ...pot, pathway } });
          return state;
        case 'NOTES':
          const notes: string = action.value;
          dispatch({ type: PotsReducerAction.UPDATE_POT, value: { ...pot, notes } });
          return state;
        default:
          throw new Error();
      }
    }
    return state;
  };

  const [, dispatchPotsPathway] = useReducer(potsPathwayReducer, {});

  const handleAddPot = () => {
    dispatch({ type: PotsReducerAction.ADD_POT, value: null });
  };

  const getPotContent = () => {
    const pot = getSelectedPot();
    if (pot) {
      return (
        <>
          <div className={classes.header}>
            <Typography className={classes.headerText} variant="h5">{pot.name || ''}</Typography>
            <div className={classes.headerActions}>
              {pots.length > 1 && (
                <Button
                  className={classes.buttonRed}
                  variant="outlined"
                  onClick={() => dispatch({ type: PotsReducerAction.DELETE_POT, value: { id: pot.id } })}>Delete Pot</Button>
              )}
            </div>
          </div>
          <PathwayView
            pathway={pathway}
            values={pot.pathway}
            validate={validate}
            dispatch={dispatchPotsPathway} />
          <TextField
            fullWidth
            className={classes.notes}
            data-qa="notes-textfield"
            label="Additional information"
            variant="outlined"
            value={pot.notes}
            multiline
            rows={4}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PotsReducerAction.NOTES, value: { id: pot.id, notes: event.target.value } })} />

        </>
      )
    }
    return <Typography variant="subtitle1">Pot not selected</Typography>;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h4">Pot Information</Typography>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div className={classes.pots}>
              <div className={classes.potsList}>
                {pots.map((pot, index) => (
                  <SpecialityCard
                    key={pot.id}
                    small
                    gridSize={12}
                    title={pot.name}
                    description={pathway.steps.map((step) => {
                      const cStep = pot.pathway[step.id];
                      if (cStep) {
                        return step.options.find((o) => o.id === cStep)?.label;
                      }
                      return null;
                    }).filter((i) => i).join(', ')}
                    icon={speciality.icon}
                    statusIcon={Object.keys(pot.pathway).length === pathway.steps.length ? <CheckCircleOutlined/> : <ErrorOutlineOutlined />}
                    selected={getSelectedPot()?.id === pot.id}
                    onClick={() => dispatch({ type: PotsReducerAction.SELECT_POT, value: { id: pot.id } })} />
                ))}
                <GridActionCard
                  small
                  gridSize={12}
                  label="Add Pot"
                  onClick={handleAddPot} />
              </div>
              <Divider orientation="vertical" />
            </div>
          </Grid>
          <Grid item xs={8}>
            {getPotContent()}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(StepPots);
