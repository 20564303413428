
import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _casesInitQuery = (id: string, timestamp: string) => ({
  cases: params(
    {
      order_by: { received_at: 'desc' },
      where: {
        received_by: {
          _eq: rawString(id),
        },

      },
    },
    [{
      id: types.string,
      accession_id: types.string,
      created_at: types.string,
      received_at: types.string,
      mrn: types.string,
      enum_case_priority: {
        description: types.string,
      },
      patient: {
        id: types.string,
        nhs_id: types.string,
      },
      organisation: {
        id: types.string,
        name: types.string,
      },
      lab: {
        id: types.string,
        name: types.string,
      },
      pots: params(
        {
          order_by: { name: 'asc' },
        },
        [{
          id: types.string,
          name: types.string,
          pathway: types.string,
          queries: [{
            message: types.string,
          }],
          enum_pot_status: {
            description: types.string,
          },
        }],
      ),
      pots_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }]
  )
});

type X = Unpacked<typeof _casesInitQuery>
export type Case = X['cases'][0];

export const casesInitQuery = (id: string, timestamp: string) => gql`${query(_casesInitQuery(id, timestamp))}`;
