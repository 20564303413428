import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  TextField,
} from '@mui/material';

import styles from './styles';
import { PathwayReadOnlyStep } from '../../../Common';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  data: PathwayReadOnlyStep,
}

const PathwayStepReadOnly = ({ classes, data }: Props): React.ReactElement => {
  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        disabled
        variant="outlined"
        value={data.label}
        label={data.title} />
    </div>
  );
};

export default withStyles(styles)(PathwayStepReadOnly);
