import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../react-app-env';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import GeneralLayout from '../../../../../layouts/General';

import CustomBackdrop from '../../../../../components/CustomBackdrop';
import { senderUserReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createOrganisationUserInitQuery, EnumSenderType, Organisation } from './queries';
import { createSenderUser, createUserOrganisationAssignments } from './mutations';


const CreateOrganisationUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const { organisation_id } = useParams();

  const [user, dispatchUser] = useReducer(senderUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    sender_type: '',
  });

  const [organisation, setOrganisation] = useState<Organisation | UnknownObject>({});
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [assignedOrganisations, setAssignedOrganisations] = useState<Organisation[]>([]);
  const [senderTypes, setSenderTypes] = useState<EnumSenderType[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: createOrganisationUserInitData } = useQuery(createOrganisationUserInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createOrganisationUserInitData) {
      setOrganisation(createOrganisationUserInitData.organisations_by_pk);
      setSenderTypes(createOrganisationUserInitData.enum_sender_types);
      setOrganisations(createOrganisationUserInitData.organisations);
      const org = createOrganisationUserInitData.organisations.find((i: Organisation) => i.id === organisation_id);
      if (org) {
        setAssignedOrganisations([org]);
      }
    }
    return () => { mounted = false; };
  }, [createOrganisationUserInitData, organisation_id]);

  const handleSave = async () => {
    setSaving(true);

    const createVariables = {
      object: user,
    };
    const user_id: string | undefined = await createSenderUser(createVariables);
    if (user_id) {
      if (assignedOrganisations.length > 0) {
        const organisationLabAssignments = assignedOrganisations.map((org: Organisation) => ({ organisation_id: org.id, user_id }));
        await createUserOrganisationAssignments(organisationLabAssignments);
      }
      setSaving(false);
      handleFinish(user_id);
    }
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/organisations/${organisation_id}/users/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Users', link: `/admin/organisations/${organisation_id}/users` },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          user={user}
          userTypes={senderTypes}
          organisations={organisations.map((i: Organisation) => ({ id: i.id, label: i.name }))}
          assignedOrganisations={assignedOrganisations.map((i: Organisation) => ({ id: i.id, label: i.name }))}
          setAssignedOrganisations={setAssignedOrganisations}
          dispatch={dispatchUser}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default CreateOrganisationUser;
