import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    header: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 60,
      marginBottom: theme.spacing(1),
    },
    caption: {
      marginLeft: theme.spacing(2),
    },
    specialityDetails: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    potContainer: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    stepValue: {
      marginLeft: theme.spacing(1),
    },
    reviewDetailsContainer: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      overflow: 'hidden',
    },
  });

export default styles;
