import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

const updateCaseMutation = gql`
  mutation ($pk_columns: cases_pk_columns_input!, $set: cases_set_input!) {
    update_cases_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateCase = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    update_cases_by_pk: {
      id: string,
    }
  }>({ mutation: updateCaseMutation!, variables });
  return res.data?.update_cases_by_pk.id;
}

const updatePotMutation = gql`
  mutation ($pk_columns: pots_pk_columns_input!, $set: pots_set_input!) {
    update_pots_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updatePot = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    update_pots_by_pk: {
      id: string,
    }
  }>({ mutation: updatePotMutation!, variables });
  return res.data?.update_pots_by_pk.id;
}

const insertPotQueriesMutation = gql`
  mutation ($objects: [pot_queries_insert_input!]!) {
    insert_pot_queries(objects: $objects) {
      affected_rows
    }
  }
`;

export const insertPotQueries = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    insert_pot_queries: {
      affected_rows: number,
    }
  }>({ mutation: insertPotQueriesMutation!, variables });
  return res.data?.insert_pot_queries.affected_rows;
}
