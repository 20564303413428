import React from 'react';

import LayoutHeader from './LayoutHeader';

import { Grid } from '@mui/material';

import useLocalStorage from '../../hooks/useLocalStorage';
import Content from './Content';
import Sidebar from './Sidebar';

interface Props extends React.PropsWithChildren {
  currentPage?: string;
  breadcrumbs?: { label: string; link?: string }[];
  endContent?: React.ReactElement;
}

const GeneralLayout = ({ children, currentPage, breadcrumbs, endContent }: Props): React.ReactElement => {
  const [open, setOpenDrawer] = useLocalStorage<boolean>('sidebar-open', true);

  return (
    <Grid container direction="column" sx={{ height: '100%' }}>
      <LayoutHeader />
      <Sidebar open={open} setOpenDrawer={setOpenDrawer} />
      <Content breadcrumbs={breadcrumbs ?? []} currentPage={currentPage} endContent={endContent} open={open}>
        {children}
      </Content>
    </Grid>
  );
};

export default GeneralLayout;
