import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

export interface Speciality {
  name: string,
  description: string,
  icon: string,
}

const updateCategoriesOrderMutation = gql`
  mutation ($objects: [speciality_categories_insert_input!]!) {
    insert_speciality_categories(objects: $objects, on_conflict: {
      constraint: speciality_categories_pkey,
      update_columns: order_index
    }) {
      returning {
        id
        order_index
      }
    }
  }
`;

export const editCategoriesOrder = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_speciality_categories: {
      returning: [{
        id: string,
        order_index: number,
      }]
    }
  }>({ mutation: updateCategoriesOrderMutation!, variables });
}
