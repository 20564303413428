import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  label: {
    marginRight: theme.spacing(1),
  },
  componentIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  icon: {
    marginTop: 5,
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  iconPlaceholder: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  }
});

export default styles;
