import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  TextField,
  Typography,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import { Lab } from '../../lab';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  lab: Lab | undefined,
  labs: Lab[],
  setLab: React.Dispatch<React.SetStateAction<Lab | undefined>>,
  validate: () => void,
}

const StepLabAssignment = ({ classes, lab, labs, setLab, validate }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.header} variant="h4">Lab Assignment</Typography>
        {labs.length === 1 && lab && (
          <>
            <Typography>Your organisation is only assigned to one lab:</Typography>
            <Typography variant="h6">{lab.name}</Typography>
          </>
        )}

        {labs.length > 1 && (
          <>
            <Typography className={classes.subTitle}>Your organisation is assigned to more than one lab. Please select the one you are sending this case to</Typography>
            <Autocomplete
              data-qa="lab-assignment-list"
              options={labs}
              getOptionLabel={(option: Lab) => option.name}
              value={labs.find(i => i.id === lab?.id)}
              autoComplete
              onBlur={() => validate()}
              onChange={(_: unknown, newValue: Lab | null) => {
                setLab(newValue || undefined);
              }}
              renderInput={(params) =>
                <TextField
                  data-qa="lab-assignment-input"
                  {...params}
                  label="Lab"
                  name="lab-assignment"
                  helperText={lab ? '' : 'Select a lab' } />}
            />
          </>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(StepLabAssignment);
