import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import {  useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';

import GeneralLayout from '../../../../layouts/General';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { labReducer, LabReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { editLabInitQuery, Organisation, OrganisationAssignment } from './queries';
import { updateLab, updateOrganisationLabAssignments } from './mutations';
import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}


const EditLab = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { lab_id } = useParams();

  const [lab, dispatchLab] = useReducer(labReducer, {
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_postcode: '',
    contact: '',
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [assignedOrganisations, setAssignedOrganisations] = useState<Organisation[]>([]);
  const [assignedOriginalOrganisations, setAssignedOriginalOrganisations] = useState<OrganisationAssignment[]>([]);

  const { data: editLabInitData } = useQuery(editLabInitQuery(lab_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLabInitData) {
      const labItem = editLabInitData.labs_by_pk;

      dispatchLab({ type: LabReducerAction.INIT, value: labItem });
      setOrganisations(editLabInitData.organisations);
      setAssignedOrganisations(labItem.organisation_assignments.map((i: OrganisationAssignment) => i.organisation));
      setAssignedOriginalOrganisations(labItem.organisation_assignments);
    }
    return () => { mounted = false; };
  }, [editLabInitData]);

  const handleSave = async () => {
    setSaving(true);

    const updateLabVariables = {
      pk_columns: {
        id: lab_id,
      },
      set: lab,
    };
    await updateLab(updateLabVariables);

    const organisationLabAssignments = assignedOrganisations.filter((org: Organisation) => !assignedOriginalOrganisations.some((i: OrganisationAssignment) => org.id === i.organisation.id)).map((org: Organisation) => ({ lab_id, organisation_id: org.id }));
    const organisationLabAssignmentsRemove = assignedOriginalOrganisations.filter((i: OrganisationAssignment) => !assignedOrganisations.some((org: Organisation) => org.id === i.organisation.id)).map((i: OrganisationAssignment) => i.id);

    await updateOrganisationLabAssignments(organisationLabAssignments, organisationLabAssignmentsRemove);

    setSaving(false);
    handleFinish(lab_id!);
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/labs/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Labs', link: '/admin/labs' },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Save"
          lab={lab}
          organisations={organisations.map((i: Organisation) => ({ id: i.id, label: i.name }))}
          assignedOrganisations={assignedOrganisations}
          setAssignedOrganisations={setAssignedOrganisations}
          dispatch={dispatchLab}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Lab" />}
    </>
  );
};

export default withStyles(styles)(EditLab);
