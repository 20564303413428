import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';

import CustomStepper from '../../../../../../../components/CustomStepper';
import { CustomTransferListItem } from '../../../../../../../components/CustomTransferList';
import { ReceiverUser, EnumReceiverType, validationErrors, ValidationErrors, ReceiverUserReducerAction, Lab } from '../../../Common';
import StepDetails from '../StepDetails';
import StepUserRole from '../StepUserRole';
import StepLabAssignment from '../StepLabAssignment';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  user: ReceiverUser,
  userTypes: EnumReceiverType[],
  labs: CustomTransferListItem[],
  assignedLabs: CustomTransferListItem[],
  setAssignedLabs: React.Dispatch<React.SetStateAction<Lab[]>>,
  dispatch: React.Dispatch<{ type: ReceiverUserReducerAction, value: any }>,
  handleSave: () => void,
}

const LabUserSteps = ({ classes, completeLabel, user, userTypes, labs, assignedLabs, setAssignedLabs, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    first_name: false,
    last_name: false,
    email: false,
    email_invalid: false,
    user_role: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.first_name = !user.first_name ? validationErrors.first_name : false;
      errorItems.last_name = !user.last_name ? validationErrors.last_name : false;
      errorItems.email = !user.email ? validationErrors.email : false;
      errorItems.email_invalid = !user.email ? validationErrors.email : false;
    }
    if (_step === 1) {
      errorItems.user_role = !user.receiver_type ? validationErrors.user_role : false;
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [user, errors]);

  const handleLabAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    setAssignedLabs(assigned.map((i) => ({ id: i.id, name: i.label })));
  }

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                user={user}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'User role',
            component:
              <StepUserRole
                user={user}
                errors={errors}
                userTypes={userTypes}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Lab assignment',
            gridSize: 8,
            component:
              <StepLabAssignment
                labs={labs}
                assignedLabs={assignedLabs}
                handleLabAssignment={handleLabAssignment} />
          },
          {
            label: 'Summary',
            component:
              <StepReview
                user={user}
                assignedLabs={assignedLabs.map((i: CustomTransferListItem) => i.label)}
                userTypes={userTypes}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(LabUserSteps);
