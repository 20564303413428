import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Organisation, OrganisationType } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  organisation: Organisation,
  assignedLabs: string[],
  organisationTypes: OrganisationType[],
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, organisation, organisationTypes, assignedLabs, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Organisation name', value: organisation.name },
          { label: 'Address', list: [
              organisation.address_line_1,
              organisation.address_line_2,
              organisation.address_city,
              organisation.address_postcode,
            ]
          }
        ]}
        handleEdit={() => setStep(0)} />
      <ReadOnlyBlock
        items={[
          { label: 'Organisation type', value: organisationTypes.find((i) => i.organisation_type === organisation.organisation_type)?.description }
        ]}
        handleEdit={() => setStep(1)} />
      <ReadOnlyBlock
        items={[
          { label: `${assignedLabs.length} Assigned labs`, list: assignedLabs }
        ]}
        handleEdit={() => setStep(2)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
