import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateLabMutation = gql`
  mutation ($pk_columns: labs_pk_columns_input!, $set: labs_set_input!) {
    update_labs_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

const updateOrganisationLabAssignmentMutation = gql`
  mutation ($assignments: [organisation_lab_assignment_insert_input!]!, $remove: [uuid!]!) {
    insert_organisation_lab_assignment(objects: $assignments) {
      returning {
        id
      }
    }
    delete_organisation_lab_assignment(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const updateLab = async (variables: object) => {
  return await gqlClient.mutate<{
    update_labs_by_pk: {
      id: string,
    }
  }>({ mutation: updateLabMutation!, variables });
}

export const updateOrganisationLabAssignments = async (assignments: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    update_organisation_lab_assignment: {
      returning: [{
        id: string,
      }],
    }
    delete_organisation_lab_assignment: {
      affected_rows: number
    }
  }>({ mutation: updateOrganisationLabAssignmentMutation!, variables: { assignments, remove } });
}
