import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Lab } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  lab: Lab,
  assignedOrganisations: string[],
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, lab, assignedOrganisations, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Lab name', value: lab.name },
          { label: 'Address', list: [
              lab.address_line_1,
              lab.address_line_2,
              lab.address_city,
              lab.address_postcode,
            ]
          }
        ]}
        handleEdit={() => setStep(0)} />
      <ReadOnlyBlock
        items={[
          { label: `${assignedOrganisations.length} Assigned Organisations`, list: assignedOrganisations }
        ]}
        handleEdit={() => setStep(1)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
