import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';

import CustomStepper from '../../../../../../../components/CustomStepper';
import { CustomTransferListItem } from '../../../../../../../components/CustomTransferList';
import { SenderUser, EnumSenderType, validationErrors, ValidationErrors, SenderUserReducerAction, Organisation } from '../../../Common';
import StepDetails from '../StepDetails';
import StepUserRole from '../StepUserRole';
import StepOrganisationAssignment from '../StepOrganisationAssignment';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  user: SenderUser,
  userTypes: EnumSenderType[],
  organisations: CustomTransferListItem[],
  assignedOrganisations: CustomTransferListItem[],
  setAssignedOrganisations: React.Dispatch<React.SetStateAction<Organisation[]>>,
  dispatch: React.Dispatch<{ type: SenderUserReducerAction, value: any }>,
  handleSave: () => void,
}

const OrganisationUserSteps = ({ classes, completeLabel, user, userTypes, organisations, assignedOrganisations, setAssignedOrganisations, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    first_name: false,
    last_name: false,
    email: false,
    email_invalid: false,
    user_role: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.first_name = !user.first_name ? validationErrors.first_name : false;
      errorItems.last_name = !user.last_name ? validationErrors.last_name : false;
      errorItems.email = !user.email ? validationErrors.email : false;
      errorItems.email_invalid = !user.email ? validationErrors.email : false;
    }
    if (_step === 1) {
      errorItems.user_role = !user.sender_type ? validationErrors.user_role : false;
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [user, errors]);

  const handleOrganisationAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    setAssignedOrganisations(assigned.map((i) => ({ id: i.id, name: i.label })));
  }

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                user={user}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'User role',
            component:
              <StepUserRole
                user={user}
                errors={errors}
                userTypes={userTypes}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Organisation assignment',
            gridSize: 8,
            component:
              <StepOrganisationAssignment
                organisations={organisations}
                assignedOrganisations={assignedOrganisations}
                handleLabAssignment={handleOrganisationAssignment} />
          },
          {
            label: 'Summary',
            component:
              <StepReview
                  user={user}
                  userTypes={userTypes}
                  assignedOrganisations={assignedOrganisations.map((i: CustomTransferListItem) => i.label)}
                  setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationUserSteps);
