import { gql } from '@apollo/client';
import gqlClient from '../../../utils/apolloClient';

const updateAdminUserMutation = gql`
  mutation ($pk_columns: users_admin_pk_columns_input!, $set: users_admin_set_input!) {
    update_users_admin_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateAdminUser = async (variables: object) => {
  const res = await gqlClient.mutate<{
    update_users_admin_by_pk: {
      id: string,
    }
  }>({ mutation: updateAdminUserMutation!, variables });
  return res.data?.update_users_admin_by_pk.id;
}

const updateSenderUserMutation = gql`
  mutation ($pk_columns: users_sender_pk_columns_input!, $set: users_sender_set_input!) {
    update_users_sender_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateSenderUser = async (variables: object) => {
  const res = await gqlClient.mutate<{
    update_users_sender_by_pk: {
      id: string,
    }
  }>({ mutation: updateSenderUserMutation!, variables });
  return res.data?.update_users_sender_by_pk.id;
}

const updateReceiverUserMutation = gql`
  mutation ($pk_columns: users_receiver_pk_columns_input!, $set: users_receiver_set_input!) {
    update_users_receiver_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateReceiverUser = async (variables: object) => {
  const res = await gqlClient.mutate<{
    update_users_receiver_by_pk: {
      id: string,
    }
  }>({ mutation: updateReceiverUserMutation!, variables });
  return res.data?.update_users_receiver_by_pk.id;
}

