
import { params, rawString, subscription, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';
import { Unpacked } from '../../../../react-app-env';

export const _newCaseEventSubscription = (id: string) => gql`${subscription({
  case_events_by_pk: params(
    {
      id: rawString(id),
    },
    {
      id: types.string,
      accession_number: types.string,
    }
  )
})}`;

export const newCaseEventSubscription = (id: string) => {
  return gqlClient.subscribe<Unpacked<typeof _newCaseEventSubscription>>({ query: _newCaseEventSubscription(id) });
}