import { capitaliseFirst } from "../../../../../utils/stringUtils";

export interface ReceiverUser {
  first_name: string,
  last_name: string,
  email: string,
  receiver_type: string,
}
export interface Lab {
  id: string,
  name: string,
}

export interface ValidationErrors {
  first_name: string | boolean,
  last_name: string | boolean,
  email: string | boolean,
  email_invalid: string | boolean,
  user_role: string | boolean,
}

export interface EnumReceiverType {
  receiver_type: string;
  description: string;
}

export const validationErrors: ValidationErrors = {
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  email: 'Please provide an email address',
  email_invalid: 'Please provide a valid email address',
  user_role: 'Please select a user role',
}

export enum ReceiverUserReducerAction {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  USER_ROLE,
  INIT,
}

export const receiverUserReducer = (state: ReceiverUser, action: { type: ReceiverUserReducerAction, value: any }): ReceiverUser =>  {
  switch (action.type) {
    case ReceiverUserReducerAction.FIRST_NAME:
      return { ...state, first_name: capitaliseFirst(action.value) };
    case ReceiverUserReducerAction.LAST_NAME:
      return { ...state, last_name: capitaliseFirst(action.value) };
    case ReceiverUserReducerAction.EMAIL:
      return { ...state, email: action.value?.toLowerCase() };
    case ReceiverUserReducerAction.USER_ROLE:
      return { ...state, receiver_type: action.value };
    case ReceiverUserReducerAction.INIT:
      const { first_name, last_name, email, receiver_type } = action.value;
      const obj = {
        first_name,
        last_name,
        email,
        receiver_type,
      };
      return { ...obj as ReceiverUser };
    default:
      throw new Error();
  }
}
