import React, { useState, useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import GeneralLayout from '../../../../layouts/General';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { organisationReducer, OrganisationType } from '../Common';
import Steps from '../Common/components/Steps';

import { createOrganisationInitQuery, Lab } from './queries';
import { createOrganisation, createOrganisationType, createOrganisationLabAssignments } from './mutations';


const CreateOrganisation = (): React.ReactElement => {
  const navigate = useNavigate();
  const [organisation, dispatchOrganisation] = useReducer(organisationReducer, {
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_postcode: '',
    contact: '',
    organisation_type: '',
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [labs, setLabs] = useState<Lab[]>([]);
  const [assignedLabs, setAssignedLabs] = useState<Lab[]>([]);
  const [organisationTypes, setOrganisationTypes] = useState<OrganisationType[]>([]);
  const [isNewOrganisationType, setIsNewOrganisationType] = useState<boolean>(false);

  const { data: createOrganisationInitData } = useQuery(createOrganisationInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createOrganisationInitData) {
      setOrganisationTypes(createOrganisationInitData.enum_organisation_types);
      setLabs(createOrganisationInitData.labs);
    }
    return () => { mounted = false; };
  }, [createOrganisationInitData]);

  const handleSave = async () => {
    setSaving(true);

    if (isNewOrganisationType) {
      const insertOrganisationTypeVariables = {
        object: {
          organisation_type: organisation.organisation_type,
        },
      }
      await createOrganisationType(insertOrganisationTypeVariables);
    }

    const insertOrganisationVariables = {
      object: organisation,
    };
    const organisation_id: string | undefined = await createOrganisation(insertOrganisationVariables);
    if (organisation_id) {
      if (assignedLabs.length > 0) {
        const organisationLabAssignments = assignedLabs.map((lab) => ({ lab_id: lab.id, organisation_id }));
        await createOrganisationLabAssignments(organisationLabAssignments);
      }

      setSaving(false);
      handleFinish(organisation_id);
    }

  };

  const handleFinish = (id: string) => {
    navigate(`/admin/organisations/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          organisation={organisation}
          assignedLabs={assignedLabs}
          labs={labs.map((i: Lab) => ({ id: i.id, label: i.name }))}
          organisationTypes={organisationTypes}
          dispatch={dispatchOrganisation}
          setIsNewOrganisationType={setIsNewOrganisationType}
          setAssignedLabs={setAssignedLabs}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Organisation" />}
    </>
  );
};

export default CreateOrganisation;
