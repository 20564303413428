import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './components/App';
import CustomThemeProvider from './components/CustomThemeProvider';


if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://8dd398e5b60043d699182174f5698d4a@o777343.ingest.sentry.io/5985101",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: 'agile-path-front-end@0.0.1',
  });
}

// import * as serviceWorker from './serviceWorker';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    border?: string;
  }

  interface Palette {
    border: string;
  }

  interface PaletteColor {
    black: string;
    white: string;
    green: string;
    red: string;
    neutral: string;
  }

  interface SimplePaletteColorOptions {
    black: string;
    white: string;
    green: string;
    red: string;
    neutral: string;
  }

}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <CustomThemeProvider>
    <App />
    </CustomThemeProvider>
  </StrictMode>
);
// serviceWorker.unregister();
