import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  Grid,
  GridSize,
} from '@mui/material';

import ColumnStepper from './ColumnStepper';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  isNextDisabled: boolean,
  gridSize?: GridSize,
  progressGridSize?: GridSize,
  children: CustomStepperStep[],
  step: number,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  validate: (i: number) => boolean,
  handleComplete: () => void,
}

interface CustomStepperStep {
  label: string,
  detail?: string | null,
  gridSize?: GridSize,
  nextLabel?: string,
  handleNext?: () => void,
  component: React.ReactElement,
}

const CutsomStepper = ({ classes, children = [], completeLabel, isNextDisabled, gridSize = 4, progressGridSize = 4, step, setStep, validate, handleComplete }: Props): React.ReactElement => {
  const handleNextStep = () => {
    if (!validate(step)) {
      setStep((i: number) => i + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep((i: number) => i - 1);
  }

  return (
    <>
      <Grid container justifyContent={'space-between'} spacing={3}>
        <Grid item xs={children[step].gridSize || gridSize}>
          <Grid container>
            <Grid item xs={12}>
              {children[step].component}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={progressGridSize}>
          <ColumnStepper
            title="Progress"
            handleStepClick={(index) => setStep(index)}
            steps={children.map((i) => ({ label: i.label, detail: i.detail, nextLabel: i.nextLabel, handleNext: i.handleNext }))}
            step={step}
            completeLabel={completeLabel}
            isNextDisabled={isNextDisabled}
            handlePrevious={handlePreviousStep}
            handleNext={handleNextStep}
            handleComplete={handleComplete}/>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CutsomStepper);
