import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import { PathwayStep, EnumPathwayStepComopnentType, PathwayReadOnlyStep } from '../../../Common';

import styles from './styles';
import PathwayStepRadio from '../PathwayStepRadio';
import PathwayStepList from '../PathwayStepList';
import PathwayStepReadOnly from '../PathwayStepReadOnly';


interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  data: PathwayStep | PathwayReadOnlyStep,
  value?: string,
  validate?: () => void,
  dispatch?: React.Dispatch<{ type: string, id: string, value: string }>,
}

const PathwayStepElement = ({ classes, data, value = '', validate = () => {}, dispatch }: Props): React.ReactElement => {
  const handleChange = (val: string = '') => {
    if (dispatch) {
      dispatch({ type: 'PATHWAY', id: data.id, value: val });
    }
  }

  const getComponent = (componentType: EnumPathwayStepComopnentType) => {
    switch (componentType) {
      case EnumPathwayStepComopnentType.READ_ONLY:
        return <PathwayStepReadOnly
          data={data as PathwayReadOnlyStep} />
      case EnumPathwayStepComopnentType.RADIO:
        return <PathwayStepRadio
          data={data as PathwayStep}
          value={value}
          handleChange={handleChange}
          validate={validate} />
      case EnumPathwayStepComopnentType.LIST:
        return <PathwayStepList
          data={data as PathwayStep}
          value={value}
          handleChange={handleChange}
          validate={validate} />
      default:
        throw new Error(`Invalid component type: ${componentType}`);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.component}>
        {getComponent(data.component_type)}
      </div>
    </div>
  );
};

export default withStyles(styles)(PathwayStepElement);
