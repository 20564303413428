import { capitaliseFirst } from "../../../../../utils/stringUtils";

export interface SenderUser {
  first_name: string,
  last_name: string,
  email: string,
  sender_type: string,
}
export interface Organisation {
  id: string,
  name: string,
}

export interface ValidationErrors {
  first_name: string | boolean,
  last_name: string | boolean,
  email: string | boolean,
  email_invalid: string | boolean,
  user_role: string | boolean,
}

export interface EnumSenderType {
  sender_type: string;
  description: string;
}

export const validationErrors: ValidationErrors = {
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  email: 'Please provide an email address',
  email_invalid: 'Please provide a valid email address',
  user_role: 'Please select a user role',
}

export enum SenderUserReducerAction {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  USER_ROLE,
  INIT,
}

export const senderUserReducer = (state: SenderUser, action: { type: SenderUserReducerAction, value: any }): SenderUser =>  {
  switch (action.type) {
    case SenderUserReducerAction.FIRST_NAME:
      return { ...state, first_name: capitaliseFirst(action.value) };
    case SenderUserReducerAction.LAST_NAME:
      return { ...state, last_name: capitaliseFirst(action.value) };
    case SenderUserReducerAction.EMAIL:
      return { ...state, email: action.value?.toLowerCase() };
    case SenderUserReducerAction.USER_ROLE:
      return { ...state, sender_type: action.value };
    case SenderUserReducerAction.INIT:
      const { first_name, last_name, email, sender_type } = action.value;
      const obj = {
        first_name,
        last_name,
        email,
        sender_type,
      };
      return { ...obj as SenderUser };
    default:
      throw new Error();
  }
}
