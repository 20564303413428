import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    width: '100%',
  },
  headerTitle: {
    flexGrow: 1,
  },
  headerActions: {
    justifyContent: 'flex-end',
  },
  formControl: {
    minWidth: 200,
  },
  textField: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  // pathwayContainer: {
  //   width: `${Math.round(6 / 12 * 10e7) / 10e5}%`,
  // },
  pots: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
    marginRight: theme.spacing(2),
  },
  potsList: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  notes: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  query: {
    marginTop: theme.spacing(2),
  },
  reasonButton: {
    marginTop: theme.spacing(2),
  }
});

export default styles;
