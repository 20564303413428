import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  Button,
} from '@mui/material';

import {
  Add as AddIcon,
} from '@mui/icons-material';

import Pathway, { PathwayReducerAction, ValidationErrors } from '../../../Common';

import styles from './styles';
import PathwayStepElement from './components/PathwayStepElement';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  pathway: Pathway,
  showSpecimenQualifier?: boolean,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: PathwayReducerAction, value: any }>,
}

const PathwayEdit = ({ classes, pathway, showSpecimenQualifier = false, dispatch, setErrors }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        {pathway.steps.map((step) => (
          <PathwayStepElement
            key={step.id}
            stepCount={pathway.steps.length}
            data={step}
            showSpecimenQualifier={showSpecimenQualifier}
            handleSetErrors={setErrors}
            dispatch={dispatch} />))}
        <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => dispatch({ type: PathwayReducerAction.ADD_STEP, value: {} })}>Add Step</Button>
      </div>
    </>
  );
};

export default withStyles(styles)(PathwayEdit);
