import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertOrganisationMutation = gql`
  mutation ($object: organisations_insert_input!) {
    insert_organisations_one(object: $object) {
      id
    }
  }
`;

const insertOrganisationLabAssignmentMutation = gql`
  mutation ($assignments: [organisation_lab_assignment_insert_input!]!) {
    insert_organisation_lab_assignment(objects: $assignments) {
      returning {
        id
      }
    }
  }
`;

const insertOrganisationTypeMutation = gql`
  mutation ($object: enum_organisation_types_insert_input!) {
    insert_enum_organisation_types_one(object: $object) {
      organisation_type
    }
  }
`;

export const createOrganisation = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_organisations_one: {
      id: string,
    }
  }>({ mutation: insertOrganisationMutation!, variables });
  return res.data?.insert_organisations_one.id;
}

export const createOrganisationLabAssignments = async (assignments: object[]) => {
  return await gqlClient.mutate<{
    insert_organisation_lab_assignment: {
      returning: [{
        id: string,
      }],
    }
  }>({ mutation: insertOrganisationLabAssignmentMutation!, variables: { assignments } });
}

export const createOrganisationType = async (variables: object) => {
  await gqlClient.mutate<{
    insert_enum_organisation_types_one: {
      organisation_type: string,
    }
  }>({ mutation: insertOrganisationTypeMutation!, variables });
}
