import { capitaliseFirst, capitaliseFirstOnly } from "../../../../utils/stringUtils";

export interface Lab {
  name: string,
  address_line_1: string,
  address_line_2: string,
  address_city: string,
  address_postcode: string,
  contact: string,
}

export interface Organisation {
  id: string,
  name: string,
}

export interface ValidationErrors {
  name: string | boolean,
  address_line_1: string | boolean,
  address_city: string | boolean,
  address_postcode: string | boolean,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  address_line_1: 'Please provide a address line 1',
  address_city: 'Please provide a city',
  address_postcode: 'Please provide a postcode',
}

export enum LabReducerAction {
  NAME,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_CITY,
  ADDRESS_POSTCODE,
  CONTACT,
  INIT,
}

export const labReducer = (state: Lab, action: { type: LabReducerAction, value: any }): Lab =>  {
  switch (action.type) {
    case LabReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case LabReducerAction.ADDRESS_LINE_1:
      return { ...state, address_line_1: capitaliseFirst(action.value) };
    case LabReducerAction.ADDRESS_LINE_2:
      return { ...state, address_line_2: capitaliseFirst(action.value) };
    case LabReducerAction.ADDRESS_CITY:
      return { ...state, address_city: capitaliseFirst(action.value) };
    case LabReducerAction.ADDRESS_POSTCODE:
      return { ...state, address_postcode: action.value?.toUpperCase() };
    case LabReducerAction.CONTACT:
      return { ...state, contact: action.value };
    case LabReducerAction.INIT:
      const { name, address_line_1, address_line_2, address_city, address_postcode, contact } = action.value;
      const obj = {
        name,
        address_line_1,
        address_line_2,
        address_city,
        address_postcode,
        contact,
      };
      return { ...obj as Lab };
    default:
      throw new Error();
  }
}
