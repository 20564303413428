import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {},
  jsonField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
});

export default styles;
