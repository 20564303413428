import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import GridCard from '../../../../components/Grid/GridCard';
import GridActionCard from '../../../../components/Grid/GridActionCard';

import { organisationsInitQuery, Organisation } from './queries';


const Organisations = (): React.ReactElement => {
  const navigate = useNavigate();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  const { data: organisationsInitData } = useQuery(organisationsInitQuery);

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationsInitData) {
      setOrganisations(organisationsInitData.organisations);
    }
    return () => { mounted = false; };
  }, [organisationsInitData]);

  const handleCreate = () => {
    navigate('/admin/organisations/create');
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/organisations/${id}/edit`);
  };

  const handleView = (id: string) => {
    navigate(`/admin/organisations/${id}`);
  };

  return (
    <GeneralLayout currentPage="Organisations">
      <Grid container spacing={4} alignItems="stretch">
        <GridActionCard
          fill
          gridSize={4}
          onClick={handleCreate} />
        {organisations.map((organisation: Organisation) => (
          <GridCard
            key={organisation.id}
            gridSize={4}
            title={organisation.name}
            counter={organisation.cases_aggregate.aggregate.count}
            counterLabel="Cases"
            handleEdit={() => handleEdit(organisation.id)}
            handleView={() => handleView(organisation.id)} />
          ))}
      </Grid>
    </GeneralLayout>
  );
};

export default Organisations;
