import React, { useEffect, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useNavigate, useLocation } from 'react-router';

import {
  Button,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import { CaseEvent } from '../Common';

import styles from './styles';
import { updateCaseAccessionNumber, updateCaseEvent, updateCaseEventsAccessionNumber } from './mutations';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const Lims = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [caseEvent] = useState<CaseEvent>(location.state as CaseEvent);
  const [accessionNumber, setAccessionNumber] = useState<string | null>(null);

  const _submit = async () => {
    if (caseEvent.case_id) {
      await updateCaseAccessionNumber({
        pk_columns: {
          id: caseEvent.case_id,
        },
        set: {
          accession_id: accessionNumber,
        },
      });
      await updateCaseEventsAccessionNumber({
        case_id: caseEvent.case_id,
        set: {
          accession_number: accessionNumber,
        },
      });
    }
    const id = await updateCaseEvent({
      pk_columns: {
        id: caseEvent.id,
      },
      set: {
        accession_number: accessionNumber,
        completed: true,
      },
    });
    if (id) {
      navigate('/admin/lims', );
    }
  };

  const _copyData = () => {
    navigator.clipboard.writeText(_dataToJsonString());
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      document.title = "New Case";
    }
    return () => { mounted = false; };
  }, []);

  const _dataToJsonString = () => JSON.stringify(caseEvent.event_data, null, 2);

  return (
    <GeneralLayout currentPage="New Case">
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <TextField
            className={classes.jsonField}
            id="new_case_data"
            value={_dataToJsonString()}
            multiline
            rows={10}
            helperText="id: new_case_data"
          />
          <Button 
            id="new_case_data_button" 
            variant="outlined" 
            color="primary"
            onClick={_copyData}>
            Copy new case data
          </Button>
          <FormHelperText>id: new_case_data_button</FormHelperText>
        </Grid>
        <Grid item xs={4}>
          <TextField 
            className={classes.jsonField}
            id="new_accession_id"
            placeholder="Accession ID"
            helperText="id: new_accession_id"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAccessionNumber(event.target.value)}
          />
          <Button 
            id="new_accession_id_button" 
            variant="outlined"
            color="primary"
            disabled={accessionNumber === null}
            onClick={_submit}>
            Submit accession id
          </Button>
          <FormHelperText>id: new_accession_id_button</FormHelperText>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default withStyles(styles)(Lims);
