import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
  },
  label:{
    marginTop: theme.spacing(2),
  },
});

export default styles;
