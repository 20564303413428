import React, { useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { pluralise } from '../../../utils/stringUtils';

import {
  Grid,
  GridSize,
  InputBase,
  Paper,
  Typography
} from '@mui/material';

import {
  Search as SearchIcon,
  Clear as ClearSearchIcon,
 } from '@mui/icons-material/';

 import ToggleButton from '@mui/material/ToggleButton';
 import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import GridCard from '../../Grid/GridCard';
import GridActionCard from '../../Grid/GridActionCard';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  users: User[],
  userType: string,
  userTypes?: UserType[],
  gridSize?: GridSize,
  handleCreate: () => void,
  handleEdit: (id: string) => void,
  handleView: (id: string) => void,
}

interface User {
  id: string,
  full_name: string,
  assignments?: number,
  type?: {
    value: string,
    description: string,
  },
  email: string,
}

interface UserType {
  type: string,
  description: string,
}

const UsersList = ({ classes, users, userType, userTypes = [], gridSize = 4, handleCreate, handleEdit, handleView }: Props): React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [usersFilter, setUsersFilter] = useState<string[]>([]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>
            <Typography className={classes.title} variant="h5" component="h2">{users.length} {pluralise('User', users.length)}</Typography>
            <div className={classes.searchContainer}>
              {userTypes.length > 0 && (
                <ToggleButtonGroup size="medium" value={usersFilter} color="primary" onChange={(_: unknown, values: string[]) => setUsersFilter(values)}>
                  {userTypes.map((i) =>
                    <ToggleButton
                      className={classes.toggleButton}
                      key={i.type}
                      value={i.type}
                      disabled={users.filter(u => u.type?.value === i.type).length === 0}>
                        {`${users.filter(u => u.type?.value === i.type).length} ${i.description}`}
                    </ToggleButton>)}
                </ToggleButtonGroup>
              )}
              <Paper variant="outlined" className={classes.search}>
                <>
                  <InputBase
                    className={classes.searchInput}
                    placeholder="Search"
                    value={searchValue}
                    onChange={((event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || ''))}
                    startAdornment={<SearchIcon className={classes.searchButton} />}
                    endAdornment={<ClearSearchIcon className={classes.searchButton} onClick={() => setSearchValue('')} />}
                    inputProps={{ 'aria-label': 'search list' }} />
                </>
              </Paper>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <>
          <GridActionCard gridSize={gridSize} fill onClick={handleCreate} />
          {users.filter((i) => i.full_name.toLowerCase().includes(searchValue.toLowerCase())).filter((i) => usersFilter.length > 0 ? usersFilter.includes(i.type?.value || '') : true).map((user) => (
            <GridCard
              fill
              gridSize={gridSize}
              key={user.id}
              title={user.full_name}
              counter={user.assignments}
              counterLabel={`Assigned ${pluralise(userType, user.assignments || 0)}`}
              subTitle={user.type?.description}
              description={user.email}
              handleEdit={() => handleEdit(user.id)}
              handleView={() => handleView(user.id)} />
          ))}
        </>
      </Grid>
    </>
  );
};

export default withStyles(styles)(UsersList);
