import { capitaliseFirstOnly, sentenceCase, generateShortUuid } from "../../../utils/stringUtils";
import { v4 as uuidv4 } from 'uuid';

export default interface Pathway {
  id: string,
  name: string,
  steps: PathwayStep[],
  version_timestamp?: string,
  speciality_category_id?: string,
}

export interface PathwayReadOnlyStep {
  id: string,
  title: string,
  label: string,
  component_type: EnumPathwayStepComopnentType,
}

export interface PathwayStep {
  id: string,
  name: string,
  description: string,
  mandatory: boolean,
  order_index: number,
  component_type: EnumPathwayStepComopnentType,
  specimen_qualifier: string,
  options: PathwayStepOption[],
}

export interface PathwayStepOption {
  id: string,
  label: string,
}

export enum EnumPathwayStepComopnentType {
  RADIO = 'RADIO',
  LIST = 'LIST',
  READ_ONLY = 'READ_ONLY',
}

export enum PathwayReducerAction {
  NAME,
  STEP,
  STEP_ORDER_INDEX,
  STEP_NAME,
  STEP_DESCRIPTION,
  STEP_MANDATORY,
  STEP_OPTIONS,
  STEP_OPTION,
  STEP_SPECIMEN_QUALIFIER,
  COMPONENT_TYPE,
  ADD_STEP,
  REMOVE_STEP,
  ADD_OPTION,
  REMOVE_OPTION,
  OPTION_LABEL,
  OPTION_VALUE,
  INIT,
}

export interface ValidationErrors {
  name: string | boolean,
  steps: string | boolean,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  steps : 'There are issues within one or more steps',
}

export interface ValidationErrorsStep {
  name: string | boolean,
  description: string | boolean,
  options: string | boolean,
}

export const validationErrorsStep: ValidationErrorsStep = {
  name: 'Please provide a name',
  description: 'Please provide a description',
  options: 'There are issues within one or more options',
}

export interface ValidationErrorsStepOption {
  label: string | boolean,
}

export const validationErrorsStepOption: ValidationErrorsStepOption = {
  label : 'Please provide a label',
}

export const pathwayReducer = (state: Pathway, action: { type: PathwayReducerAction, value: any }): Pathway =>  {
  switch (action.type) {
    case PathwayReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case PathwayReducerAction.STEP:
      const stepIndex: number = state.steps.findIndex((i: PathwayStep) => i.id === action.value.id);
      if (stepIndex >= 0) {
        state.steps = state.steps.splice(stepIndex, 1, action.value as PathwayStep);
      } else {
        state.steps.push(action.value);
      }
      return { ...state };
    case PathwayReducerAction.STEP_ORDER_INDEX:
      const stepI = state.steps.findIndex((i: PathwayStep) => i.id === action.value.stepId);
      if (stepI >= 0) {
        state.steps.splice(action.value.order_index, 0, state.steps.splice(stepI, 1)[0]);
        state.steps = state.steps.map((step: PathwayStep, order_index: number) => ({ ...step, order_index }));
        state.steps = state.steps.sort((a: PathwayStep, b: PathwayStep) => a.order_index - b.order_index);
      }
      return { ...state };
    case PathwayReducerAction.STEP_NAME:
      const stepN = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepN) {
        stepN.name = sentenceCase(action.value.name);
      }
      return { ...state };
    case PathwayReducerAction.STEP_MANDATORY:
      const stepM = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepM) {
        stepM.mandatory = action.value.mandatory;
      }
      return { ...state };
    case PathwayReducerAction.STEP_DESCRIPTION:
      const stepD = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepD) {
        stepD.description = sentenceCase(action.value.description);
      }
      return { ...state };
    case PathwayReducerAction.STEP_SPECIMEN_QUALIFIER:
      const stepS = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepS) {
        stepS.specimen_qualifier = action.value.specimen_qualifier.toUpperCase();
      }
      return { ...state };
    case PathwayReducerAction.STEP_OPTIONS:
      const stepO = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepO) {
        stepO.options = action.value.options;
      }
      return { ...state };
    case PathwayReducerAction.STEP_OPTION:
      const stepOp = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepOp) {
        const optionIndex: number = stepOp.options.findIndex((i: PathwayStepOption) => i.id === action.value.id);
        if (optionIndex >= 0) {
          stepOp.options.splice(optionIndex, 1, action.value.option);
        }
      }
      return { ...state };
    case PathwayReducerAction.COMPONENT_TYPE:
      const stepOpc = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepOpc) {
        stepOpc.component_type = action.value.type;
      }
      return { ...state };
    case PathwayReducerAction.ADD_STEP:
      state.steps.push({
        id: uuidv4(),
        name: '',
        description: '',
        mandatory: true,
        order_index: state.steps.length,
        component_type: EnumPathwayStepComopnentType.RADIO,
        specimen_qualifier: '',
        options: [
          { id: generateShortUuid(), label: '' }, 
          { id: generateShortUuid(), label: '' },
        ],
      });
      return { ...state };
    case PathwayReducerAction.REMOVE_STEP:
      state.steps = state.steps.filter(i => i.id !== action.value.stepId);
      return { ...state };
    case PathwayReducerAction.ADD_OPTION:
      const stepA = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepA) {
        stepA.options.push({ id: generateShortUuid(), label: '' });
      }
      return { ...state };
    case PathwayReducerAction.REMOVE_OPTION:
      const stepR = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepR) {
        stepR.options = stepR.options.filter(i => i.id !== action.value.optionId);
      }
      return { ...state };
    case PathwayReducerAction.OPTION_LABEL:
      const stepOpl = state.steps.find((i: PathwayStep) => i.id === action.value.stepId);
      if (stepOpl) {
        const option = stepOpl.options.find((i: PathwayStepOption) => i.id === action.value.optionId);
        if (option) {
          option.label = sentenceCase(action.value.label);
        }
      }
      return { ...state };
    case PathwayReducerAction.INIT:
      if (!action.value) {
        return { ...state };
      }
      const { id, name, steps, version_timestamp, speciality_category_id } = action.value;

      const obj = {
        id,
        name,
        steps: Array(action.value.steps.length).fill({}),
        version_timestamp,
        speciality_category_id,
      };

      steps.sort((a: PathwayStep, b: PathwayStep) => a.order_index - b.order_index).forEach((step: PathwayStep, sIndex: number) => {
        const { id, order_index, component_type, name, description, mandatory, specimen_qualifier, options } = step;
        obj.steps[sIndex] = { id, order_index, component_type, name, description, specimen_qualifier, mandatory };
        obj.steps[sIndex].options = [...options.map((op: PathwayStepOption) => ({ ...op }))];
      });
      return { ...obj as Pathway };
    default:
      throw new Error();
  }
}

interface PathwayValues {
  [key: string]: string,
}

export const pathwayValueReducer = (state: PathwayValues, action: { id: string, value: string }): PathwayValues =>  {
  return { ...state, [action.id]: action.value };
}
