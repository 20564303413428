export interface Speciality {
  id: string,
  name: string,
  icon: string,
  description: string,
  category: Category | null,
}

export interface Category {
  id: string,
  name: string,
  icon: string,
  description: string,
}

export interface SpecialityItem {
  id: string,
  name: string,
  icon: string,
  description: string,
  categories: Category[],
}

export interface ValidationErrors {
  id: string | boolean,
}

export const validationErrors: ValidationErrors = {
  id: 'Please select a speciality',
}

export enum SpecialityReducerAction {
  ID,
  CATEGORY,
  INIT,
}

export const specialityReducer = (state: Speciality, action: { type: SpecialityReducerAction, value: any }): Speciality =>  {
  switch (action.type) {
    case SpecialityReducerAction.ID:
      return { ...state, id: action.value };
    case SpecialityReducerAction.CATEGORY:
      return { ...state, category: action.value };
    case SpecialityReducerAction.INIT:
      const { id, name, icon, description } = action.value;
      const obj = {
        id,
        name,
        icon,
        description,
      };
      return { ...obj as Speciality };
    default:
      throw new Error();
  }
}

