import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  Divider,
  Grid,
  GridSize,
  Typography
} from '@mui/material';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  gridSize?: GridSize,
  title: string,
  subTitle?: string,
  description?: string,
  icon: string | null,
  statusIcon?: React.ReactElement,
  statusIconColour?: 'error' | 'warning' | 'success',
  small?: boolean,
  selected?: boolean,
  divider?: boolean,
  onClick?: () => void,
}

const SpecialityCard = ({ classes, gridSize = 6, title, subTitle, description, icon, statusIcon, statusIconColour, small = false, selected = false, divider = false, onClick }: Props): React.ReactElement => {
  return (
    <Grid item xs={gridSize} zeroMinWidth>
      <div className={`${classes.root} ${!onClick ? classes.noHover : ''} ${small ? classes.small : ''} ${selected ? classes.selected : ''}`} onClick={onClick}>
        <div className={classes.icon}>
          {icon && (
            <img src={`data:image/svg+xml;base64,${btoa(icon)}`} alt="icon" />
          )}
        </div>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant={small ? 'h6' : 'h5'}>{title}</Typography>
          {subTitle && (
            <Typography className={classes.subTitle} variant="subtitle2" color="textSecondary">{subTitle}</Typography>
          )}
          {description && (
            <Typography className={classes.description} variant="subtitle2" color="textSecondary">{description}</Typography>
          )}
          {statusIcon && (
            <div className={`${classes.status} ${statusIconColour ? classes[statusIconColour] : ''}`}>
              {statusIcon}
            </div>
          )}
        </div>
      </div>
      {divider && <Divider />}
    </Grid>
  );
};

export default withStyles(styles)(SpecialityCard);
