import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import { EmptyObject } from '../../react-app-env';

import {
  Paper,
} from '@mui/material';

import {
  Sidebar,
  Topbar,
} from './components';

import styles from './styles';
export interface Breadcrum {
  label: string,
  link?: string,
}

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>,
  title?: string;
  breadcrumbs?: Breadcrum[],
}

const Dashboard = ({ classes, title, breadcrumbs, children }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        <Sidebar />
        <Paper
          elevation={0}
          className={classes.main}>
          <Topbar title={title} breadcrumbs={breadcrumbs} />
          <main className={classes.content}>
            <div className={classes.contentContainer}>
              {children}
            </div>
          </main>
        </Paper>
      </div>
    </>
  );
};

export default withStyles(styles)(Dashboard);
