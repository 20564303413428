import { capitaliseFirstOnly } from "../../../../../utils/stringUtils";

export interface Category {
  name: string,
  description: string,
  icon: string,
}
export interface ValidationErrors {
  name: string | boolean,
  description: string | boolean,
  icon: string | boolean,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  description : 'Please provide a description',
  icon : 'Please provide an icon',
}

export enum CategoryReducerAction {
  NAME,
  DESCRIPTION,
  ICON,
  INIT,
}

export const categoryReducer = (state: Category, action: { type: CategoryReducerAction, value: any }): Category =>  {
  switch (action.type) {
    case CategoryReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case CategoryReducerAction.DESCRIPTION:
      return { ...state, description: capitaliseFirstOnly(action.value) };
    case CategoryReducerAction.ICON:
      return { ...state, icon: action.value };
    case CategoryReducerAction.INIT:
      const { name, description, icon } = action.value;
      const obj = {
        name,
        description,
        icon
      }
      return { ...obj as Category };
    default:
      throw new Error();
  }
}
