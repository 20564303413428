import { UnknownObject } from "../../../../react-app-env";

export interface SpecialityPathway {
  pathway: UnknownObject,
  notes: string,
}

export interface ValidationErrors {
  incomplete: string | boolean,
}

export const validationErrors: ValidationErrors = {
  incomplete: 'Please complete all required * fields',
}

export const specialityPathwayReducer = (state: SpecialityPathway, action: { type: string, id: string, value: string }): SpecialityPathway =>  {
  switch (action.type) {
    case 'PATHWAY':
      return { ...state, pathway: { ...state.pathway, [action.id]: action.value} };
    case 'NOTES':
      return { ...state, notes: action.value };
    default:
      throw new Error();
  }
}

