import React, { useEffect, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useLocation, useNavigate } from 'react-router';
import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import { CaseEvent } from '../Common';

import styles from './styles';
import { updateCaseEvent } from './mutations';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const LimsCaseData = ({ classes }: Props): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [caseEvent] = useState<CaseEvent>(location.state as CaseEvent);

  const _submit = async () => {
    const id = await updateCaseEvent({
      pk_columns: {
        id: caseEvent.id,
      },
      set: {
        completed: true,
      },
    });
    if (id) {
      navigate('/admin/lims', { replace: true });
    }
  };

  const _copyData = () => {
    navigator.clipboard.writeText(_dataToJsonString());
  }

  const _copyAccessionId = () => {
    navigator.clipboard.writeText(caseEvent.accession_number || '');
  }

  

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      document.title = "Case Data";
    }
    return () => { mounted = false; };
  }, []);

  const _dataToJsonString = () => JSON.stringify(caseEvent.event_data, null, 2);

  return (
    <GeneralLayout currentPage="New Case">
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <TextField
            className={classes.jsonField}
            id="case_data"
            value={_dataToJsonString()}
            multiline
            rows={30}
            helperText="id: case_data"
          />
          <Button 
            id="case_data_button" 
            variant="outlined" 
            color="primary"
            onClick={_copyData}>
            Copy case data
          </Button>
          <FormHelperText>id: case_data_button</FormHelperText>
        </Grid>
        <Grid item xs={4}>
          <TextField
            className={classes.jsonField}
            id="accession_id"
            value={caseEvent.accession_number}
            helperText="id: accession_id"
          />
          <Button 
            id="accession_id_button" 
            variant="outlined" 
            color="primary"
            onClick={_copyAccessionId}>
            Copy accession id
          </Button>
          <FormHelperText>id: accession_id_button</FormHelperText>
          <Divider className={classes.divider} />
          <Button 
            id="case_data_complete_button" 
            variant="outlined"
            color="primary"
            onClick={_submit}>
            Submit complete
          </Button>
          <FormHelperText>id: case_data_complete_button</FormHelperText>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default withStyles(styles)(LimsCaseData);
