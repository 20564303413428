import { gql } from '@apollo/client';
import client from './apolloClient';

async function getNhsPatientInfo(nhs_number : string): Promise<string | boolean>  {
  try {
    const GET_YOU_CONNECT_TOKEN = gql`
        query MyQuery {
          getNhsPatientData(nhs_number: "${nhs_number}") {
            patient
          }
        }
      `;
      const tokenData  = await client.query({
      query: GET_YOU_CONNECT_TOKEN,
      fetchPolicy: 'no-cache',
      });
    console.log('Response:', tokenData);

    return tokenData.data.getNhsPatientData.patient;

  } catch (error) {
    console.error('Error during POST request:', error);
    return false;
  }
}

export async function getNhsInfo(nhs_number: string): Promise<string | boolean> {

  return await getNhsPatientInfo(nhs_number);
}

