import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';

import { selectAdminUserInitQuery, AdminUser } from './queries';

const ViewOrganisation = (): React.ReactElement => {
  const navigate = useNavigate();
  const { admin_user_id } = useParams();

  const [user, setUser] = useState<AdminUser | UnknownObject>({});

  const { data: adminUserInitQuery } = useQuery(selectAdminUserInitQuery(admin_user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && adminUserInitQuery) {
      const usr = adminUserInitQuery.users_admin_by_pk;
      setUser(usr);
    }
    return () => { mounted = false; };
  }, [adminUserInitQuery]);

  const handleEdit = () => {
    navigate(`/admin/users/${admin_user_id}/edit`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Admin' },
        { label: 'Users', link: '/admin/users' },
        { label: `${user.full_name}` },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <>
            <ReadOnlyBlock
              items={[
                { label: 'Name', value: user.full_name },
                { label: 'Email', value: user.email },
              ]}
              handleEdit={handleEdit} />
          </>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ViewOrganisation;
