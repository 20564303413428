import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectOrganisationUsersInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    }
  ),
  users_sender: {
    id: types.string,
    full_name: types.string,
    email: types.string,
    gmc_number: types.string,
    dob: types.string,
    gender: types.string,
    organisations_aggregate: {
      aggregate: {
        count: types.number,
      },
    },
    enum_sender_type: {
      sender_type: types.string,
      description: types.string,
    },
  },
  enum_sender_types: [{
  	sender_type: types.string,
    description: types.string,
  }],
});

type X = Unpacked<typeof _selectOrganisationUsersInitQuery>
export type Organisation = X['organisations_by_pk'];
export type OrganisationUser = X['users_sender'];
export type EnumSenderType = X['enum_sender_types'][0];

export const selectOrganisationUsersInitQuery = (id: string) => gql`${query(_selectOrganisationUsersInitQuery(id))}`;
