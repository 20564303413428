import React, { useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { QRCode } from 'react-qrcode-logo';

import { Grid, Tooltip, Typography } from '@mui/material';

import { UnknownObject } from '../../../react-app-env';

import styles from './styles';
import CustomDialog from '../../CustomDialog';

interface PatientInfo {
  name: string;
  nhsNumber: string;
  dob: string;
}

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  patientInformation: PatientInfo;
  data?: UnknownObject;
  rawData?: string;
  size?: number;
}

const PotQR = ({ classes, patientInformation, data, rawData, size = 200 }: Props): React.ReactElement => {
  const [printDialogOpen, setPrintDialogOpen] = useState<boolean>(false);

  const QrCodeComponent = () => (
    <QRCode
      value={rawData ? rawData : JSON.stringify(data)}
      logoImage="/images/icons/app-icon-inverse-rounded.png"
      qrStyle="dots"
      ecLevel="H"
      size={size}
      logoWidth={40}
      logoHeight={40}
      eyeRadius={10}
    />
  );

  return (
    <>
      <Tooltip title={'Click to open print preview'} placement="top">
        <div className={classes.root} onClick={() => setPrintDialogOpen(true)}>
          <QrCodeComponent />
        </div>
      </Tooltip>
      <CustomDialog
        open={printDialogOpen}
        title={''}
        component={
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <QrCodeComponent />
            </Grid>
            <Grid item xs={4}>
              <Grid container style={{ height: '100%' }} justifyContent={"center"} alignContent="center">
                <Grid item>
                  <Typography variant="caption">Name:</Typography>
                  <Typography variant="body2">{patientInformation.name}</Typography>
                  <Typography variant="caption">NHS number:</Typography>
                  <Typography variant="body2">{patientInformation.nhsNumber}</Typography>
                  <Typography variant="caption">Date of Birth:</Typography>
                  <Typography variant="body2">{patientInformation.dob}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        handleClose={() => setPrintDialogOpen(false)}
        actions={[
          { label: 'Cancel', onClick: () => setPrintDialogOpen(false) },
          { label: 'Print', onClick: () => window.print() },
        ]}
      />
    </>
  );
};

export default withStyles(styles)(PotQR);
