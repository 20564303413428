import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import { PathwayReadOnlyStep } from '../Common';

import styles from './styles';
import PathwayStepElement from './components/PathwayStepElement';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  pathway: PathwayReadOnlyStep[] | undefined,
}

const PathwayReadOnlyView = ({ classes, pathway }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        {pathway?.map((step) => (
          <PathwayStepElement
            data={step} />))}
      </div>
    </>
  );
};

export default withStyles(styles)(PathwayReadOnlyView);
