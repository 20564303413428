
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectOrganisationInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      address_line_1: types.string,
      address_line_2: types.string,
      address_city: types.string,
      address_postcode: types.string,
      name: types.string,
      organisation_type: types.string,
      contact: types.string,
      enum_organisation_type: {
        description: types.string,
      },
      lab_assignments: {
        lab: {
          id: types.string,
          name: types.string,
        },
      },
      users_aggregate: {
        aggregate: {
          count: types.number,
        }
      }
    },
  ),
});

type X = Unpacked<typeof _selectOrganisationInitQuery>
export type Organisation = X['organisations_by_pk'];
export type LabAssignment = X['organisations_by_pk']['lab_assignments'];
export type Lab = LabAssignment['lab'];

export const selectOrganisationInitQuery = (id: string) => gql`${query(_selectOrganisationInitQuery(id))}`;
