import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectLabUsersInitQuery = (id: string) => ({
  labs_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    },
  ),
  users_receiver: {
    id: types.string,
    full_name: types.string,
    email: types.string,
    hcpc_number: types.string,
    dob: types.string,
    gender: types.string,
    labs_aggregate: {
      aggregate: {
        count: types.number,
      },
    },
    enum_receiver_type: {
      receiver_type: types.string,
      description: types.string,
    },
  },
  enum_receiver_types: [{
  	receiver_type: types.string,
    description: types.string,
  }],
});

type X = Unpacked<typeof _selectLabUsersInitQuery>
export type Lab = X['labs_by_pk'];
export type LabUser = X['users_receiver'];
export type EnumReceiverType = X['enum_receiver_types'][0];

export const selectLabUsersInitQuery = (id: string) => gql`${query(_selectLabUsersInitQuery(id))}`;
