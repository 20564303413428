import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  Button,
  Divider,
  Typography
} from '@mui/material';

import {
  CheckCircleOutlined as CompletedIcon,
} from '@mui/icons-material';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  title: string,
  nextLabel?: string,
  previousLabel?: string,
  completeLabel?: string,
  steps: CustomStepperStep[],
  step: number,
  isNextDisabled?: boolean,
  handleStepClick: (index: number) => void,
  handleNext?: () => void,
  handlePrevious?: () => void,
  handleComplete?: () => void,
}

interface CustomStepperStep {
  label: string,
  detail?: string | null,
  nextLabel?: string,
  handleNext?: () => void,
}

const ColumnStepper = ({ classes, title, steps, step = 0, isNextDisabled = false, nextLabel = 'Next', previousLabel = 'Previous', completeLabel = 'Complete', handleStepClick, handleNext, handlePrevious, handleComplete }: Props): React.ReactElement => {
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5">{title}</Typography>
      <Divider />
      {steps.map((i, index) => (
        <div key={i.label}>
          <div className={`${classes.step} ${index < step ? classes.completed : ''}`} onClick={() => index < step ? handleStepClick(index) : null}>
            <div className={classes.label}>
              <Typography className={index === step ? classes.active : ''} color="textSecondary">{i.label}</Typography>
              {i.detail && (
                <Typography className={classes.detail}>{i.detail}</Typography>
              )}
            </div>
            {index < step && (
              <div className={classes.completedIcon}>
                <CompletedIcon />
              </div>
            )}
          </div>
          <Divider />
        </div>
      ))}
      <div className={classes.actions}>
        {handleNext && step + 1 < steps.length && (
          <Button className={classes.action} data-qa="next-step" variant="contained" color="primary" onClick={steps[step].handleNext || handleNext} disabled={isNextDisabled}>{steps[step].nextLabel || nextLabel}</Button>
        )}
        {handleComplete && step + 1 === steps.length && (
          <Button className={classes.action} data-qa="complete-step" variant="contained" color="primary" onClick={handleComplete}>{completeLabel}</Button>
        )}
        {handlePrevious && step > 0 && (
          <Button className={classes.action} data-qa="previous-step" variant="outlined" color="primary" onClick={handlePrevious}>{previousLabel}</Button>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ColumnStepper);
