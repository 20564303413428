import { capitaliseFirstOnly } from "../../../../utils/stringUtils";

export interface Speciality {
  name: string,
  description: string,
  icon: string,
}
export interface ValidationErrors {
  name: string | boolean,
  description: string | boolean,
  icon: string | boolean,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  description : 'Please provide a description',
  icon : 'Please provide an icon',
}

export enum SpecialityReducerAction {
  NAME,
  DESCRIPTION,
  ICON,
  INIT,
}

export const specialityReducer = (state: Speciality, action: { type: SpecialityReducerAction, value: any }): Speciality =>  {
  switch (action.type) {
    case SpecialityReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case SpecialityReducerAction.DESCRIPTION:
      return { ...state, description: action.value || '' };
    case SpecialityReducerAction.ICON:
      return { ...state, icon: action.value };
    case SpecialityReducerAction.INIT:
      const { name, description, icon } = action.value;
      const obj = {
        name,
        description,
        icon
      }
      return { ...obj as Speciality };
    default:
      throw new Error();
  }
}
