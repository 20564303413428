import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  Grid,
  Typography,
} from '@mui/material';

import SpecialityCard from '../../../../../../components/SpecialityCard';
import { Speciality, SpecialityItem, ValidationErrors, SpecialityReducerAction } from '../../speciality';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  speciality: Speciality,
  specialities: SpecialityItem[],
  errors: ValidationErrors,
  nextStep: () => void,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: SpecialityReducerAction, value: any}>,
}

const StepSpeciality = ({ classes, speciality, specialities, errors, nextStep, setErrors, dispatch }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        {!speciality.id && (
          <>
            <Typography className={classes.header} variant="h4">Select Speciality</Typography>
            <Grid container spacing={4}>
              {specialities.map((i) => (
                <SpecialityCard
                  key={i.id}
                  title={i.name}
                  description={i.description}
                  icon={i.icon}
                  onClick={() => dispatch({ type: SpecialityReducerAction.INIT, value: i })} />
              ))}
            </Grid>
          </>
        )}
        {speciality.id && (
          <>
            <Typography className={classes.header} variant="h4">Select Speciality Catagory</Typography>
            <Grid container spacing={4}>
              {specialities.find((i) => i.id === speciality.id)?.categories.map((i) => (
                <SpecialityCard
                  key={i.id}
                  title={i.name}
                  description={i.description}
                  icon={i.icon}
                  onClick={() => {
                    dispatch({ type: SpecialityReducerAction.CATEGORY, value: i });
                    nextStep();
                }} />
              ))}
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(StepSpeciality);
