import { Box, CircularProgress, Divider, Drawer, Grid, Toolbar } from '@mui/material';
import AppMenu from '../../../components/Menu';
import DrawerSwitch from './Switcher';
import { drawerWidth, openedMixin, closedMixin } from './common';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { MenuItemProps } from '../../../components/Menu/MenuItem';
import { listItems } from '../../../components/DashboardProviderArgs';
import { useContext } from 'react';
import { AuthContext } from '../../../components/AuthProvider';
import { useNavigate } from 'react-router';

interface Props {
  open: boolean;
  setOpenDrawer: (open: boolean) => void;
}

const Sidebar = ({ open, setOpenDrawer }: Props) => {
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }
  const navigate = useNavigate()
  const { userRole, signOut } = authContext;

  const menu =
    listItems
      .find((item) => item.name === userRole)
      ?.payload.items.map((item) => ({
        name: item.label,
        link: item.to,
        Icon: item.icon,
        items: [],
      })) ?? [];

  const footerMenu = [
    {
      name: 'Log out',
      Icon: LogoutIcon,
      action: () => {
        signOut().then(() => navigate('/sign-in',  { replace: true }));
      },
    },
  ].filter(Boolean) as MenuItemProps[];

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={(theme) => ({
        '& .MuiToolbar-root': {
          minHeight: '60px',
        },
        width: drawerWidth,
        flexShrink: 0,
        border: 0,
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
        '&:hover #drawer-switch-button': {
          opacity: 1,
        },
      })}>
      <Toolbar />
      <Box
        sx={(theme) => ({
          padding: theme.spacing(2),
          height: '100%',
        })}>
        {userRole ? (
          <Grid container direction="column" justifyContent="space-between" height="100%">
            <Grid width={'100%'} item>
              <AppMenu sidebarOpen={open} subHeader="" menuItems={menu} />
            </Grid>
            <Grid width={'100%'} item>
              <Divider />
              <AppMenu sidebarOpen={open} subHeader="" menuItems={footerMenu} />
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '30vh',
              alignItems: 'center',
              justifyContent: 'center',
              ml: 2,
            }}>
            <CircularProgress
              sx={{
                height: '64px !important',
                width: '64px !important',
                color: '#FF612B',
              }}
            />
          </Box>
        )}
      </Box>
      <DrawerSwitch open={open} setOpenDrawer={setOpenDrawer} drawerWidth={drawerWidth} />
    </Drawer>
  );
};

export default Sidebar;
