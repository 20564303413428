import React, { useState, useReducer, useContext, useCallback, useEffect } from 'react';
import { UnknownObject } from '../../../../../react-app-env';

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import GeneralLayout from '../../../../../layouts/General';

import { AuthContext } from '../../../../../components/AuthProvider';
import CustomBackdrop from '../../../../../components/CustomBackdrop';

import Steps from '../Common/components/Steps';

import { caseSearchReducer } from '../Common/case_search';
import { EnumPotStatus, caseReducer, CaseReducerAction, CasePot } from '../Common/case';

import { caseCheckInInitQuery, getCaseQuery } from './queries';
import { insertPotQueries, updateCase, updatePot } from './mutations';


const CheckInCase = (): React.ReactElement => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)!;

  const [caseSearch, dispatchCaseSearch] = useReducer(caseSearchReducer, {
    case_id: '',
  });

  const [caseData, dispatchCase] = useReducer(caseReducer, {});
  const [potStatuses, setPotStatuses] = useState<EnumPotStatus[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: caseCheckInInitData } = useQuery(caseCheckInInitQuery, { fetchPolicy: 'cache-first' });

  useEffect(() => {
    let mounted = true;
    if (mounted && caseCheckInInitData) {
      setPotStatuses(caseCheckInInitData.enum_pot_status);
    }
    return () => {
      mounted = false;
    };
  }, [caseCheckInInitData]);

  const handleSave = async () => {
    setSaving(true);

    const updateCaseVariables = {
      pk_columns: {
        id: caseData.id,
      },
      set: {
        received_by: user?.id,
        received_at: new Date().toISOString(),
      },
    };
    await updateCase(updateCaseVariables);

    await Promise.all(
      caseData.pots.map((pot: CasePot) => {
        const updatePotVariables = {
          pk_columns: {
            id: pot.id,
          },
          set: {
            updated_by: user?.id,
            status: pot.status,
          },
        };
        return updatePot(updatePotVariables);
      })
    );

    const potQueries = caseData.pots.filter((i: CasePot) => i.reason);

    const insertPotQueriesVariables: UnknownObject = {
      objects: potQueries.map((pot: CasePot) => ({
        pot_id: pot.id,
        message: pot.reason,
        created_by: user?.id,
      })),
    };

    await insertPotQueries(insertPotQueriesVariables);

    handleFinish(caseData.id);
  };

  const handleFinish = (id: string) => {
    setSaving(false);
    navigate(`/lab/cases/${caseData.id}`);
  };

  const handleSearch = useCallback(async () => {
    const where: UnknownObject[] = [];
    if (caseSearch.case_id) {
      where.push({
        field: 'id',
        value: caseSearch.case_id,
      });
    }

    const c = await getCaseQuery(where);
    if (c) {
      dispatchCase({ type: CaseReducerAction.INIT, value: c });
    }
    return c?.id || '';
  }, [caseSearch]);

  return (
    <>
      <GeneralLayout breadcrumbs={[{ label: 'Cases', link: '/lab/cases' }, { label: 'Check-In' }]}>
        <Steps
          completeLabel="Check-In"
          caseData={caseData}
          caseSearch={caseSearch}
          dispatchCase={dispatchCase}
          dispatchCaseSearch={dispatchCaseSearch}
          user={user}
          potStatuses={potStatuses}
          handleSearch={handleSearch}
          handleSave={handleSave}
        />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Case" />}
    </>
  );
};

export default CheckInCase;
