import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';

import Pathway from '../../../../../../components/PathwayBuilder/Common';
import PathwayView from '../../../../../../components/PathwayBuilder/View';
import { ValidationErrors } from '../../speciality_pathway';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  pathway: Pathway,
  values: UnknownObject,
  notes: string,
  errors: ValidationErrors,
  validate: () => void,
  // setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: string, id: string, value: string}>,
}

const StepSpecialityPathway = ({ classes, pathway, values, notes = '', errors, validate, dispatch }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h4">Clinical Information</Typography>
          {errors.incomplete && <FormHelperText error>{errors.incomplete}</FormHelperText>}
        </div>
        <PathwayView
          pathway={pathway}
          values={values}
          validate={validate}
          dispatch={dispatch} />
        <TextField
          className={classes.notes}
          data-qa="notes-textfield"
          label="Additional information"
          variant="outlined"
          value={notes}
          multiline
          rows={4}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: 'NOTES', id: '', value: event.target.value })} />
      </div>
    </>
  );
};

export default withStyles(styles)(StepSpecialityPathway);
