import React, { useState, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import GeneralLayout from '../../../../../../layouts/General';

import CustomBackdrop from '../../../../../../components/CustomBackdrop';
import Steps from '../Common/components/Steps';
import { pathwayReducer, PathwayReducerAction, PathwayStep } from "../../../../../../components/PathwayBuilder/Common";

import { editSurgicalPathwaySteps } from './mutations';
import { editSurgicalPathwayInitQuery, Speciality, Category } from './queries';


const EditSurgicalPathway = (): React.ReactElement => {
  const navigate = useNavigate();
  const { speciality_id, category_id, surgical_pathway_id } = useParams();

  const [pathway, dispatchPathway] = useReducer(pathwayReducer, {
    id: '',
    name: '',
    steps: [],
  });

  const [speciality, setSpeciality] = useState<Speciality | null>(null);
  const [category, setCategory] = useState<Category | null>(null);
  const [originalStepIds, setOriginalStepIds] = useState<string[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const { data: editSurgicalPathwayInitData } = useQuery(editSurgicalPathwayInitQuery(speciality_id!, category_id!, surgical_pathway_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editSurgicalPathwayInitData) {
      setSpeciality(editSurgicalPathwayInitData.specialities_by_pk);
      setCategory(editSurgicalPathwayInitData.speciality_categories_by_pk);
      dispatchPathway({ type: PathwayReducerAction.INIT, value: editSurgicalPathwayInitData.surgical_pathways_by_pk });
      setOriginalStepIds(editSurgicalPathwayInitData.surgical_pathways_by_pk.steps.map((i: PathwayStep) => i.id));
    }
    return () => { mounted = false; };
  }, [editSurgicalPathwayInitData]);

  const handleSave = async () => {
    setSaving(true);

    const stepIds = pathway.steps.map((i) => i.id);
    const deleteIds = originalStepIds.filter((id) => !stepIds.includes(id));

    const updateStepVariables = {
      objects: pathway.steps.map((step: PathwayStep) => ({ ...step, surgical_pathway_id })),
      remove: deleteIds,
    };
    await editSurgicalPathwaySteps(updateStepVariables);

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Specialities', link: '/admin/specialities' },
          { label: `${speciality?.name}`, link: `/admin/specialities/${speciality_id}` },
          { label: `${category?.name}`, link: `/admin/specialities/${speciality_id}/${category_id}` },
          { label: `Surgical Pathway` },
          { label: 'Edit' },
        ]}>
        <Steps
          completeLabel="Save"
          pathway={pathway}
          dispatch={dispatchPathway}
          handleSave={handleSave}/>
      </GeneralLayout>
      {saving && <CustomBackdrop label="Saving Category" />}
    </>
  );
};

export default EditSurgicalPathway;
