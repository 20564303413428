import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';
import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';

import {
  Grid,
  Typography,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import GridCard from '../../../../components/Grid/GridCard';

import styles from './styles';
import { selectOrganisationInitQuery, Organisation, LabAssignment } from './queries';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}


const ViewOrganisation = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { organisation_id } = useParams();

  const [organisation, setOrganisation] = useState<Organisation | UnknownObject>({});
  const [assignedLabs, setAssignedLabs] = useState<string[]>([]);

  const { data: organisationsInitData } = useQuery(selectOrganisationInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationsInitData) {
      const org = organisationsInitData.organisations_by_pk;
      setOrganisation(org);
      setAssignedLabs(org.lab_assignments.map((i: LabAssignment) => i.lab.name));
    }
    return () => { mounted = false; };
  }, [organisationsInitData]);

  const handleEdit = () => {
    navigate(`/admin/organisations/${organisation_id}/edit`);
  };

  const handleViewUsers = () => {
    navigate(`/admin/organisations/${organisation_id}/users`);
  };

  const handleCreateUser = () => {
    navigate(`/admin/organisations/${organisation_id}/users/create`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: organisation.name },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.header}>
                  <Typography className={classes.title} variant="h5" component="h2">{organisation.name}</Typography>
                </div>
              </Grid>
            </Grid>
            <ReadOnlyBlock
            gridSize={3}
              items={[
                { label: 'Organisation type', value: organisation.enum_organisation_type?.description },
                { label: 'Address', list: [
                  organisation.address_line_1,
                  organisation.address_line_2,
                  organisation.address_city,
                  organisation.address_postcode,
                ]},
                { label: 'Contact', value: organisation.contact || 'No contact information found' },
                { label: `${assignedLabs.length} Assigned labs`, list: assignedLabs },
              ]}
              handleEdit={handleEdit} />
          </>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <GridCard
                fill
                gridSize={12}
                counter={organisation?.users_aggregate?.aggregate.count}
                key="Users"
                title="Users"
                subTitle="Organisation users"
                editLabel="Create"
                handleEdit={() => handleCreateUser()}
                handleView={() => handleViewUsers()} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default withStyles(styles)(ViewOrganisation);
