import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../react-app-env';
import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../../layouts/General';
import UsersList from '../../../../../components/Users/List';

import { selectOrganisationUsersInitQuery, OrganisationUser, Organisation, EnumSenderType } from './queries';

const ListOrganisationUsers = (): React.ReactElement => {
  const navigate = useNavigate();
  const { organisation_id } = useParams();

  const [organisation, setOrganisation] = useState<Organisation | UnknownObject>({});
  const [users, setUsers] = useState<OrganisationUser[]>([]);
  const [userTypes, setUserTypes] = useState<EnumSenderType[]>([]);

  const { data: organisationUsersInitQuery } = useQuery(selectOrganisationUsersInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUsersInitQuery) {
      setOrganisation(organisationUsersInitQuery.organisations_by_pk);
      setUsers(organisationUsersInitQuery.users_sender);
      setUserTypes(organisationUsersInitQuery.enum_sender_types);
    }
    return () => { mounted = false; };
  }, [organisationUsersInitQuery]);

  const handleCreate = () => {
    navigate(`/admin/organisations/${organisation_id}/users/create`);
  };

  const handleView = (id: string) => {
    navigate(`/admin/organisations/${organisation_id}/users/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/organisations/${organisation_id}/users/${id}/edit`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Users' },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UsersList
            users={users.map((i) => ({ id: i.id, full_name: i.full_name, email: i.email, assignments: i.organisations_aggregate.aggregate.count, type: { value: i.enum_sender_type.sender_type, description: i.enum_sender_type.description } }))}
            userType="Organisation"
            userTypes={userTypes.map((i) => ({ type: i.sender_type, description: i.description }))}
            gridSize={4}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleView={handleView}
          />
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ListOrganisationUsers;
