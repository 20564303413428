export interface CaseSearch {
  case_id: string;
}

export interface ValidationErrors {
  incomplete: string | boolean;
}

export const validationErrors = {
  incomplete: 'Please provide Case ID',
};

export interface Validation {
  incomplete: (caseSearch: CaseSearch) => string | boolean;
}

export const validation: Validation = {
  incomplete: (caseSearch) => {
    if (!caseSearch.case_id) {
      return validationErrors.incomplete;
    }
    return false;
  },
};

export enum CaseSearchReducerAction {
  CASE_ID,
}

export const caseSearchReducer = (state: CaseSearch, action: { type: CaseSearchReducerAction; value: any }): CaseSearch => {
  switch (action.type) {
    case CaseSearchReducerAction.CASE_ID:
      return { ...state, case_id: action.value };
    default:
      throw new Error();
  }
};
