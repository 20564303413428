import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

export interface Speciality {
  name: string,
  description: string,
  icon: string,
}

const updateSpecialityMutation = gql`
  mutation ($pk_columns: specialities_pk_columns_input!, $set: specialities_set_input!) {
    update_specialities_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const editSpeciality = async (variables: object) => {
  return await gqlClient.mutate<{
    update_specialities_by_pk: {
      id: string,
    }
  }>({ mutation: updateSpecialityMutation!, variables });
}
