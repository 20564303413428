import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { ReceiverUser, SenderUser, UnknownObject, User } from '../../../../../../../react-app-env';

import CustomStepper from '../../../../../../../components/CustomStepper';
// import Pathway from '../../../../../../../components/PathwayBuilder/Common';

import {
  CaseSearch,
  ValidationErrors as ValidationCaseSearchErrors,
  validation as validationCaseSearch,
  CaseSearchReducerAction,
} from '../../case_search';

import {
  Case,
  CasePot,
  validation as validationCase,
  CaseReducerAction,
  EnumPotStatus,
} from '../../case';

import { pluralise } from '../../../../../../../utils/stringUtils';

import StepSearchCase from '../StepSearchCase';
import StepCase from '../StepCase';
import StepPots from '../StepPots';
// import StepCase from '../StepCase';
// import StepPots from '../StepPots';
// import StepReview from '../StepReview';

import styles from './styles';
import StepReview from '../StepReview';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  caseData: Case | UnknownObject,
  caseSearch: CaseSearch,
  user: User | ReceiverUser | SenderUser | null,
  potStatuses: EnumPotStatus[],
  dispatchCaseSearch: React.Dispatch<{ type: CaseSearchReducerAction, value: any }>,
  dispatchCase: React.Dispatch<{ type: CaseReducerAction, value: any }>,
  handleSearch: () => void,
  handleSave: () => void,
}

const CaseSteps = ({ classes, completeLabel, caseData, caseSearch, user, potStatuses, dispatchCaseSearch, dispatchCase, handleSearch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [caseSearchErrors, setCaseSearchErrors] = useState<ValidationCaseSearchErrors>({
    incomplete: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.incomplete = validationCaseSearch.incomplete(caseSearch);
      setCaseSearchErrors({...caseSearchErrors, ...errorItems});
      return Object.values(errorItems).some((i) => i);
    }
    if (_step === 2) {
      return caseData.pots.some((i: CasePot) => !validationCase.isPotValid(i));
    }
    return false;
  }, [caseData, caseSearch, caseSearchErrors]);

  const isNextDisabled = () => {
    if (step === 0) {
      return Object.values(caseSearchErrors).some((i) => i);
    }
    if (step === 2) {
      return caseData.pots.some((i: CasePot) => !validationCase.isPotValid(i));
    }
    return false;
  }

  useEffect(() => {
    let mounted = true;
    if (mounted && caseData.id) {
      setStep(1);
    }
    return () => { mounted = false; };
  }, [caseData.id]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={isNextDisabled()}
        completeLabel={completeLabel}
        gridSize={9}
        progressGridSize={3}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Search Case',
            detail: caseData?.accession_id,
            nextLabel: 'Search',
            gridSize: 6,
            handleNext: handleSearch,
            component:
              <StepSearchCase
                caseSearch={caseSearch}
                errors={caseSearchErrors}
                setErrors={setCaseSearchErrors}
                dispatch={dispatchCaseSearch} />
          },
          {
            label: 'Case review',
            component:
              <StepCase
                caseData={caseData}
                user={user} />
          },
          {
            label: 'Pots',
            detail: caseData.pots ? `${caseData.pots.length} ${pluralise('Pot', caseData.pots.length)}` : undefined,
            component:
              <StepPots
                caseData={caseData}
                validate={() => validate(step)}
                potStatuses={potStatuses}
                dispatch={dispatchCase} />
          },
          {
            label: 'Summary',
            detail: caseData.pots ? `${caseData.pots.length} ${pluralise('Pot', caseData.pots.length)}` : undefined,
            component:
              <StepReview
                caseData={caseData}
                potStatuses={potStatuses}
                setStep={setStep} />
          },
        ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(CaseSteps);
