
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _selectSurgicalPathwayInitQuery = (speciality_id: string, category_id: string, surgical_pathway_id: string) => ({
  surgical_pathways_by_pk: params(
    { id: rawString(surgical_pathway_id) },
    {
      id: types.string,
      name: types.string,
      steps: [{
        id: types.string,
        order_index: types.number,
        component_type: types.string,
        mandatory: types.boolean,
        name: types.string,
        description: types.string,
        options: types.string,
      }]
    },
  ),
  specialities_by_pk: params(
    { id: rawString(speciality_id) },
    {
      id: types.string,
      name: types.string,
    },
  ),
  speciality_categories_by_pk: params(
    { id: rawString(category_id) },
    {
      name: types.string,
      description: types.string,
      icon: types.string,
    },
  ),
});

type X = Unpacked<typeof _selectSurgicalPathwayInitQuery>
export type SurgicalPathway = X['surgical_pathways_by_pk'];
export type Speciality = X['specialities_by_pk'];
export type Category = X['speciality_categories_by_pk'];

export const selectSurgicalPathwayInitQuery = (speciality_id: string, category_id: string, surgical_pathway_id: string) => gql`${query(_selectSurgicalPathwayInitQuery(speciality_id, category_id, surgical_pathway_id))}`;
