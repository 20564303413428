import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _createOrganisationUserInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    }
  ),
  organisations: params(
    { order_by: { name: 'asc' }},
    [{
      id: types.string,
      name: types.string,
    }],
  ),
  enum_sender_types: [{
  	sender_type: types.string,
    description: types.string,
  }],
});

type X = Unpacked<typeof _createOrganisationUserInitQuery>
export type Organisation = X['organisations'][0];
export type EnumSenderType = X['enum_sender_types'][0];

export const createOrganisationUserInitQuery = (id: string) => gql`${query(_createOrganisationUserInitQuery(id))}`;
