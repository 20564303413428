import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from '@mui/material';

import { PathwayStep } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  value: string,
  data: PathwayStep,
  validate: () => void,
  handleChange: (i: string) => void,
}

const PathwayStepList = ({ classes, value, data, validate, handleChange }: Props): React.ReactElement => {
  return (
    <div className={classes.root}>
      <FormControl variant="outlined" required={data.mandatory} className={classes.formControl}>
        <InputLabel htmlFor={`${data.id}-select`}>{data.name}</InputLabel>
        <Select
          id={`${data.id}-select`}
          label={data.name}
          value={value}
          inputProps={{
            name: data.name,
            id: `${data.id}-select`,
          }}
          onBlur={validate}
          onChange={(event) => handleChange(event.target.value as string)} >
            {data.options.map((i) => <MenuItem key={i.id} value={i.id}>{i.label}</MenuItem>)}
        </Select>
        <FormHelperText className={classes.description}>{data.description}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(PathwayStepList);
