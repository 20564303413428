import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import moment from 'moment';

import { Grid, Typography } from '@mui/material';

import { ReceiverUser, SenderUser, UnknownObject, User } from '../../../../../../../react-app-env';
import ReadOnlyBlock from '../../../../../../../components/ReadOnlyBlock';
import SpecialityCard from '../../../../../../../components/SpecialityCard';
import PotQR from '../../../../../../../components/QrCodes/PotQR';

import { Case, getPatientInfoFromEvents } from '../../case';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  caseData: Case | UnknownObject;
  user: User | ReceiverUser | SenderUser | null;
}

const StepCase = ({ classes, caseData, user }: Props): React.ReactElement => {
  const patientName = getPatientInfoFromEvents(caseData);
  const patientNhsNumber = caseData?.patient?.nhs_id || '';
  const patientDob = moment(caseData?.patient?.dob).format('DD/MM/yyyy');

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <ReadOnlyBlock
                fill
                title="Patient Details"
                items={[
                  {
                    label: 'Name',
                    value: patientName,
                  },
                  { label: 'NHS number', value: patientNhsNumber },
                  { label: 'Medical Record Number', value: caseData.mrn },
                  { label: 'Date of Birth', value: patientDob },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <ReadOnlyBlock
                fill
                title="Sender Details"
                items={[
                  { label: 'Organisation', value: caseData.organisation?.name },
                  { label: 'Lab', value: caseData.lab?.name },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <ReadOnlyBlock
                fill
                center
                components={[
                  <PotQR
                    patientInformation={{
                      name: patientName,
                      nhsNumber: patientNhsNumber,
                      dob: patientDob,
                    }}
                    rawData={caseData.id}
                  />,
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <ReadOnlyBlock
                fill
                title="Clinical Details"
                components={[
                  <div className={classes.reviewDetailsContainer}>
                    <SpecialityCard
                      small
                      divider
                      gridSize={12}
                      title={caseData.speciality.name.split('|')[0]}
                      description={caseData.speciality.name.split('|')[1]}
                      icon={caseData.speciality.icon}
                    />
                    <div className={classes.specialityDetails}>
                      <Grid container spacing={2}>
                        {caseData.surgical_pathways?.map((pathway: UnknownObject) => (
                          <Grid key={pathway.id} item xs={6}>
                            <div className={classes.specialityStep}>
                              <Typography variant="subtitle2" color="textSecondary">
                                {pathway.title}
                              </Typography>
                              <Typography className={classes.stepValue} variant="body1">
                                {pathway.label}
                              </Typography>
                            </div>
                          </Grid>
                        ))}
                        {caseData.note && (
                          <Grid item xs={12}>
                            <Typography variant="body1" color="textSecondary">
                              Additional information: {caseData.note}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </div>,
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyBlock
                fill
                title="Pot Details"
                components={[
                  <div className={classes.reviewDetailsContainer}>
                    {caseData.pots?.map((pot: UnknownObject) => (
                      <div className={classes.potContainer}>
                        <Typography variant="subtitle1" color="textSecondary">
                          {pot.name}
                        </Typography>
                        <Typography className={classes.stepValue} variant="body1">
                          {pot.pathway.map((i: UnknownObject) => i.label).join(', ')}
                        </Typography>
                        {pot.note && (
                          <Typography className={classes.stepValue} variant="subtitle2" color="textSecondary">
                            Additional information: {pot.note}
                          </Typography>
                        )}
                      </div>
                    ))}
                  </div>,
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(StepCase);
