
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editOrganisationInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation_type: types.string,
      address_line_1: types.string,
      address_line_2: types.string,
      address_city: types.string,
      address_postcode: types.string,
      contact: types.string,
      lab_assignments: {
        id: types.string,
        lab: {
          id: types.string,
          name: types.string,
        },
      },
    },
  ),
  enum_organisation_types: [{
    organisation_type: types.string,
    description: types.string,
  }],
  labs: [{
    id: types.string,
    name: types.string,
  }],
});

type X = Unpacked<typeof _editOrganisationInitQuery>
export type LabAssignment = X['organisations_by_pk']['lab_assignments'];
export type Lab = LabAssignment['lab'];
export type EnumOrganisationType = X['enum_organisation_types'][0];

export const editOrganisationInitQuery = (id: string) => gql`${query(_editOrganisationInitQuery(id))}`;
