import React, { useState, useReducer } from 'react';

import GeneralLayout from '../../../../layouts/General';
import { useNavigate } from 'react-router';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { adminUserReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createAdminUser } from './mutations';


const CreateLabUser = (): React.ReactElement => {
  const navigate = useNavigate();

  const [user, dispatchUser] = useReducer(adminUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const createVariables = {
      object: user,
    };
    const user_id: string | undefined = await createAdminUser(createVariables);
    if (user_id) {
      setSaving(false);
      handleFinish(user_id);
    }
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/users/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Admin' },
          { label: 'Users', link: '/admin/users' },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          user={user}
          dispatch={dispatchUser}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default CreateLabUser;
