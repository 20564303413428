import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  Divider,
  TextField,
} from '@mui/material';

import { Organisation, ValidationErrors, validationErrors, OrganisationReducerAction } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  organisation: Organisation,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: OrganisationReducerAction, value: any}>,
}

const StepDetails = ({ classes, organisation, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'name') {
      errorItems.name = !organisation.name ? validationErrors.name : false;
    }
    if (field === 'address_line_1') {
      errorItems.address_line_1 = !organisation.address_line_1 ? validationErrors.address_line_1 : false;
    }
    if (field === 'address_city') {
      errorItems.address_city = !organisation.address_city ? validationErrors.address_city : false;
    }
    if (field === 'address_postcode') {
      errorItems.address_postcode = !organisation.address_postcode ? validationErrors.address_postcode : false;
    }
    setErrors({...errors, ...errorItems});
  }, [organisation, errors, setErrors]);


  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={organisation.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}/>
        <Divider className={classes.divider} />
        <TextField
          className={classes.textField}
          data-qa="address-line-1-textfield"
          required
          label="Address line 1"
          variant="outlined"
          error={errors.address_line_1 as boolean}
          helperText={errors.address_line_1}
          value={organisation.address_line_1}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.ADDRESS_LINE_1, value: event.target.value })}
          onKeyUp={() => validate('address_line_1')}
          onBlur={() => validate('address_line_1')} />
        <TextField
          className={classes.textField}
          data-qa="address-line-2-textfield"
          label="Address line 2"
          variant="outlined"
          value={organisation.address_line_2}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.ADDRESS_LINE_2, value: event.target.value })} />
        <TextField
          className={classes.textField}
          data-qa="address-city-textfield"
          required
          label="City"
          variant="outlined"
          error={errors.address_city as boolean}
          helperText={errors.address_city}
          value={organisation.address_city}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.ADDRESS_CITY, value: event.target.value })}
          onKeyUp={() => validate('address_city')}
          onBlur={() => validate('address_city')} />
        <TextField
          className={classes.textField}
          data-qa="address-postcode-textfield"
          required
          label="Postcode"
          variant="outlined"
          error={errors.address_postcode as boolean}
          helperText={errors.address_postcode}
          value={organisation.address_postcode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.ADDRESS_POSTCODE, value: event.target.value })}
          onKeyUp={() => validate('address_postcode')}
          onBlur={() => validate('address_postcode')} />
        <Divider className={classes.divider} />
        <TextField
          className={classes.textField}
          data-qa="contact-textfield"
          label="Contact"
          variant="outlined"
          helperText="Email / Phone"
          value={organisation.contact}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.CONTACT, value: event.target.value })} />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
