import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../../../react-app-env';

import CustomStepper from '../../../../../../../../components/CustomStepper';
import Pathway, { PathwayReducerAction, ValidationErrors, validationErrors } from "../../../../../../../../components/PathwayBuilder/Common";
import { PathwayEdit, PathwayPreview, PathwayReview } from '../../../../../../../../components/PathwayBuilder/Edit/components';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  pathway: Pathway,
  dispatch: React.Dispatch<{ type: PathwayReducerAction, value: any }>,
  handleSave: () => void,
}

const SurgicalPathwaySteps = ({ classes, completeLabel, pathway, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    steps: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.steps = pathway.steps.length === 0 ? validationErrors.steps : false;
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [pathway, errors]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        gridSize={6}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Builder',
            component:
              <PathwayEdit
                pathway={pathway}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Preview',
            gridSize: 4,
            component:
              <PathwayPreview
                pathway={pathway} />
          },
          {
            label: 'Summary',
            component:
              <PathwayReview
                pathway={pathway}
                setStep={setStep} />
            },
          ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(SurgicalPathwaySteps);
