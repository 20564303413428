import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import CustomStepper from '../../../../../../components/CustomStepper';
import { Speciality, validationErrors, ValidationErrors, SpecialityReducerAction } from '../../../Common';
import StepDetails from '../StepDetails';
import StepIcon from '../StepIcon';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  speciality: Speciality,
  dispatch: React.Dispatch<{ type: SpecialityReducerAction, value: any }>,
  handleSave: () => void,
}

const SpecialitySteps = ({ classes, completeLabel, speciality, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    description: false,
    icon: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = !speciality.name ? validationErrors.name : false;
      errorItems.description = !speciality.description ? validationErrors.description : false;
    }
    setErrors({ ...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [speciality, errors]);

  return (
    <>
      <CustomStepper
          step={step}
          isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
          completeLabel={completeLabel}
          setStep={setStep}
          validate={validate}
          handleComplete={handleSave} >
          {[
            {
              label: 'Details',
              component:
                <StepDetails
                  speciality={speciality}
                  errors={errors}
                  setErrors={setErrors}
                  dispatch={dispatch} />
            },
            {
              label: 'Icon',
              component:
                <StepIcon
                  speciality={speciality}
                  dispatch={dispatch} />
            },
            {
              label: 'Summary',
              component:
                <StepReview
                  speciality={speciality}
                  setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(SpecialitySteps);
