import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../react-app-env';

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import { Grid } from '@mui/material';
import ReadOnlyBlock from '../../../../../components/ReadOnlyBlock';
import GridActionCard from '../../../../../components/Grid/GridActionCard';
import { PathwayStep, PathwayStepOption } from '../../../../../components/PathwayBuilder/Common';

import { Category, selectCategoryInitQuery, Speciality } from './queries';
import GridCard from '../../../../../components/Grid/GridCard';
import { GeneralLayout } from '../../../../../layouts';


const ViewCategory = (): React.ReactElement => {
  const navigate = useNavigate();
  const { speciality_id, category_id } = useParams();

  const [speciality, setSpeciality] = useState<Speciality | UnknownObject>({});
  const [category, setCategory] = useState<Category | UnknownObject>({});

  const { data: categoriesInitData } = useQuery(selectCategoryInitQuery(speciality_id!, category_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoriesInitData) {
      setSpeciality(categoriesInitData.specialities_by_pk);
      setCategory(categoriesInitData.speciality_categories_by_pk);
    }
    return () => { mounted = false; };
  }, [categoriesInitData]);

  const handleEdit = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}/edit`);
  };

  const handleCreateSurgicalPathway = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}/surgical_pathway/create`);
  };

  const handleViewSurgicalPathway = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}/surgical_pathway/${category.surgical_pathway.id}`);
  };

  const handleEditSurgicalPathway = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}/surgical_pathway/${category.surgical_pathway.id}/edit`);
  };

  const handleCreatePotPathway = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}/pot_pathway/create`);
  };

  const handleViewPotPathway = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}/pot_pathway/${category.pot_pathway.id}`);
  };

  const handleEditPotPathway = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}/pot_pathway/${category.pot_pathway.id}/edit`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Specialities', link: '/admin/specialities' },
        { label: `${speciality?.name}`, link: `/admin/specialities/${speciality_id}` },
        { label: `${category?.name}`, link: `/admin/specialities/${speciality_id}/${category_id}` },
      ]} currentPage={""}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <GridCard
                title={category?.name}
                description={category?.description}
                svg={category?.icon ? `data:image/svg+xml;base64,${btoa(category?.icon)}` : undefined}
                gridSize={12}
                handleEdit={handleEdit} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {category?.surgical_pathway
              ? (
                <Grid item xs={6}>
                  <ReadOnlyBlock
                    fill
                    title="Surgical Pathway"
                    items={[
                      ...category.surgical_pathway.steps.map((i: PathwayStep) => ({ label: i.name, value: i.description, list: i.options.map((o: PathwayStepOption) => o.label) })),
                    ]}
                    handleView={handleViewSurgicalPathway}
                    handleEdit={handleEditSurgicalPathway} />
                  </Grid>
              )
              : (
                <GridActionCard
                  fill
                  label="Create Surgical Pathway"
                  gridSize={6}
                  onClick={handleCreateSurgicalPathway} />
              )}
              {category?.pot_pathway
                ? (
                  <Grid item xs={6}>
                    <ReadOnlyBlock
                      fill
                      title="Pot Pathway"
                      items={[
                        ...category.pot_pathway.steps.map((i: PathwayStep) => ({ label: i.name, value: i.description, list: i.options.map((o: PathwayStepOption) => o.label) })),
                      ]}
                      handleView={handleViewPotPathway}
                      handleEdit={handleEditPotPathway} />
                    </Grid>
                )
                : (
                  <GridActionCard
                    fill
                    label="Create Pot Pathway"
                    gridSize={6}
                    onClick={handleCreatePotPathway} />
                )}
          </Grid>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ViewCategory;