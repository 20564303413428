
import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _organisationsInitQuery = {
  organisations: params(
    { order_by: { name: 'asc' }},
    [{
      id: types.string,
      name: types.string,
      organisation_type: types.string,
      cases_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }],
  )
};

type X = typeof _organisationsInitQuery
export type Organisation = X['organisations'][0];

export const organisationsInitQuery = gql`${query(_organisationsInitQuery)}`;
