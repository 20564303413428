import { UnknownObject } from "../../../../react-app-env";
import { v4 as uuidv4 } from 'uuid';
import { capitaliseFirstOnly, numericToAlpha } from "../../../../utils/stringUtils";

export interface Pots {
  pots: Pot[],
}

export interface Pot {
  id: string,
  name: string,
  pathway: UnknownObject,
  notes: string,
  selected: boolean,
}

export interface ValidationErrors {
  no_name: string | boolean,
  incomplete: string | boolean,
}

export const validationErrors: ValidationErrors = {
  no_name: 'Please provide a name for the pot',
  incomplete: 'Please complete all required * fields',
}

export enum PotsReducerAction {
  ADD_POT,
  NAME,
  NOTES,
  UPDATE_POT,
  SELECT_POT,
  DELETE_POT,
  INIT,
}

export const potsReducer = (state: Pots, action: { type: PotsReducerAction, value: any }): Pots =>  {
  switch (action.type) {
    case PotsReducerAction.ADD_POT:
      return {
        ...state,
        pots: [
          ...state.pots.map((i, index) => ({ ...i, selected: false, name: `Pot ${numericToAlpha(index + 27)}` })),
          {
            id: uuidv4(),
            name: `Pot ${numericToAlpha(state.pots.length + 27)}`,
            pathway: {},
            selected: true,
            notes: '',
          },
        ],
      };
    case PotsReducerAction.SELECT_POT:
      return {
        ...state,
        pots: state.pots.map((i) => ({ ...i, selected: i.id === action.value.id })),
      };
    case PotsReducerAction.DELETE_POT:
      return {
        ...state,
        pots: state.pots.filter((i) => i.id !== action.value.id),
      };
    case PotsReducerAction.NAME:
      const pIndex = state.pots.findIndex((i) => i.id === action.value.id);
      if (pIndex >= 0) {
        const pots = [...state.pots];
        pots[pIndex].name = capitaliseFirstOnly(action.value.name);
        return { ...state, pots };
      }
      return state;
    case PotsReducerAction.NOTES:
      const pIndx = state.pots.findIndex((i) => i.id === action.value.id);
      if (pIndx >= 0) {
        const pots = [...state.pots];
        pots[pIndx].notes = action.value.notes;
        return { ...state, pots };
      }
      return state;
    case PotsReducerAction.UPDATE_POT:
      const potIndex = state.pots.findIndex((i) => i.id === action.value.id);
      if (potIndex >= 0) {
        const pots = [...state.pots];
        pots[potIndex] = action.value;
        return { ...state, pots };
      }
      return state;
    case PotsReducerAction.INIT:
      const potsInit = action.value;
      const pots: Pot[] = [];
      potsInit.forEach((pot: UnknownObject) => {
        const { id, name, pathway, selected = false, notes } = pot;
        pots.push({ id, name, pathway, selected, notes });
      });
      return { pots };
    default:
      throw new Error();
  }
}

