import React, { createContext } from 'react';

interface AppContextItems {}

export const AppContext = createContext<AppContextItems | null>(null);

type Props = { children: React.ReactNode };

const StateProvider = ({ children }: Props): React.ReactElement => {

  return (
    <AppContext.Provider value={{}}>
      {children}
    </AppContext.Provider>
  );
};

export default StateProvider;
