import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { pluralise, stringFromArray } from '../../../../../../../utils/stringUtils';

import ReadOnlyBlock from '../../../../../../../components/ReadOnlyBlock';
import { EnumReceiverType, ReceiverUser } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  user: ReceiverUser,
  assignedLabs: string[],
  userTypes: EnumReceiverType[],
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, user, userTypes, assignedLabs, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Name', value: stringFromArray([user.first_name, user.last_name]) },
          { label: 'Email', value: user.email },
        ]}
        handleEdit={() => setStep(0)} />
      <ReadOnlyBlock
        items={[
          { label: 'User role', value: userTypes.find(i => i.receiver_type === user.receiver_type)?.description }
        ]}
        handleEdit={() => setStep(1)} />
      <ReadOnlyBlock
        items={[
          { label: `${assignedLabs.length} ${pluralise('Assigned Lab', assignedLabs.length)}`, list: assignedLabs }
        ]}
        handleEdit={() => setStep(2)} />
  </>
  );
};

export default withStyles(styles)(StepReview);
