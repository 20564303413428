import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateCaseEventMutation = gql`
  mutation ($pk_columns: case_events_pk_columns_input!, $set: case_events_set_input!) {
    update_case_events_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateCaseEvent = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    update_case_events_by_pk: {
      id: string,
    }
  }>({ mutation: updateCaseEventMutation!, variables });
  return res.data?.update_case_events_by_pk.id;
}
