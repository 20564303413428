import React, { useState, useEffect, useReducer, useContext, useCallback } from 'react';

import { useQuery, useSubscription } from '@apollo/client';

import GeneralLayout from '../../../../layouts/General';
import { useNavigate } from 'react-router';
import { AuthContext } from '../../../../components/AuthProvider';
import { wordsToAcronym } from '../../../../utils/stringUtils';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import Pathway, { PathwayStep } from '../../../../components/PathwayBuilder/Common';

import Steps from '../Common/components/Steps';

import { patientReducer } from '../Common/patient';
import { specialityReducer } from '../Common/speciality';
import { specialityPathwayReducer } from '../Common/speciality_pathway';
import { potsReducer, PotsReducerAction } from '../Common/pots';
import { Organisation } from '../Common/organisation';
import { Lab } from '../Common/lab';

import { getPathwaysQuery, createCaseInitQuery, Speciality, LabAssignment, getExistingDataIds } from './queries';
import { createCase, createCaseEvent, createPotPathwayVersion, updateCaseEvent } from './mutations';
import { UnknownObject } from '../../../../react-app-env';
import { _newCaseEventSubscription } from './subscriptions';



const CreateCase = (): React.ReactElement => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)!;
  const [accessionNumber, setAccessionNumber] = useState<string | undefined>(undefined);
  const [newCaseEventId, setNewCaseEventId] = useState('');

  const { data: newCaseEvent } = useSubscription(_newCaseEventSubscription(newCaseEventId));

  const [patient, dispatchPatient] = useReducer(patientReducer, {
    nhs_number: '',
    mrn: '',
    first_name: '',
    last_name: '',
    dob: null,
  });

  const [speciality, dispatchSpeciality] = useReducer(specialityReducer, {
    id: '',
    name: '',
    icon: '',
    description: '',
    category: null,
  });

  const [specialityPathwayValues, dispatchSpecialityPathwayValues] = useReducer(specialityPathwayReducer, {
    pathway: {},
    notes: '',
  });

  const [pots, dispatchPots] = useReducer(potsReducer, {
    pots: [],
  });

  const [specialityPathway, setSpecialityPathway] = useState<Pathway>({
    id: '',
    name: '',
    steps: [],
  });

  const [potPathway, setPotPathway] = useState<Pathway>({
    id: '',
    name: '',
    steps: [],
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<Organisation>();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [lab, setLab] = useState<Lab>();
  const [labs, setLabs] = useState<Lab[]>([]);
  const [specialities, setSpecialities] = useState<Speciality[]>([]);

  const { data: createCaseInitData } = useQuery(createCaseInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createCaseInitData) {
      const orgs = createCaseInitData.organisations;
      setOrganisations(orgs);
      if (orgs.length === 1) {
        setOrganisation(orgs[0]);
      }
      const lbs = createCaseInitData.organisation_lab_assignment.map((i: LabAssignment) => ({ ...i.lab, organisation_id: i.organisation_id }));
      setLabs(lbs);
      if (lbs.length === 1) {
        setLab(lbs[0]);
      }
      setSpecialities(createCaseInitData.specialities);
      dispatchPots({ type: PotsReducerAction.ADD_POT, value: null });
    }
    return () => { mounted = false; };
  }, [createCaseInitData]);

  useEffect(() => {
    let mounted = true;
    if (mounted && newCaseEvent) {
      const accession_number = newCaseEvent.case_events_by_pk.accession_number;
      if (accession_number) {
        setAccessionNumber(accession_number);
      }
    }
    return () => { mounted = false; };
  }, [newCaseEvent]);

  const handleSave = async () => {
    setSaving(true);

    const organisation_id = organisation?.id;
    const lab_id = lab?.id;

    const {
      a_patient_id,
      a_surgical_pathway_version_id,
      a_pot_pathway_version_id,
    } = await getExistingDataIds(patient.nhs_number, specialityPathway.id, specialityPathway?.version_timestamp || '', potPathway.id, potPathway?.version_timestamp || '');


    let pot_pathway_version_id: string = a_pot_pathway_version_id;

    if (!a_pot_pathway_version_id) {
      const insertPotPathwayVersionVariables = {
        object: {
          data: {
            name: potPathway.name,
            steps: potPathway.steps,
          },
          pot_pathway_id: potPathway.id,
          version_timestamp: potPathway.version_timestamp,
        }
      };
      pot_pathway_version_id = await createPotPathwayVersion(insertPotPathwayVersionVariables);
    }

    let accession_id_prefix = `${wordsToAcronym(`${organisation?.name}`)}`;
    if (accession_id_prefix.length === 1) {
      accession_id_prefix = `${organisation?.name.toLocaleUpperCase().substring(0, 3)}`;
    }
    // const assession__org_code = generateNumberUuid(2);
    // const assession_lab_code = generateNumberUuid(6);
    // const accession_id = `${accession_id_prefix}${assession__org_code}-${assession_lab_code}`;
    
    const specimen_qualifier = potPathway.steps.find((i) => i.specimen_qualifier);
    
    const insertCaseVariables: UnknownObject = {
      object: {
        organisation_id,
        lab_id,
        pots: {
          data: pots.pots.map((pot) => ({
            organisation_id,
            lab_id,
            speciality_category_id: speciality.category?.id,
            name: pot.name,
            pathway: potPathway.steps.map((p: PathwayStep) => ({
              id: p.id,
              title: p.name,
              label: p.options.find((o) => o.id === pot.pathway[p.id])?.label,
              value: pot.pathway[p.id],
            })).filter((i) => i.value),
            note: pot.notes,
            created_by: user?.id,
            pot_pathway_version_id,
            specimen_qualifier: specimen_qualifier ? specimen_qualifier.specimen_qualifier : '',
          })),
        },
        mrn: patient.mrn,
        accession_id: accessionNumber,
        surgical_pathways: specialityPathway.steps.map((p: PathwayStep) => ({
          id: p.id,
          title: p.name,
          label: p.options.find((o) => o.id === specialityPathwayValues.pathway[p.id])?.label,
          value: specialityPathwayValues.pathway[p.id],
        })).filter((i) => i.value),
        note: specialityPathwayValues.notes,
        created_by: user?.id,
      },
    };

    if (!a_patient_id) {
      insertCaseVariables.object.patient = {
        data: {
          nhs_id: patient.nhs_number,
          dob: patient.dob?.toISOString(),
        }
      }
    } else {
      insertCaseVariables.object.patient_id = a_patient_id;
    }

    if (!a_surgical_pathway_version_id) {
      insertCaseVariables.object.surgical_pathway_version = {
        data: {
          data: {
            name: specialityPathway.name,
            steps: specialityPathway.steps,
          },
          surgical_pathway_id: specialityPathway.id,
          version_timestamp: specialityPathway.version_timestamp,
        }
      }
    } else {
      insertCaseVariables.object.surgical_pathway_version_id = a_surgical_pathway_version_id;
    }
    const case_id: string | undefined = await createCase(insertCaseVariables);
    if (case_id) {
      await handleUpdateNewCaseEvent(case_id);
      await handleCreateFullCaseEvent(case_id);
      setSaving(false);
      handleFinish(case_id);
    }
  };

  const handleCreateNewCaseEvent = useCallback(async () => {
    const caseEventId = await createCaseEvent({
      object: {
        event_type: 'NEW_CASE',
        organisation_id: organisation?.id,
        event_data: {
          dob: patient.dob?.toISOString(),
          last_name: patient.last_name,
          first_name: patient.first_name,
          nhs_number: patient.nhs_number,
        },
      }
    });
    setNewCaseEventId(caseEventId!);
  
  }, [organisation, patient]);

  const handleUpdateNewCaseEvent = useCallback(async (case_id: string) => {
    const caseEventId = await updateCaseEvent({
      pk_columns: {
        id: newCaseEventId,
      },
      set: {
        case_id,
      }
    });
    setNewCaseEventId(caseEventId!);
  }, [newCaseEventId]);


  const handleCreateFullCaseEvent = useCallback(async (case_id: string) => {
    const specimen_qualifier = potPathway.steps.find((i) => i.specimen_qualifier);
    await createCaseEvent({
      object: {
        event_type: 'CASE_DATA',
        organisation_id: organisation?.id,
        accession_number: accessionNumber ?? null,
        case_id,
        event_data: {
          patient: {
            dob: patient.dob?.toISOString(),
            last_name: patient.last_name,
            first_name: patient.first_name,
            nhs_number: patient.nhs_number,
          },
          pots: pots.pots.map((pot) => ({
            name: pot.name,
            note: pot.notes,
            specimen_qualifier: specimen_qualifier ? specimen_qualifier.specimen_qualifier : '',
          })),
          report: specialityPathway.steps.map((p: PathwayStep) => ({
            title: p.name,
            label: p.options.find((o) => o.id === specialityPathwayValues.pathway[p.id])?.label,
          })).filter((i) => i.label),
        },
      }
    });
  }, [accessionNumber, patient, potPathway, organisation, pots, specialityPathway, specialityPathwayValues]);


  const handleFinish = (id: string) => {
    navigate(`/org/cases/${id}`);
  };

  const handleCategoryChange = useCallback(async (category_id: string) => {
    if (!accessionNumber) {
      handleCreateNewCaseEvent();
    }
    const res = await getPathwaysQuery(category_id);
    const data = {
      ...res.data.surgical_pathways[0],
      steps: res.data.surgical_pathways[0].steps.map((i) => ({ ...i, specimen_qualifier: '', })),
    };
    setSpecialityPathway(data);
    setPotPathway(res.data.pot_pathways[0]);
  }, [accessionNumber, handleCreateNewCaseEvent]);

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Cases', link: '/org/cases' },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          patient={patient}
          speciality={speciality}
          specialities={specialities}
          specialityPathwayValues={specialityPathwayValues}
          accessionNumber={accessionNumber}
          pots={pots.pots}
          user={user}
          organisation={organisation}
          organisations={organisations}
          lab={lab}
          labs={labs}
          specialityPathway={specialityPathway}
          potPathway={potPathway}
          dispatchPatient={dispatchPatient}
          dispatchSpeciality={dispatchSpeciality}
          dispatchSpecialityPathwayValues={dispatchSpecialityPathwayValues}
          setOrganisation={setOrganisation}
          setSpecialityPathway={setSpecialityPathway}
          setPotPathway={setPotPathway}
          setLab={setLab}
          dispatchPots={dispatchPots}
          handleCategoryChange={handleCategoryChange}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Case" />}
    </>
  );
};

export default CreateCase;
