import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

import styles from './styles';
import { PathwayStep } from '../../../Common';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  value: string,
  data: PathwayStep,
  validate: () => void,
  handleChange: (i: string) => void,
}

const PathwayStepRadio = ({ classes, value, data, validate, handleChange }: Props): React.ReactElement => {
  return (
    <div className={classes.root}>
      <FormControl className={classes.fieldset} required={data.mandatory} component="fieldset">
        <FormLabel className={classes.name} component="legend">{data.name}</FormLabel>
        <FormHelperText className={classes.description}>{data.description}</FormHelperText>
        <RadioGroup value={value} onBlur={validate} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value as string)}>
          {data.options.map((i) => (
            <FormControlLabel key={i.id} value={i.id} control={<Radio color="primary" />} label={i.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(PathwayStepRadio);
