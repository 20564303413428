import React from 'react';
import { useTheme } from '@mui/material/styles';
import useLocalStorage from '../../hooks/useLocalStorage';

interface Props {
  className?: string,
  color?: string,
}

const EditGridIcon = ({ className = '', color }: Props ): React.ReactElement => {
  const theme = useTheme();
  const [darkMode] = useLocalStorage<boolean>('dark-mode', false);

  const textColour = darkMode ? '#FFFFFF' : '#020202';

  let fill = color || textColour;
  if (color === 'primary') {
    fill = theme.palette.primary.main;
  }

  return (
    <svg id="EditGridIcon" style={{ fill }} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172">
      <path d="M64.5,21.5c7.83362,0 14.33333,6.49972 14.33333,14.33333v28.66667c0,7.83362 -6.49972,14.33333 -14.33333,14.33333h-28.66667c-7.83362,0 -14.33333,-6.49972 -14.33333,-14.33333v-28.66667c0,-7.83362 6.49972,-14.33333 14.33333,-14.33333zM136.16667,21.5c7.83362,0 14.33333,6.49972 14.33333,14.33333v28.66667c0,7.83362 -6.49972,14.33333 -14.33333,14.33333h-28.66667c-7.83362,0 -14.33333,-6.49972 -14.33333,-14.33333v-28.66667c0,-7.83362 6.49972,-14.33333 14.33333,-14.33333zM35.83333,64.5h28.66667v-28.66667h-28.66667zM107.5,64.5h28.66667v-28.66667h-28.66667zM64.5,93.16667c7.83362,0 14.33333,6.49972 14.33333,14.33333v28.66667c0,7.83362 -6.49972,14.33333 -14.33333,14.33333h-28.66667c-7.83362,0 -14.33333,-6.49972 -14.33333,-14.33333v-28.66667c0,-7.83362 6.49972,-14.33333 14.33333,-14.33333zM136.16667,93.16667c4.22753,0 8.06658,1.89297 10.70523,4.86699c-0.1288,0.12146 -0.25622,0.24518 -0.38222,0.37118l-10.32301,10.32301v-1.22784h-28.66667v28.66667h1.22784l-10.70004,10.70004c-2.97065,-2.63849 -4.86114,-6.47528 -4.86114,-10.70004v-28.66667c0,-7.83362 6.49972,-14.33333 14.33333,-14.33333zM35.83333,136.16667h28.66667v-28.66667h-28.66667z"/>
      <path d="M144.37628,120.78405l14.33416,14.33416l-36.88179,36.88179h-14.33416v-14.33416zM156.61765,108.54267l-7.16708,7.16708l14.33416,14.33416l7.16708,-7.16708c1.39758,-1.39758 1.39758,-3.66955 0,-5.06713l-9.26703,-9.26703c-1.40475,-1.39758 -3.66955,-1.39758 -5.06713,0z"/>
    </svg>
  );
};

export default EditGridIcon;
