import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateOrganisationMutation = gql`
  mutation ($pk_columns: organisations_pk_columns_input!, $set: organisations_set_input!) {
    update_organisations_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

const updateOrganisationLabAssignmentMutation = gql`
  mutation ($assignments: [organisation_lab_assignment_insert_input!]!, $remove: [uuid!]!) {
    insert_organisation_lab_assignment(objects: $assignments) {
      returning {
        id
      }
    }
    delete_organisation_lab_assignment(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

const insertOrganisationTypeMutation = gql`
  mutation ($object: enum_organisation_types_insert_input!) {
    insert_enum_organisation_types_one(object: $object) {
      organisation_type
    }
  }
`;

export const updateOrganisation = async (variables: object) => {
  return await gqlClient.mutate<{
    update_organisations_by_pk: {
      id: string,
    }
  }>({ mutation: updateOrganisationMutation!, variables });
}

export const updateOrganisationLabAssignments = async (assignments: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    update_organisation_lab_assignment: {
      returning: [{
        id: string,
      }],
    }
    delete_organisation_lab_assignment: {
      affected_rows: number
    }
  }>({ mutation: updateOrganisationLabAssignmentMutation!, variables: { assignments, remove } });
}

export const createOrganisationType = async (variables: object) => {
  await gqlClient.mutate<{
    insert_enum_organisation_types_one: {
      organisation_type: string,
    }
  }>({ mutation: insertOrganisationTypeMutation!, variables });
}
