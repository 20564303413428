import { gql } from '@apollo/client';
import gqlClient from '../../../../../../utils/apolloClient';

const updatePotPathwayMutation = gql`
  mutation ($pk_columns: pot_pathways_pk_columns_input!, $set: pot_pathways_set_input!) {
    update_pot_pathways_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

const updatePotPathwayStepsMutation = gql`
  mutation ($objects: [pot_pathway_steps_insert_input!]!, $remove: [uuid!]!) {
    insert_pot_pathway_steps(
      objects: $objects,
      on_conflict: {
        constraint: pot_pathway_steps_pkey,
        update_columns: [order_index, component_type, name, description, mandatory, options, specimen_qualifier]
      }
    )
    {
      returning {
        id
      }
    }
    delete_pot_pathway_steps(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const editPotPathway = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    update_pot_pathways_by_pk: {
      id: string,
    }
  }>({ mutation: updatePotPathwayMutation!, variables });
  return res.data?.update_pot_pathways_by_pk.id;
}


export const editPotPathwaySteps = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_pot_pathway_steps: {
      returning: {
        id: string,
      }
    }
  }>({ mutation: updatePotPathwayStepsMutation!, variables });
}
