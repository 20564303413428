import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import {
  Grid
} from '@mui/material';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  isEdit: boolean,
  children: SortableGridChild[],
  onDragEnd: (result: DropResult) => void,
}

interface SortableGridChild {
  key: string,
  component: React.ReactElement,
}

const SortableGrid = ({ classes, children = [], isEdit = false, onDragEnd }: Props): React.ReactElement => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="grid">
        {(provided) => (
          <div ref={provided.innerRef}>
            <Grid container direction={isEdit ? 'column' : 'row'}>
              {children.map((child, index) => (
                <Grid item xs={6}>
                  <Draggable draggableId={child.key} key={child.key} index={index} isDragDisabled={!isEdit}>
                    {(providedInner) => (
                      <div
                        className={classes.fill}
                        ref={providedInner.innerRef}
                        {...providedInner.draggableProps}
                        {...providedInner.dragHandleProps}>
                          {child.component}
                      </div>
                    )}
                  </Draggable>
                </Grid>
              ))}
              {provided.placeholder}
            </Grid>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default withStyles(styles)(SortableGrid);
