import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  FormControl,
  TextField,
  Typography,
} from '@mui/material';

import { Patient, validation, ValidationErrors, PatientReducerAction, validationErrors } from '../../patient';

import styles from './styles';
import DatePicker from '../../../../../../components/DatePicker';
import { getNhsInfo } from '../../../../../../utils/youConnectClient';
import { PatientNhsDetails } from '../../../../../../utils/patientNhsDetails';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  patient: Patient,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: PatientReducerAction, value: any}>,
}

const StepPatient = ({ classes, patient, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(async (field: string, value?: any) => {
    const errorItems: UnknownObject = {}
    if (field === 'nhs_number') {
      let nhsError = validation.nhs_number(value || patient.nhs_number);
      if (!nhsError) {
        const patientDetails = await getNhsInfo(value || patient.nhs_number);
        if(!patientDetails){
          nhsError = validationErrors.nhs_number_details;
        }
        else
        {
          let patientNhsData: PatientNhsDetails = JSON.parse(patientDetails as string);

          patient.first_name = patientNhsData?.name[0]?.given[0] ?? patient.first_name as string;
          patient.last_name = patientNhsData.name[0].family as string;
          patient.dob = new Date(patientNhsData?.birthDate as string);
        }
      }
      errorItems.nhs_number = nhsError;
    }
    if (field === 'mrn') {
      errorItems.mrn = validation.mrn(patient.mrn);
    }
    if (field === 'first_name') {
      errorItems.first_name = validation.first_name(value || patient.first_name);
    }
    if (field === 'last_name') {
      errorItems.last_name = validation.last_name(value || patient.last_name);
    }
    if (field === 'dob') {
      errorItems.dob = validation.dob(value || patient.dob);
    }
    setErrors({ ...errors, ...errorItems });
  }, [patient, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.header} variant="h4">Patient</Typography>
        <TextField
          className={classes.textField}
          data-qa="nhs_number-textfield"
          required
          label="NHS number"
          variant="outlined"
          error={errors.nhs_number as boolean}
          helperText={errors.nhs_number}
          value={patient.nhs_number}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PatientReducerAction.NHS_NUMBER, value: event.target.value })}
          onKeyUp={() => validate('nhs_number')}
          onBlur={() => validate('nhs_number')} />
        <TextField
          className={classes.textField}
          data-qa="mrn-textfield"
          required
          label="Medical Record Number"
          variant="outlined"
          error={errors.mrn as boolean}
          helperText={errors.mrn}
          value={patient.mrn}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PatientReducerAction.MRN, value: event.target.value })}
          onKeyUp={() => validate('mrn')}
          onBlur={() => validate('mrn')} />
        <TextField
          className={classes.textField}
          data-qa="first_name-textfield"
          required
          label="First name"
          variant="outlined"
          error={errors.first_name as boolean}
          helperText={errors.first_name}
          value={patient.first_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PatientReducerAction.FIRST_NAME, value: event.target.value })}
          onKeyUp={() => validate('first_name')}
          onBlur={() => validate('first_name')} />
        <TextField
          className={classes.textField}
          data-qa="last_name-textfield"
          required
          label="Last name"
          variant="outlined"
          error={errors.last_name as boolean}
          helperText={errors.last_name}
          value={patient.last_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PatientReducerAction.LAST_NAME, value: event.target.value })}
          onKeyUp={() => validate('last_name')}
          onBlur={() => validate('last_name')} />
        <FormControl className={classes.dob} component="fieldset" error={errors.dob as boolean}>
          <DatePicker
            label="Date of birth"
            error={errors.dob as boolean}
            helperText={errors.dob as string}
            date={patient.dob}
            handleDateChange={(date: Date) => dispatch({ type: PatientReducerAction.DOB, value: date })}
            yearsFromNow={-110}
            onValidate={['dob', validate]}/>
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(StepPatient);
