import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import {
  Grid,
} from '@mui/material';


import GridCard from '../../../components/Grid/GridCard';

import styles from './styles';
import { homeInitQuery } from './queries';
import { GeneralLayout } from '../../../layouts';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const Home = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const [organisationsCount, setOrganisationsCount] = useState<number>(0);
  const [labsCount, setLabsCount] = useState<number>(0);
  const [specialitiesCount, setSpecialitiesCount] = useState<number>(0);

  const { data: homeInitData } = useQuery(homeInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && homeInitData) {
      setOrganisationsCount(homeInitData.organisations_aggregate.aggregate.count);
      setLabsCount(homeInitData.labs_aggregate.aggregate.count);
      setSpecialitiesCount(homeInitData.specialities_aggregate.aggregate.count);
    }
    return () => { mounted = false; };
  }, [homeInitData]);

  const handleAction = (link: string) => {
    navigate(link);
  }

  const actions = [
    {
      title: 'Organisations',
      description: 'Trusts, Clinics...',
      counter: organisationsCount,
      createLink: '/admin/organisations/create',
      viewLink: '/admin/organisations',
    },
    {
      title: 'Labs',
      description: '',
      counter: labsCount,
      createLink: '/admin/labs/create',
      viewLink: '/admin/labs',
    },
    {
      title: 'Specialities',
      description: '',
      counter: specialitiesCount,
      createLink: '/admin/specialities/create',
      viewLink: '/admin/specialities',
    }
  ];

  return (
    <GeneralLayout currentPage="Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            key={action.title}
            gridSize={4}
            title={action.title}
            description={action.description}
            counter={action.counter}
            editLabel="Create"
            handleEdit={() => handleAction(action.createLink)}
            handleView={() => handleAction(action.viewLink)} />
        ))}
      </Grid>
    </GeneralLayout>
  );
};

export default withStyles(styles)(Home);