import React, { useCallback, useReducer, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';


import PathwayView from '../../../View';
import Pathway, { pathwayValueReducer, ValidationErrors, validationErrors } from '../../../Common';

import styles from './styles';
import { UnknownObject } from '../../../../../react-app-env';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  pathway: Pathway,
}

const PathwayPreview = ({ classes, pathway }: Props): React.ReactElement => {
  const [pathwayValues, dispatchPathwayValues] = useReducer(pathwayValueReducer, {});

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    steps: false,
  });

  const validate = useCallback(() => {
    const errorItems: UnknownObject = {}
    errorItems.steps = !pathwayValues.name ? validationErrors.steps : false;

    setErrors({...errors, ...errorItems});
  }, [pathwayValues, errors, setErrors]);

  return (
    <>
      <PathwayView
        pathway={pathway}
        validate={validate}
        dispatch={dispatchPathwayValues} />
    </>
  );
};

export default withStyles(styles)(PathwayPreview);
