import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

export interface Category {
  name: string,
  description: string,
  icon: string,
}

const updateCategoryMutation = gql`
  mutation ($pk_columns: speciality_categories_pk_columns_input!, $set: speciality_categories_set_input!) {
    update_speciality_categories_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const editCategory = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    update_speciality_categories_by_pk: {
      id: string,
    }
  }>({ mutation: updateCategoryMutation!, variables });
  return res.data?.update_speciality_categories_by_pk.id;
}
