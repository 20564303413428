import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectOrganisationUserInitQuery = (organisation_id: string, user_id: string) => ({
  organisations_by_pk: params(
    { id: rawString(organisation_id) },
    {
      name: types.string,
    }
  ),
  users_sender_by_pk: params(
    { id: rawString(user_id) },
    {
      id: types.string,
      full_name: types.string,
      email: types.string,
      gmc_number: types.string,
      dob: types.string,
      gender: types.string,
      organisations: [{
        id: types.string,
        organisation: {
          id: types.string,
          name: types.string,
        },
      }],
      enum_sender_type: {
        sender_type: types.string,
        description: types.string,
      },
    },
  ),
});

type X = Unpacked<typeof _selectOrganisationUserInitQuery>
export type Organisation = X['organisations_by_pk'];
export type OrganisationUser = X['users_sender_by_pk'];
export type OrganisationAssignment = OrganisationUser['organisations'][0];

export const selectOrganisationUserInitQuery = (organisation_id: string, user_id: string) => gql`${query(_selectOrganisationUserInitQuery(organisation_id, user_id))}`;
