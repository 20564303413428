import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    width: '100%',
  },
  headerText: {
    flexGrow: 1,
  },
  headerActions: {
    // display: 'flex',
    justifyContent: 'flex-end',
  },
  textField: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  pots: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
    marginRight: theme.spacing(2),
  },
  potsList: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  notes: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  buttonRed: {
    // color: theme.palette.error.main,
    // backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  }
});

export default styles;
