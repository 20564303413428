
import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _createLabInitQuery = {
  organisations: [{
    id: types.string,
    name: types.string,
  }],
};

type X = Unpacked<typeof _createLabInitQuery>
export type Organisation = X['organisations'][0];

export const createLabInitQuery = gql`${query(_createLabInitQuery)}`;
