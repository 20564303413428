import moment from "moment";

export const getStartOfDay = () => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const getEndOfDay = () => {
  const date = new Date();
  date.setUTCHours(23, 59, 59, 59);
  return date;
};

export const getWeeksPast = (weeks: number) => {
  const date = new Date();
  date.setDate(getStartOfDay().getDate() - (weeks * 7));
  return date;
};


export const formatDate = (date: Date, format: string) => {
  return moment(date).format(format);
};
