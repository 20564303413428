import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertLabMutation = gql`
  mutation ($object: labs_insert_input!) {
    insert_labs_one(object: $object) {
      id
    }
  }
`;

const insertOrganisationLabAssignmentMutation = gql`
  mutation ($assignments: [organisation_lab_assignment_insert_input!]!) {
    insert_organisation_lab_assignment(objects: $assignments) {
      returning {
        id
      }
    }
  }
`;

export const createLab = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    insert_labs_one: {
      id: string,
    }
  }>({ mutation: insertLabMutation!, variables });
  return res.data?.insert_labs_one.id;
}

export const createOrganisationLabAssignments = async (assignments: object[]) => {
  return await gqlClient.mutate<{
    insert_organisation_lab_assignment: {
      returning: [{
        id: string,
      }],
    }
  }>({ mutation: insertOrganisationLabAssignmentMutation!, variables: { assignments } });
}
