import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderColor: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderWidth: 1,
      borderRadius: 10,
      borderStyle: 'solid',
      overflow: 'hidden',
      cursor: 'pointer',
    },
  });

export default styles;
