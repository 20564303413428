import { UnknownObject } from "../react-app-env";

export const capitaliseFirst = (value: string) => {
  const words = value?.toLowerCase().split(' ');
  return words.map((i) => i ? `${i[0].toUpperCase()}${i.slice(1)}` : '').join(' ');
};

export const capitaliseFirstOnly = (value: string) => {
  const words = value.split('. ');
  return words.map((i) => i ? `${i[0].toUpperCase()}${i.slice(1)}` : '').join('. ');
};

export const sentenceCase = (value: string) => {
  const words = value?.toLowerCase().split('. ');
  return words.map((i) => i ? `${i[0].toUpperCase()}${i.slice(1)}` : '').join('. ');
};

export const camelToCapital = (value: string) => {
  const words = value?.replace(/([A-Z])/g, ',$1').replace(/^,/, '').split(',');
  return words.map((i) => `${i[0].toUpperCase()}${i.slice(1)}`).join(' ');
};

export const camelToSpaces = (value: string) => {
  return value?.replace(/([A-Z])/g, ',$1').replace(/^,/, '').split(',').join(' ');
};

export const wordsToAcronym = (value: string) => {
  const words = value?.split(' ');
  return words.map((i) => i[0].toUpperCase()).join('');
};

export const stringFromArray = (words: string[], divider: string = ' ') => {
  return words.join(divider);
};

export const pluralise = (value: string, length: number) => {
  return `${value}${length === 1 ? '' : 's'}`;
};

export const dateToFormattedString = (value: string | number | Date) => {
  const d = new Date(value);
  return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`;
};

export const generateShortUuid = () => {
  return Math.random().toString(36).slice(-6);
};

export const generateNumberUuid = (length: number) => {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
};

export const numbersToWords = (limit: number) => {
  const n = [
    'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Nineth', 'Tenth',
    'Eleventh', 'Twelveth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth', 'Twentieth',
  ];
  return n.slice(0, limit);
};


export const numericToAlpha = (num: number): string => {
  let s: string = '';
  let t: number;
  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = (num - t)/26 | 0;
  }
  return s;
}

export const getKeyValue = (obj: UnknownObject, key: string): string | number => {
  const nesting: string[] = key.split('.');
  if (nesting.length > 1) {
    return getKeyValue(obj[nesting[0]], nesting.slice(1).join('.'));
  }
  return obj[key];
};
