
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { EnumPathwayStepComopnentType, PathwayStepOption } from '../../../../components/PathwayBuilder/Common';
import gqlClient from '../../../../utils/apolloClient';
import { Unpacked } from '../../../../react-app-env';

const _createCaseInitQuery = {
  organisations: [{
    id: types.string,
    name: types.string,
  }],
  organisation_lab_assignment: [{
    organisation_id: types.string,
    lab: {
      id: types.string,
      name: types.string,
    }
  }],
  specialities: params(
    { order_by: { order_index: 'asc' }},
    [{
  	id: types.string,
    name: types.string,
    icon: types.string,
    description: types.string,
    order_index: types.number,
    categories: params(
      { order_by: { order_index: 'asc' }},
      [{
        id: types.string,
        name: types.string,
        icon: types.string,
        order_index: types.number,
        description: types.string,
      }]
    ),
  }])
};

type X = typeof _createCaseInitQuery
export type Organisation = X['organisations'][0];
export type LabAssignment = X['organisation_lab_assignment'][0];
export type Lab = LabAssignment['lab'];
export type Speciality = X['specialities'][0];
export type Category = Speciality['categories'][0];

export const createCaseInitQuery = gql`${query(_createCaseInitQuery)}`;

const _getPathwaysQuery = (id: string) => ({
  surgical_pathways: params(
    { where: { speciality_category_id: { _eq: rawString(id) } } },
    [{
      id: types.string,
      name: types.string,
      version_timestamp: types.string,
      speciality_category_id: types.string,
      steps: params(
        { order_by: { order_index: 'asc' }},
        [{
          id: types.string,
          name: types.string,
          description: types.string,
          mandatory: types.boolean,
          component_type: types.string,
          order_index: types.number,
          options: types.string,
        }],
      )
    }],
  ),
  pot_pathways: params(
    { where: { speciality_category_id: { _eq: rawString(id) } } },
    [{
      id: types.string,
      name: types.string,
      version_timestamp: types.string,
      speciality_category_id: types.string,
      steps: params(
        { order_by: { order_index: 'asc' }},
        [{
          id: types.string,
          name: types.string,
          description: types.string,
          mandatory: types.boolean,
          component_type: types.string,
          order_index: types.number,
          specimen_qualifier: types.string,
          options: types.string,
        }],
      )
    }],
  )
});

export const getPathwaysQuery = async (id: string) => await gqlClient.query<{
  surgical_pathways: [{
    id: string,
    name: string,
    version_timestamp: string,
    speciality_category_id: string,
    steps: [{
      id: string,
      name: string,
      description: string,
      mandatory: boolean,
      component_type: EnumPathwayStepComopnentType,
      order_index: number,
      options: PathwayStepOption[],
    }],
  }],
  pot_pathways: [{
    id: string,
    name: string,
    version_timestamp: string,
    speciality_category_id: string,
    steps: [{
      id: string,
      name: string,
      description: string,
      mandatory: boolean,
      specimen_qualifier: string,
      component_type: EnumPathwayStepComopnentType,
      order_index: number,
      options: PathwayStepOption[],
    }],
  }]
}>({ query: gql`${query(_getPathwaysQuery(id))}`! });



type Y = Unpacked<typeof _getPathwaysQuery>
export type SurgicalPathways = Y['surgical_pathways'][0];
export type SurgicalPathwaysStep = SurgicalPathways['steps'][0];
export type PotPathways = Y['pot_pathways'][0];
export type PotPathwaysStep = PotPathways['steps'][0];


const _getExistingDataIds = (nhs_number: string, surgical_pathway_id: string, speciality_version_timestamp: string, pot_pathway_id: string, pot_version_timestamp: string) => ({
  patients: params(
    {
      where: {
        nhs_id: { _eq: rawString(nhs_number) },
      }
    },
    [{
      id: types.string,
    }],
  ),
  surgical_pathway_versions: params(
    {
      where: {
        surgical_pathway_id: { _eq: rawString(surgical_pathway_id) },
        version_timestamp : { _eq: rawString(speciality_version_timestamp) }
      }
    },
    [{
      id: types.string,
    }],
  ),
  pot_pathway_versions: params(
    {
      where: {
        pot_pathway_id: { _eq: rawString(pot_pathway_id) },
        version_timestamp : { _eq: rawString(pot_version_timestamp) }
      }
    },
    [{
      id: types.string,
    }],
  ),
});

export const getExistingDataIds = async (nhs_number: string, surgical_pathway_id: string, surgical_pathway_version_timestamp: string, pot_pathway_id: string, pot_pathway_version_timestamp: string) => {
  const res = await gqlClient.query<{
    patients: [{
      id: string,
    }],
    surgical_pathway_versions: [{
      id: string,
    }],
    pot_pathway_versions: [{
      id: string,
    }]
  }>({ query: gql`${query(_getExistingDataIds(nhs_number, surgical_pathway_id, surgical_pathway_version_timestamp, pot_pathway_id, pot_pathway_version_timestamp))}`! });
  const ids = {
    a_patient_id: '',
    a_surgical_pathway_version_id: '',
    a_pot_pathway_version_id: '',
  };
  if (res.data.patients.length === 1) {
    ids.a_patient_id = res.data.patients[0].id;
  }
  if (res.data.surgical_pathway_versions.length === 1) {
    ids.a_surgical_pathway_version_id = res.data.surgical_pathway_versions[0].id;
  }
  if (res.data.surgical_pathway_versions.length === 1) {
    ids.a_pot_pathway_version_id = res.data.pot_pathway_versions[0].id;
  }
  return ids;
};
