import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { stringFromArray } from '../../../../utils/stringUtils';
import { useNavigate } from 'react-router';

import GeneralLayout from '../../../../layouts/General';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { adminUserReducer, AdminUserReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { editAdminUserInitQuery } from './queries';
import { updateAdminUser } from './mutations';


const EditLabUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const { admin_user_id } = useParams();

  const [user, dispatchUser] = useReducer(adminUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editAdminUserInitQueryData } = useQuery(editAdminUserInitQuery(admin_user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editAdminUserInitQueryData) {
      const { users_admin_by_pk: usr } = editAdminUserInitQueryData;
      dispatchUser({ type: AdminUserReducerAction.INIT, value: usr });
    }
    return () => { mounted = false; };
  }, [editAdminUserInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: admin_user_id,
      },
      set: user,
    };
    await updateAdminUser(updateVariables);

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/users/${admin_user_id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Admin' },
          { label: 'Users', link: '/admin/users' },
          { label: stringFromArray([user?.first_name, user?.last_name]), link: `/admin/users/${admin_user_id}` },
          { label: 'Edit' },
        ]}>
        <Steps
          completeLabel="Save"
          user={user}
          dispatch={dispatchUser}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default EditLabUser;
