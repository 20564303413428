
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _createCategoriesInitQuery = (speciality_id: string) => ({
  specialities_by_pk: params(
    { id: rawString(speciality_id) },
    {
      id: types.string,
      name: types.string,
    },
  )
});

type X = Unpacked<typeof _createCategoriesInitQuery>
export type Speciality = X['specialities_by_pk'];

export const createCategoriesInitQuery = (id: string) => gql`${query(_createCategoriesInitQuery(id))}`;
