
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _categoriesInitQuery = (speciality_id: string) => ({
  speciality_categories: params(
    {
      where: {
        speciality_id: { _eq: rawString(speciality_id) }
      },
      order_by: { order_index: 'asc' },
    },
    [{
      id: types.string,
      name: types.string,
      description: types.string,
      icon: types.string,
      order_index: types.number,
      pot_pathways_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }],
  ),
  specialities_by_pk: params(
    { id: rawString(speciality_id) },
    {
      id: types.string,
      name: types.string,
      icon: types.string,
      description: types.string,
    },
  )
});

type X = Unpacked<typeof _categoriesInitQuery>
export type Speciality = X['specialities_by_pk'];
export type Category = X['speciality_categories'][0];

export const categoriesInitQuery = (id: string) => gql`${query(_categoriesInitQuery(id))}`;
