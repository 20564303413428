import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';

import { TextField, Typography } from '@mui/material';

import { CaseSearch, validation, ValidationErrors, CaseSearchReducerAction } from '../../case_search';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  caseSearch: CaseSearch;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: CaseSearchReducerAction; value: any }>;
}

const StepSearchCase = ({ classes, caseSearch, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(() => {
    const errorItems: UnknownObject = {};
    errorItems.incomplete = validation.incomplete(caseSearch);
    setErrors({ ...errors, ...errorItems });
  }, [caseSearch, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.header} variant="h4">
          Check-in Case
        </Typography>
        <TextField
          className={classes.textField}
          data-qa="case_id-textfield"
          label="Case ID"
          variant="outlined"
          error={errors.incomplete as boolean}
          helperText={errors.incomplete}
          value={caseSearch.case_id}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CaseSearchReducerAction.CASE_ID, value: event.target.value })}
          onKeyUp={() => validate()}
          onBlur={() => validate()}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepSearchCase);
