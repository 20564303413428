
import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import gqlClient, { getWhereKeyValue } from '../../../../utils/apolloClient';
import { UnknownObject, Unpacked } from '../../../../react-app-env';

const _casesSearchQuery = (where: UnknownObject) => ({
  cases: params(
    {
      order_by: { created_at: 'asc' },
      where
    },
    [{
      id: types.string,
      accession_id: types.string,
      created_at: types.string,
      mrn: types.string,
      enum_case_priority: {
        description: types.string,
      },
      patient: {
        id: types.string,
        nhs_id: types.string,
      },
      organisation: {
        id: types.string,
        name: types.string,
      },
      lab: {
        id: types.string,
        name: types.string,
      },
      pots_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }],
  )
});

type X = Unpacked<typeof _casesSearchQuery>
export type Case = X['cases'][0];


export const casesSearchQuery = async (queries: UnknownObject[]) => {
  const where = {
    _or: queries.map((q) => getWhereKeyValue(q.field, q.value)),
  };

  const res = await gqlClient.query<{
    cases: [{
      id: string,
      accession_id: string,
      created_at: string,
      mrn: string,
      enum_case_priority: {
        description: string,
      },
      patient: {
        id: string,
        nhs_id: string,
      },
      organisation: {
        id: string,
        name: string,
      },
      lab: {
        id: string,
        name: string,
      },
      pots_aggregate: {
        aggregate: {
          count: number,
        },
      },
    }],
  }>({ query: gql`${query(_casesSearchQuery(where))}`! });
  return res.data.cases;
};
