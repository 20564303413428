
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editSpecialityInitQuery = (id: string) => ({
  specialities_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      description: types.string,
      icon: types.string,
    },
  ),
});

type X = Unpacked<typeof _editSpecialityInitQuery>
export type Speciality = X['specialities_by_pk'];

export const editSpecialityInitQuery = (id: string) => gql`${query(_editSpecialityInitQuery(id))}`;
