import React, { useState, useContext, useEffect, useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';

import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';

import { CenteredLayout } from '../../../layouts';
import { AuthContext } from '../../../components/AuthProvider';
import ButtonContainer from '../../../components/ButtonContainer';
import DatePicker from '../../../components/DatePicker';
import GendersRadioGroup from '../../../components/GendersRadioGroup';
import { Logo } from '../../../components/Logos';
import { dateToFormattedString } from '../../../utils/stringUtils';
import { validatePassword } from '../../../utils/validators';

import { updateAdminUser, updateReceiverUser, updateSenderUser } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

interface CustomInitialSignInError {
  first_name?: string,
  last_name?: string,
  dob?: string | number | Date,
  gender?: string,
  newPassword?: string,
  confirmPassword?: string,
  changePassword?: string,
}

const InitialSignIn = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }
  const { loggedInUser, userRole, user, signOut, signIn, completeNewPassword } = authContext;

  const givenName = loggedInUser && loggedInUser.challengeParam ? loggedInUser.challengeParam.userAttributes.given_name || '' : '';
  const familyName = loggedInUser && loggedInUser.challengeParam ? loggedInUser.challengeParam.userAttributes.family_name || '' : '';

  const [first_name, setFirstName] = useState<string>(givenName);
  const [last_name, setLastName] = useState<string>(familyName);
  const [dob, setDob] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState<string>('');
  const [errors, setErrors] = useState<CustomInitialSignInError>({});
  const [changingPassword, setChangingPassword] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [steps] = useState(['Name', 'Password', 'Details']);

  const handleNext = useCallback(() => {
    if (activeStep === 1 && !userRole) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, userRole]);

  const handleGoToSignIn = useCallback(() => {
    navigate(`/sign-in?e=${loggedInUser?.username}`);
  }, [loggedInUser, navigate]);

  const handleSetDob = (date: Date) => {
    setDob(dateToFormattedString(date));
    // validate();
  }

  const validate = useCallback(() => {
    let error = false;
    setErrors({});
    if(activeStep === 0) {
      if (!first_name) {
        setErrors((e) => ({ ...e, first_name: 'Please provide a first name' }));
        error = true;
      }
      if (!last_name) {
        setErrors((e) => ({ ...e, last_name: 'Please provide a last name' }));
        error = true;
      }
    }
    if(activeStep === 1) {
      if (!newPassword) {
        setErrors((e) => ({ ...e, newPassword: 'Please provide a password' }));
        error = true;
      } else if (!validatePassword(newPassword)) {
        setErrors((e) => ({ ...e, newPassword: 'Password does not conform to the passowrd criteria' }));
        error = true;
      }
      if (!confirmPassword) {
        setErrors((e) => ({ ...e, confirmPassword: 'Please provide a password' }));
        error = true;
      } else if (confirmPassword !== newPassword) {
        setErrors((e) => ({ ...e, newPassword: 'Passwords do not match', confirmPassword: 'Passwords do not match' }));
        error = true;
      } else if (!validatePassword(confirmPassword)) {
        setErrors((e) => ({ ...e, confirmPassword: 'Password does not conform to the passowrd criteria' }));
        error = true;
      }
    }
    if(activeStep === 2) {
      if (!dob) {
        setErrors((e) => ({ ...e, dob: 'Please provide a date of birth' }));
        error = true;
      }
      if (!gender) {
        setErrors((e) => ({ ...e, gender: 'Please provide a gender' }));
        error = true;
      }
    }
    return !error;
  }, [activeStep, confirmPassword, dob, first_name, gender, last_name, newPassword]);

  const handleContinue = useCallback(async () => {
    if (validate()) {
      if (activeStep === 1) {
        setChangingPassword(true);
        const attributes = {
          given_name: first_name,
          family_name: last_name,
        };
        try {
            const email = loggedInUser?.username || '';
            await completeNewPassword(newPassword, attributes);
            await signOut();
            await signIn(email, newPassword);
            setChangingPassword(false);
        } catch (err: any) {
          setErrors((e) => ({ ...e, changePassword: err.message }));
        }
      }
      if (activeStep === 2) {
        const updateUserVariables = {
          pk_columns: {
            id: user?.id,
          },
          set: {
            first_name,
            last_name,
            dob,
            gender,
          },
        };
        if (userRole === 'admin') {
          await updateAdminUser(updateUserVariables);
        }
        if (userRole?.startsWith('sender')) {
          await updateSenderUser(updateUserVariables);
        }
        if (userRole?.startsWith('receiver')) {
          await updateReceiverUser(updateUserVariables);
        }
        await signOut();
        handleGoToSignIn();
      }
      if (activeStep < steps.length - 1) {
        return handleNext();
      }
    }
  }, [activeStep, loggedInUser, userRole, user, first_name, last_name, newPassword, dob, gender, steps, signIn, signOut, validate, completeNewPassword, handleNext, handleGoToSignIn]);

  useEffect(() => {
    let mounted = true;
    if (mounted && userRole && !userType) {
      setUserType(userRole);
      handleContinue();
    }
    return () => { mounted = false; };
  }, [userRole, userType, handleContinue]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <div className={classes.form}>
            <TextField
              className={classes.textField}
              data-qa="first_name-textfield"
              required
              label="First name"
              variant="outlined"
              name="first_name"
              error={errors.first_name !== undefined}
              helperText={errors.first_name}
              value={first_name}
              autoComplete="off"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value || '')} />
            <TextField
              className={classes.textField}
              data-qa="last_name-textField"
              required
              label="Last name"
              variant="outlined"
              name="last_name"
              error={errors.last_name !== undefined}
              helperText={errors.last_name}
              value={last_name}
              autoComplete="off"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value || '')} />
          </div>
        );
        case 1:
          return (
            <div className={classes.form}>
              <Typography className={classes.passwordDetail}>Passwords must be at least 8 characters long and contain at least 1 lower case letter, 1 upper case letter, and a number</Typography>
              <TextField
                className={classes.textField}
                required
                label="New password"
                name="password"
                type="password"
                variant="outlined"
                error={errors.newPassword !== undefined}
                helperText={errors.newPassword}
                autoComplete="off"
                value={newPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value || '')} />
              <TextField
                className={classes.textField}
                required
                label="Confirm new password"
                name="passwordConfirm"
                variant="outlined"
                type="password"
                error={errors.confirmPassword !== undefined}
                autoComplete="off"
                helperText={errors.confirmPassword}
                value={confirmPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value || '')} />
            </div>
          );
        case 2:
          return (
            <div className={classes.form}>
              <FormControl className={classes.dob} component="fieldset" error={errors.dob !== undefined}>
                <DatePicker
                  label="Date of birth"
                  handleDateChange={handleSetDob}
                  yearsFromNow={-110} />
                <FormHelperText>{errors.dob as string}</FormHelperText>
              </FormControl>
              <FormControl component="fieldset" error={errors.gender !== undefined}>
                <FormLabel component="label">Gender</FormLabel>
                <GendersRadioGroup gender={gender} onChange={(event) => setGender(event.target.value || '')} />
                <FormHelperText>{errors.gender}</FormHelperText>
              </FormControl>
            </div>
          );
      default:
        return 'Unknown step';
    }
  };

  const getContent = () => (
    <>
      <Stepper className={classes.stepper} activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
      {errors.changePassword && (
        <Typography>{errors.changePassword}</Typography>
      )}
      <ButtonContainer center>
        {!changingPassword && <Button data-qa="continue-finish-button" variant="contained" color="primary" onClick={handleContinue}>{activeStep < steps.length - 1 ? 'CONTINUE' : 'FINISH'}</Button>}
        {changingPassword && <CircularProgress />}
      </ButtonContainer>
    </>
  );

  return (
    <CenteredLayout>
      <div className={classes.modal}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <Typography className={classes.title} variant="h4">Update user profile</Typography>
        <div className={classes.content}>
          {getContent()}
        </div>
      </div>
    </CenteredLayout>
  );
};

export default withStyles(styles)(InitialSignIn);
