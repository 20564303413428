import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {},
  header: {
    marginBottom: theme.spacing(4),
  },
  textFieldGroup: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  searchButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  searchErrorText: {
    marginTop: theme.spacing(1),
  },
});

export default styles;
