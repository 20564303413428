import React, { useState, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import GeneralLayout from '../../../../../../layouts/General';
import { useNavigate } from 'react-router'; 

import CustomBackdrop from '../../../../../../components/CustomBackdrop';
import Steps from '../Common/components/Steps';
import { pathwayReducer, PathwayReducerAction, PathwayStep } from "../../../../../../components/PathwayBuilder/Common";

import { createPotPathway, createPotPathwaySteps } from './mutations';
import { createPotPathwayInitQuery, Speciality, Category } from './queries';



const CreatePotPathway = (): React.ReactElement => {
  const navigate = useNavigate();
  const { speciality_id, category_id } = useParams();

  const [pathway, dispatchPathway] = useReducer(pathwayReducer, {
    id: '',
    name: '',
    steps: [],
  });

  const [speciality, setSpeciality] = useState<Speciality | null>(null);
  const [category, setCategory] = useState<Category | null>(null);
  const [saving, setSaving] = useState<boolean>(false);

  const { data: createCategoriesInitData } = useQuery(createPotPathwayInitQuery(speciality_id!, category_id!));

  useEffect(() => {
    let mounted = true;
    if (mounted && createCategoriesInitData) {
      dispatchPathway({ type: PathwayReducerAction.ADD_STEP, value: {} });
      setSpeciality(createCategoriesInitData.specialities_by_pk);
      setCategory(createCategoriesInitData.speciality_categories_by_pk);
    }
    return () => { mounted = false; };
  }, [createCategoriesInitData]);

  const handleSave = async () => {
    setSaving(true);

    const insertVariables = {
      object: {
        name: `${speciality?.name}|${category?.name}`,
        speciality_category_id: category_id,
      },
    };

    const pot_pathway_id: string | undefined = await createPotPathway(insertVariables);
    if (pot_pathway_id) {
      const insertStepsVariables = {
        objects: pathway.steps.map((step: PathwayStep) => ({
          pot_pathway_id,
          ...step,
        })),
      };
      createPotPathwaySteps(insertStepsVariables);

      setSaving(false);
      handleFinish();
    }

  };

  const handleFinish = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Specialities', link: '/admin/specialities' },
          { label: `${speciality?.name}`, link: `/admin/specialities/${speciality_id}` },
          { label: `${category?.name}`, link: `/admin/specialities/${speciality_id}/${category_id}` },
          { label: 'Pot Pathway' },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          pathway={pathway}
          dispatch={dispatchPathway}
          handleSave={handleSave}/>
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Pot Pathway" />}
    </>
  );
};

export default CreatePotPathway;
