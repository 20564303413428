import React, { useState, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import GeneralLayout from '../../../../layouts/General';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import Steps from '../Common/components/Steps';
import { specialityReducer, SpecialityReducerAction } from '../Common';

import { editSpeciality } from './mutations';
import { editSpecialityInitQuery } from './queries';

const EditSpeciality = (): React.ReactElement => {
  const navigate = useNavigate();
  const { speciality_id } = useParams();

  const [speciality, dispatchSpeciality] = useReducer(specialityReducer, {
    name: '',
    description: '',
    icon: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editOrganisationInitData } = useQuery(editSpecialityInitQuery(speciality_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editOrganisationInitData) {
      dispatchSpeciality({ type: SpecialityReducerAction.INIT, value: editOrganisationInitData.specialities_by_pk });
    }
    return () => { mounted = false; };
  }, [editOrganisationInitData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: speciality_id,
      },
      set: speciality,
    };
    await editSpeciality(updateVariables);
    setSaving(false);
    handleFinish(speciality_id!);
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/specialities/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Specialities', link: '/admin/specialities' },
          { label: speciality.name, link: `/admin/specialities/${speciality_id}` },
          { label: 'Edit' },
        ]}>
        <Steps
          completeLabel="Save"
          speciality={speciality}
          dispatch={dispatchSpeciality}
          handleSave={handleSave}/>
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Speciality" />}
    </>
  );
};

export default EditSpeciality;
