import { capitaliseFirst, capitaliseFirstOnly } from "../../../../utils/stringUtils";

export interface Organisation {
  name: string,
  address_line_1: string,
  address_line_2: string,
  address_city: string,
  address_postcode: string,
  contact: string,
  organisation_type: string,
}

export interface OrganisationType {
  organisation_type: string,
  description: string,
}

export interface Lab {
  id: string,
  name: string,
}

export interface ValidationErrors {
  name: string | boolean,
  address_line_1: string | boolean,
  address_city: string | boolean,
  address_postcode: string | boolean,
  organisation_type: string | boolean,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  address_line_1: 'Please provide a address line 1',
  address_city: 'Please provide a city',
  address_postcode: 'Please provide a postcode',
  organisation_type: 'Please provide a organisation type',
}

export enum OrganisationReducerAction {
  NAME,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_CITY,
  ADDRESS_POSTCODE,
  CONTACT,
  ORGANISATION_TYPE,
  INIT,
}

export const organisationReducer = (state: Organisation, action: { type: OrganisationReducerAction, value: any }): Organisation =>  {
  switch (action.type) {
    case OrganisationReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case OrganisationReducerAction.ADDRESS_LINE_1:
      return { ...state, address_line_1: capitaliseFirst(action.value) };
    case OrganisationReducerAction.ADDRESS_LINE_2:
      return { ...state, address_line_2: capitaliseFirst(action.value) };
    case OrganisationReducerAction.ADDRESS_CITY:
      return { ...state, address_city: capitaliseFirst(action.value) };
    case OrganisationReducerAction.ADDRESS_POSTCODE:
      return { ...state, address_postcode: action.value?.toUpperCase() };
    case OrganisationReducerAction.CONTACT:
      return { ...state, contact: action.value };
    case OrganisationReducerAction.ORGANISATION_TYPE:
      return { ...state, organisation_type: action.value?.replace(/ /g, '') };
    case OrganisationReducerAction.INIT:
      const { name, address_line_1, address_line_2, address_city, address_postcode, contact, organisation_type } = action.value;
      const obj = {
        name,
        address_line_1,
        address_line_2,
        address_city,
        address_postcode,
        contact,
        organisation_type,
      };
      return { ...obj as Organisation };
    default:
      throw new Error();
  }
}
