import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  TextField,
  Typography,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import { Organisation } from '../../organisation';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  organisation: Organisation | undefined,
  organisations: Organisation[],
  setOrganisation: React.Dispatch<React.SetStateAction<Organisation | undefined>>,
  validate: () => void,
}

const StepOrganisationAssignment = ({ classes, organisation, organisations, setOrganisation, validate }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.header} variant="h4">Organisation Assignment</Typography>
        {organisations.length === 1 && organisation && (
          <>
            <Typography>You are only assigned to one organisation:</Typography>
            <Typography variant="h6">{organisation.name}</Typography>
          </>
        )}
        {organisations.length > 1 && (
          <>
            <Typography className={classes.subTitle}>You are assigned to more than one organisation. Please select the one you are sending this case from</Typography>

            <Autocomplete
              data-qa="organisation-assignment-list"
              options={organisations}
              getOptionLabel={(option: Organisation) => option.name}
              value={organisations.find(i => i.id === organisation?.id)}
              autoComplete
              onBlur={() => validate()}
              onChange={(_: unknown, newValue: Organisation | null) => {
                setOrganisation(newValue || undefined);
              }}
              renderInput={(params) =>
                <TextField
                  data-qa="organisation-assignment-input"
                  {...params}
                  label="Organisation"
                  name="organisation-assignment"
                  helperText={organisation ? '' : 'Select an organisation' } />}
            />
          </>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(StepOrganisationAssignment);
