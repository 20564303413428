import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { ReceiverUser, SenderUser, UnknownObject, User } from '../../../../../../react-app-env';


import CustomStepper from '../../../../../../components/CustomStepper';
import Pathway from '../../../../../../components/PathwayBuilder/Common';
import {
  Patient,
 validationErrors as validationPatientErrors,
  ValidationErrors as ValidationPatientErrors,
  PatientReducerAction,
} from '../../patient';

import {
  Speciality,
  SpecialityItem,
 validationErrors as validationSpecialityErrors,
  ValidationErrors as ValidationSpecialityErrors,
  SpecialityReducerAction,
} from '../../speciality';

import {
  SpecialityPathway,
 validationErrors as validationSpecialityPathwayErrors,
  ValidationErrors as ValidationSpecialityPathwayErrors,
} from '../../speciality_pathway';

import {
  Pot,
  PotsReducerAction,
 validationErrors as validationPotsErrors,
  ValidationErrors as ValidationPotsErrors,
} from '../../pots';

import {
  Organisation,
} from '../../organisation';

import {
  Lab,
} from '../../lab';

import StepPatient from '../StepPatient';
import StepSpeciality from '../StepSpeciality';
import StepSpecialityPathway from '../StepSpecialityPathway';
import StepPots from '../StepPots';
import StepOrganisationAssignment from '../StepOrganisationAssignment';
import StepLabAssignment from '../StepLabAssignment';
import StepReview from '../StepReview';

import styles from './styles';
import { pluralise } from '../../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  accessionNumber?: string,
  patient: Patient,
  speciality: Speciality,
  specialities: SpecialityItem[],
  specialityPathwayValues: SpecialityPathway,
  specialityPathway: Pathway,
  potPathway: Pathway,
  pots: Pot[],
  user: User | ReceiverUser | SenderUser | null,
  organisation?: Organisation,
  organisations: Organisation[],
  lab?: Lab,
  labs: Lab[],
  dispatchPatient: React.Dispatch<{ type: PatientReducerAction, value: any }>,
  dispatchSpeciality: React.Dispatch<{ type: SpecialityReducerAction, value: any }>,
  dispatchSpecialityPathwayValues: React.Dispatch<{ type: string, id: string, value: string }>,
  dispatchPots: React.Dispatch<{ type: PotsReducerAction, value: any }>,
  setOrganisation: React.Dispatch<React.SetStateAction<Organisation | undefined>>,
  setSpecialityPathway: React.Dispatch<React.SetStateAction<Pathway>>,
  setPotPathway: React.Dispatch<React.SetStateAction<Pathway>>,
  setLab: React.Dispatch<React.SetStateAction<Lab | undefined>>,
  handleCategoryChange: (category_id: string) => void,
  handleSave: () => void,
}

const CaseSteps = ({ classes, completeLabel, accessionNumber, patient, speciality, specialities, specialityPathwayValues, specialityPathway, potPathway, pots, user, organisation, organisations, lab, labs, dispatchPatient, dispatchSpeciality, dispatchSpecialityPathwayValues: dispatchSpecialityPathway, dispatchPots, setOrganisation, setSpecialityPathway, setPotPathway, setLab, handleCategoryChange, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [patientErrors, setPatientErrors] = useState<ValidationPatientErrors>({
    nhs_number: false,
    nhs_number_length: false,
    mrn: false,
    mrn_length: false,
    first_name: false,
    last_name: false,
    dob: false,
  });

  const [specialityErrors, setSpecialityErrors] = useState<ValidationSpecialityErrors>({
    id: false,
  });


  const [specialityPathwayErrors, setSpecialityPathwayErrors] = useState<ValidationSpecialityPathwayErrors>({
    incomplete: false,
  });


  const [potsErrors, setPotsErrors] = useState<ValidationPotsErrors>({
    no_name: false,
    incomplete: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.nhs_number = !patient.nhs_number ? validationPatientErrors.nhs_number : false;
      errorItems.mrn = !patient.mrn ? validationPatientErrors.mrn : false;
      errorItems.first_name = !patient.first_name ? validationPatientErrors.first_name : false;
      errorItems.last_name = !patient.last_name ? validationPatientErrors.last_name : false;
      errorItems.dob = !patient.dob ? validationPatientErrors.dob : false;
      setPatientErrors({...patientErrors, ...errorItems});
      return (Object.keys(errorItems) as Array<keyof ValidationPatientErrors>).some((i) => errorItems[i]);
    }
    if (_step === 1) {
      errorItems.id = !speciality.id ? validationSpecialityErrors.id : false;
      setSpecialityErrors({...specialityErrors, ...errorItems});
      return (Object.keys(errorItems) as Array<keyof ValidationSpecialityErrors>).some((i) => errorItems[i]);
    }
    if (_step === 2) {
      const values = Object.keys(specialityPathwayValues.pathway);
      errorItems.incomplete = !specialityPathway.steps.filter((i) => i.mandatory).every((i) => values.includes(i.id)) ?  validationSpecialityPathwayErrors.incomplete : false;
      setSpecialityPathwayErrors({...specialityPathwayErrors, ...errorItems});
      return (Object.keys(errorItems) as Array<keyof ValidationSpecialityPathwayErrors>).some((i) => errorItems[i]);
    }
    if (_step === 3) {
      const pot = pots.find((p) => p.selected);
      if (pot) {
        errorItems.no_name = !pot.name ? validationPotsErrors.no_name : false;
      }
      setPotsErrors({...potsErrors, ...errorItems});
    }
    return false;
  }, [patient, speciality, pots, specialityPathwayValues, specialityPathway, patientErrors, specialityErrors, specialityPathwayErrors, potsErrors]);


  useEffect(() => {
    if (speciality.category) {
      handleCategoryChange(speciality.category.id);
    }
  }, [speciality.category, handleCategoryChange]);

  const isNextDisabled = () => {
    if (step === 0) {
      return (Object.keys(patientErrors) as Array<keyof ValidationPatientErrors>).some((i) => patientErrors[i]);
    }
    if (step === 1) {
      return !speciality.category;
    }
    if (step === 2) {
      return (Object.keys(specialityPathwayErrors) as Array<keyof ValidationSpecialityPathwayErrors>).some((i) => specialityPathwayErrors[i]);
    }
    if (step === 3) {
      const pathwaySteps = potPathway.steps.filter((i) => i.mandatory).length;
      return pots.some(((pot) => Object.keys(pot.pathway).length < pathwaySteps));
    }
    if (step === 4) {
      const lbs = labs.filter((i) => !organisation || i.organisation_id === organisation.id);
      if (lbs.length === 1) {
        setLab(lbs[0]);
      }
      return !organisation;
    }
    if (step === 5) {
      return !lab;
    }
    return false;
  }

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={isNextDisabled()}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Patient',
            detail: (patient.first_name && patient.last_name) ? `${patient.first_name} ${patient.last_name}` : null,
            component:
              <StepPatient
                patient={patient}
                errors={patientErrors}
                setErrors={setPatientErrors}
                dispatch={dispatchPatient} />
          },
          {
            label: 'Speciality',
            detail: `${speciality.name}${speciality.category ? ` / ${speciality.category.name}` : ''}`,
            gridSize: 8,
            component:
              <StepSpeciality
                speciality={speciality}
                specialities={specialities}
                errors={specialityErrors}
                setErrors={setSpecialityErrors}
                nextStep={() => setStep(2)}
                dispatch={dispatchSpeciality} />
          },
          {
            label: 'Clinical',
            detail: accessionNumber,
            gridSize: 4,
            component:
              <StepSpecialityPathway
                pathway={specialityPathway}
                values={specialityPathwayValues.pathway}
                notes={specialityPathwayValues.notes}
                errors={specialityPathwayErrors}
                validate={() => validate(step)}
                dispatch={dispatchSpecialityPathway} />
          },
          {
            label: 'Surgical',
            detail: potPathway.id ? `${pots.length} ${pluralise('Pot', pots.length)}` : '',
            gridSize: 8,
            component:
              <StepPots
                pathway={potPathway}
                pots={pots}
                speciality={speciality}
                errors={potsErrors}
                validate={() => validate(step)}
                dispatch={dispatchPots} />
          },
          {
            label: 'Organisation',
            detail: organisation ? organisation.name : null,
            component:
              <StepOrganisationAssignment
                organisation={organisation}
                organisations={organisations}
                setOrganisation={setOrganisation}
                validate={() => validate(step)} />
          },
          {
            label: 'Lab Assignment',
            detail: lab ? lab.name : null,
            component:
              <StepLabAssignment
                lab={lab}
                labs={labs.filter((i) => !organisation || i.organisation_id === organisation.id)}
                setLab={setLab}
                validate={() => validate(step)} />
          },
          {
            label: 'Summary',
            gridSize: 8,
            component:
              <StepReview
                patient={patient}
                pots={pots}
                potPathway={potPathway}
                speciality={speciality}
                accessionNumber={accessionNumber}
                specialityPathway={specialityPathway}
                specialityPathwayValues={specialityPathwayValues}
                organisation={organisation}
                lab={lab}
                user={user}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(CaseSteps);
