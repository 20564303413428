import React, { useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { EmptyObject } from '../../react-app-env';
import { formatDate } from '../../utils/dateUtils';

import {
  Avatar,
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography
} from '@mui/material';

import {
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material';


import styles from './styles';

interface TableProps extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>,
  title?: string,
  counter?: number,
  orderdBy?: string,
  headers: SortableTableHeader[],
  rows: SortableTableRow[],
}

interface HeadProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>,
  headers: SortableTableHeader[],
  hasExpandedRows: boolean,
  hasActionRows: boolean,
  order: SortableTableOrder,
  orderBy: string,
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void,
}

interface SortableTableToolbarProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>,
  title?: string,
  counter?: number,
}

export interface SortableTableHeader {
  key: string,
  label: string | number,
  format?: string,
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
}

interface SortableTableRow {
  key: string,
  columns: SortableTableColumn[],
  collapse?: SortableTableCollapse,
  onClick?: () => void,
  actions?: SortableTableRowAction[],
}

interface SortableTableRowAction {
  label: string,
  onClick?: () => void,
}

interface SortableTableColumn {
  key: string,
  format?: string,
  label: string | number,
  component?: React.ReactElement,
}

interface SortableTableCollapse {
  title?: string,
  headers: SortableTableHeader[],
  rows: SortableTableRow[],
}

type SortableTableOrder = 'asc' | 'desc';

function descendingComparator(a: string | number, b: string | number) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(
  order: SortableTableOrder,
): (a: string | number, b: string | number) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b)
    : (a, b) => -descendingComparator(a, b);
}

function stableSort(array: SortableTableRow[], orderBy: string, comparator: (a: string | number, b: string | number) => number) {
  const stabilizedThis = array.map((row, index) => [row, row.columns.find(i => i.key === orderBy)?.label || '', index] as [SortableTableRow, string | number, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[1], b[1]);
    if (order !== 0) return order;
    return a[2] - b[2];
  });
  return stabilizedThis.map((el) => el[0]);
}

const SortableTableToolbar = ({ classes, title, counter }: SortableTableToolbarProps): React.ReactElement => {
  return (
    <Toolbar className={classes.toolbar}>
      {counter !== undefined && (
        <Avatar className={classes.counter} variant="rounded">
          {counter}
        </Avatar>
      )}
      {title && (
        <Typography className={classes.title} variant="h6" id="sortableTableTitle" component="div">
        {title}
        </Typography>
      )}
    </Toolbar>
  );
};

const SortableTableHead = ({ classes, headers, hasExpandedRows = false, hasActionRows = false, order, orderBy, onRequestSort }: HeadProps): React.ReactElement => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {hasExpandedRows && (
          <TableCell className={classes.collapseCell} />
        )}
        {headers.map((header) => (
          <TableCell
            key={header.key}
            align={header.align || 'left'}
            sortDirection={orderBy === header.key ? order : false} >
            <TableSortLabel
              active={orderBy === header.key}
              direction={orderBy === header.key ? order : 'asc'}
              onClick={createSortHandler(header.key)} >
              {header.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {hasActionRows && (
          <TableCell />
        )}
      </TableRow>
    </TableHead>
  );
}

const SortableTable = ({ classes, title, counter = 0, orderdBy, headers = [], rows = [] }: TableProps): React.ReactElement => {
  const [order, setOrder] = useState<SortableTableOrder>('desc');
  const [orderBy, setOrderBy] = useState<string>(orderdBy || headers[0]?.key || '');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpandRow = (key: string) => {
    setExpandedRows((i) => {
      if (i.includes(key)) {
        return i.filter((a) => a !== key);
      }
      return [...i, key];
    });
  };

  const hasExpandedRows = () => rows.some((i) => i.collapse);

  const hasActionRows = () => rows.some((i) => i.actions);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper} variant="outlined">
          {(title || counter > 0) && (
            <SortableTableToolbar classes={classes} title={title} counter={counter} />
          )}
          <TableContainer>
            <Table className={classes.table} size="medium">
              <SortableTableHead
                classes={classes}
                headers={headers}
                hasExpandedRows={hasExpandedRows()}
                hasActionRows={hasActionRows()}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort} />
              <TableBody>
                {stableSort(rows, orderBy, getComparator(order))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: SortableTableRow, index) => {
                    const labelId = `sortable-table-${index}`;
                    const isExpanded = expandedRows.includes(row.key);
                    return (
                      <>
                        <TableRow
                          key={row.key}
                          hover={row.onClick !== undefined}
                          onClick={() => row.onClick !== undefined ? row.onClick() : null}
                          tabIndex={-1}>
                            {row.collapse && (
                              <TableCell
                                className={isExpanded ? classes.lastRow : ''}>
                                <IconButton size="small" onClick={() => handleExpandRow(row.key)}>
                                  {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                              </TableCell>
                            )}
                            {!row.collapse && hasExpandedRows() && (
                              <TableCell />
                            )}

                            <TableCell
                              className={isExpanded ? classes.lastRow : ''}
                              key={`${row.key}_${row.columns[0].key}`}
                              id={labelId}
                              component="th"
                              scope="row">
                              {row.columns[0].label}
                            </TableCell>

                            {row.columns.slice(1).map((column) => (
                              <TableCell
                                className={isExpanded ? classes.lastRow : ''}
                                key={`${row.key}_${column.key}`}
                                align={headers.find((h) => h.key === column.key)?.align || 'left'}>
                                  {column.component
                                  ? column.component
                                  : column.format ? formatDate(new Date(column.label), column.format) : column.label}
                              </TableCell>
                            ))}
                            {hasActionRows() && (
                              <TableCell
                                className={isExpanded ? classes.lastRow : ''}
                                padding="none"
                                key={`${row.key}_actions`}
                                align="right">
                                  <div className={classes.actionsContainer}>
                                    {row.actions?.map((action) => (
                                      <Button size="small" variant="outlined" onClick={action.onClick}>{action.label}</Button>
                                    ))}
                                  </div>
                              </TableCell>
                            )}
                        </TableRow>
                        {row.collapse && (
                          <TableRow>
                            <TableCell
                              className={!isExpanded ? classes.lastRow : ''}
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={headers.length + 1 + (row.actions?.length || 0)}>
                              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                  {row.collapse.title && (
                                    <Typography variant="h6" component="div">
                                      {row.collapse.title}
                                    </Typography>
                                  )}
                                  <Table size="small">
                                    <TableHead>
                                      {row.collapse.headers.map((collapseHeader) => (
                                        <TableCell
                                          size="medium"
                                          key={collapseHeader.key}
                                          align={collapseHeader.align || 'left'}>
                                            {collapseHeader.label}
                                        </TableCell>
                                      ))}
                                    </TableHead>
                                    <TableBody>
                                      {row.collapse.rows.map((collapseRow, colRowIndex) => (
                                        <TableRow key={collapseRow.key}>
                                          {collapseRow.columns.map((collapseColumn) => (
                                            <TableCell
                                              className={colRowIndex + 1 === row.collapse?.rows.length ? classes.lastRow : ''}
                                              key={collapseColumn.key}
                                              align={row.collapse?.headers.find((h) => h.key === collapseColumn.key)?.align || 'left'}>
                                                {collapseColumn.component
                                                ? collapseColumn.component
                                                : collapseColumn.format ? formatDate(new Date(collapseColumn.label), collapseColumn.format) : collapseColumn.label}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 10 && (
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage} />
          )}
        </Paper>
      </div>
    </>
  );
};

export default withStyles(styles)(SortableTable);
