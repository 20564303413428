import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../react-app-env';
import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';

import {
  Grid
} from '@mui/material';

import GeneralLayout from '../../../../../layouts/General';
import UsersList from '../../../../../components/Users/List';

import { selectLabUsersInitQuery, LabUser, EnumReceiverType, Lab } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}


const ListLabUsers = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { lab_id } = useParams();

  const [lab, setLab] = useState<Lab | UnknownObject>({});
  const [users, setUsers] = useState<LabUser[]>([]);
  const [userTypes, setUserTypes] = useState<EnumReceiverType[]>([]);

  const { data: labUserInitQuery } = useQuery(selectLabUsersInitQuery(lab_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && labUserInitQuery) {
      setLab(labUserInitQuery.labs_by_pk);
      setUsers(labUserInitQuery.users_receiver);
      setUserTypes(labUserInitQuery.enum_receiver_types);
    }
    return () => { mounted = false; };
  }, [labUserInitQuery]);

  const handleCreate = () => {
    navigate(`/admin/labs/${lab_id}/users/create`);
  };

  const handleView = (id: string) => {
    navigate(`/admin/labs/${lab_id}/users/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/labs/${lab_id}/users/${id}/edit`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Labs', link: '/admin/labs' },
        { label: lab.name, link: `/admin/labs/${lab_id}` },
        { label: 'Users' },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UsersList
            users={users.map((i) => ({ id: i.id, full_name: i.full_name, email: i.email, assignments: i.labs_aggregate.aggregate.count, type: { value: i.enum_receiver_type.receiver_type, description: i.enum_receiver_type.description } }))}
            userType="Lab"
            userTypes={userTypes.map((i) => ({ type: i.receiver_type, description: i.description }))}
            gridSize={4}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleView={handleView}
          />
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default withStyles(styles)(ListLabUsers);
