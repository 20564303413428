
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _editPotPathwayInitQuery = (speciality_id: string, category_id: string, pot_pathway_id: string) => ({
  pot_pathways_by_pk: params(
    { id: rawString(pot_pathway_id) },
    {
      id: types.string,
      name: types.string,
      steps: [{
        id: types.string,
        order_index: types.number,
        component_type: types.string,
        mandatory: types.boolean,
        name: types.string,
        description: types.string,
        specimen_qualifier: types.boolean,
        options: types.string,
      }]
    },
  ),
  specialities_by_pk: params(
    { id: rawString(speciality_id) },
    {
      id: types.string,
      name: types.string,
    },
  ),
  speciality_categories_by_pk: params(
    { id: rawString(category_id) },
    {
      id: types.string,
      name: types.string,
    },
  )
});

type X = Unpacked<typeof _editPotPathwayInitQuery>
export type PotPathway = X['pot_pathways_by_pk'];
export type Speciality = X['specialities_by_pk'];
export type Category = X['speciality_categories_by_pk'];

export const editPotPathwayInitQuery = (speciality_id: string, category_id: string, pot_pathway_id: string) => gql`${query(_editPotPathwayInitQuery(speciality_id, category_id, pot_pathway_id))}`;





