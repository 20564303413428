import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 142,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& > $icon svg': {
        color: `${theme.palette.background.default} !important`,
        fill: `${theme.palette.background.default} !important`,
      },
      '& > $label': {
        color: theme.palette.background.default,
      },
    },
  },
  fill: {
    height: '100%',
  },
  small: {
    minHeight: 60,
    flexDirection: 'row',
    '& > $label': {
      marginTop: 0,
      marginLeft: theme.spacing(1),
    },
  },
  icon: {
    color: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
    }
  },
  label: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
});

export default styles;
