import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  icon: {
    marginTop: theme.spacing(2),
    filter: theme.palette.mode === 'dark' ? 'invert(1)' : '',
    opacity: theme.palette.mode === 'dark' ? 1 : 0.87,
    '& > img': {
      width: 70,
      height: 70,
    },
  },
});

export default styles;
