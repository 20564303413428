import React, { useState, useReducer } from 'react';
import GeneralLayout from '../../../../layouts/General';
import { useNavigate } from 'react-router';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import Steps from '../Common/components/Steps';
import { specialityReducer } from '../Common';

import { createSpeciality } from './mutations';


const CreateSpeciality = (): React.ReactElement => {
  const navigate = useNavigate();
  const [speciality, dispatchSpeciality] = useReducer(specialityReducer, {
    name: '',
    description: '',
    icon: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const insertSpecialityVariables = {
      object: {
        ...speciality,
        icon: speciality.icon || null,
      },
    };
    const speciality_id: string | undefined = await createSpeciality(insertSpecialityVariables);
    if (speciality_id) {
      setSaving(false);
      handleFinish(speciality_id);
    }
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/specialities/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Specialities', link: '/admin/specialities' },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          speciality={speciality}
          dispatch={dispatchSpeciality}
          handleSave={handleSave}/>
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Speciality" />}
    </>
  );
};

export default CreateSpeciality;
