import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  fill: {
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  subTitle: {
    fontSize: 14,
    // maxWidth: theme.spacing(25),
  },
  description: {
    fontSize: 12,
    // maxWidth: theme.spacing(25),
  },
  counter: {
    marginTop: 0,
    lineHeight: 1,
    cursor: 'default',
  },
  icon: {
    marginTop: 0,
    marginRight: theme.spacing(2),
    filter: theme.palette.mode === 'dark' ? 'invert(1)' : '',
    opacity: theme.palette.mode === 'dark' ? 1 : 0.87,
    '& > img': {
      width: 70,
      height: 70,
    },
  },
  actions: {
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
});

export default styles;
