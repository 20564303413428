import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertSpecialityMutation = gql`
  mutation ($object: specialities_insert_input!) {
    insert_specialities_one(object: $object) {
      id
    }
  }
`;

export const createSpeciality = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    insert_specialities_one: {
      id: string,
    }
  }>({ mutation: insertSpecialityMutation!, variables });
  return res.data?.insert_specialities_one.id;
}
