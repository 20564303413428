import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../react-app-env';


import Pathway from '../Common';

import styles from './styles';
import PathwayStepElement from './components/PathwayStepElement';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  pathway: Pathway,
  values?: UnknownObject,
  validate: () => void,
  dispatch: React.Dispatch<{ type: string, id: string, value: string }>,
}

const PathwayView = ({ classes, pathway, values = {}, validate, dispatch }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        {pathway.steps.map((step) => (
          <PathwayStepElement
            data={step}
            value={values[step.id] || ''}
            validate={validate}
            dispatch={dispatch} />))}
      </div>
    </>
  );
};

export default withStyles(styles)(PathwayView);
