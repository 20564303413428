import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  IconButton,
  TextField,
} from '@mui/material';

import {
  HighlightOffOutlined as DeleteIcon,
  RadioButtonCheckedOutlined as RadioCheckedIcon,
  RadioButtonUncheckedOutlined as RadioUncheckedIcon,
  ArrowDropDown as ListIcon,
} from '@mui/icons-material';

import styles from './styles';
import { EnumPathwayStepComopnentType, PathwayReducerAction, PathwayStepOption, validationErrorsStepOption, ValidationErrorsStepOption } from '../../../../../Common';
import { UnknownObject } from '../../../../../../../react-app-env';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  stepId: string,
  componentType: EnumPathwayStepComopnentType,
  showSpecimenQualifier: boolean,
  index: number,
  option: PathwayStepOption,
  handleValidateStep: (i: string) => void,
  dispatch: React.Dispatch<{ type: PathwayReducerAction, value: any }>,
}

const PathwayStepOptionElement = ({ classes, stepId, componentType, index, option, showSpecimenQualifier = false, handleValidateStep, dispatch }: Props): React.ReactElement => {
  const [errors, setErrors] = useState<ValidationErrorsStepOption>({
    label: false,
  });

  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'label') {
      errorItems.label = !option.label ? validationErrorsStepOption.label : false;
    }
    setErrors({...errors, ...errorItems});
    handleValidateStep('options');
  }, [errors, handleValidateStep, option]);

  return (
    <div className={classes.root}>
      {componentType === EnumPathwayStepComopnentType.RADIO && (
        index === 0
          ? (<RadioCheckedIcon className={classes.componentIcon} color="primary" />)
          : (<RadioUncheckedIcon className={classes.componentIcon} />)
      )}
      {componentType === EnumPathwayStepComopnentType.LIST && (
        <ListIcon className={classes.componentIcon}  />
      )}
      <TextField
        className={classes.textField}
        data-qa="label-textfield"
        required
        label="Label"
        variant="outlined"
        error={errors.label as boolean}
        helperText={errors.label}
        value={option.label}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PathwayReducerAction.OPTION_LABEL, value: { stepId, optionId: option.id, label: event.target.value || '' } })}
        onKeyUp={() => validate('label')}
        onBlur={() => validate('label')} />


        {index > 1
          ? (<IconButton className={classes.icon} onClick={() => dispatch({ type: PathwayReducerAction.REMOVE_OPTION, value: { stepId, optionId: option.id } })}>
            <DeleteIcon />
          </IconButton>)
          : (<div className={classes.iconPlaceholder}></div>)
        }
    </div>
  );
};

export default withStyles(styles)(PathwayStepOptionElement);
