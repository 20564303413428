import { EnumPathwayStepComopnentType } from '../../../../../components/PathwayBuilder/Common';
import { UnknownObject } from '../../../../../react-app-env';

export interface Case {
  id: string;
  accession_id: string;
  created_at: Date;
  mrn: string;
  note: string;
  priority: string;
  patient: CasePatient;
  organisation: CaseOrganisation;
  lab: CaseLab;
  speciality: CaseSpeciality;
  surgical_pathways: CasePathway[];
  pots: CasePot[];
  case_events: CaseEvent[];
}

export interface CaseEvent {
  event_data: {
    patient: {
      first_name: string;
      last_name: string;
    };
  };
}

export interface CaseOrganisation {
  id: string;
  name: string;
}

export interface CaseLab {
  id: string;
  name: string;
}

export interface CasePatient {
  nhs_id: string;
  dob: Date;
}

export interface CaseSpeciality {
  name: string;
  icon: string;
}

export interface CasePot {
  id: string;
  name: string;
  status: CasePotStatus;
  reason: string;
  note: string;
  pathway: CasePathway[];
  selected: boolean;
}

export interface CasePathway {
  id: string;
  label: string;
  title: string;
  value: string;
}

export interface EnumPotStatus {
  status: string;
  description: string;
}

export enum CasePotStatus {
  INITIAL = 'INITIAL',
  QUERY = 'QUERY',
  HOLDING = 'HOLDING',
  CHECKED_IN = 'CHECKED_IN',
  COMPLETED = 'COMPLETED',
}

export const CasePotStatusRequiresReason = [CasePotStatus.QUERY];

export interface ValidationErrors {
  incomplete: string | boolean;
}

export const validationErrors = {
  incomplete: 'Please provide Case ID',
};

export interface Validation {
  incomplete: (caseData: Case) => string | boolean;
  isPotValid: (pot: CasePot) => boolean;
}

export const validation: Validation = {
  incomplete: (caseData) => {
    if (!caseData.id && !caseData.accession_id) {
      return validationErrors.incomplete;
    }
    return false;
  },
  isPotValid: (pot) => {
    const isNotInitial = pot.status !== CasePotStatus.INITIAL;
    const hasRequiredReason = CasePotStatusRequiresReason.includes(pot.status) ? pot.reason !== '' : true;
    return isNotInitial && hasRequiredReason;
  },
};

export enum CaseReducerAction {
  SELECT_POT,
  UPDATE_POT_STATUS,
  QUERY_POT,
  CANCEL_QUERY_POT,
  CHECK_IN_POT,
  UPDATE_POT_REASON,
  INIT,
}

export const getPatientInfoFromEvents = (caseData: Case | UnknownObject) => {
  if (caseData?.case_events?.length) {
    const caseDataEvent = caseData.case_events[0];

    return `${caseDataEvent.event_data?.patient.first_name} ${caseDataEvent.event_data?.patient.last_name}`;
  }

  return '';
};

const getPotIndex = (pots: CasePot[], id: string) => {
  return pots.findIndex((i: CasePot) => i.id === id);
};

export const caseReducer = (state: Case | UnknownObject, action: { type: CaseReducerAction; value: any }): Case | UnknownObject => {
  switch (action.type) {
    case CaseReducerAction.SELECT_POT:
      return {
        ...state,
        pots: state.pots.map((i: CasePot) => ({ ...i, selected: i.id === action.value.id })),
      };
    case CaseReducerAction.UPDATE_POT_STATUS:
      const potIndex = getPotIndex(state.pots, action.value.id);
      if (potIndex >= 0) {
        state.pots[potIndex].status = action.value.status;
      }
      return { ...state };
    case CaseReducerAction.QUERY_POT:
      state.pots.forEach((p: CasePot) => {
        const status = p.status === CasePotStatus.INITIAL ? CasePotStatus.INITIAL : CasePotStatus.HOLDING;
        p.status = p.id === action.value.id || p.status === CasePotStatus.QUERY ? CasePotStatus.QUERY : status;
      });
      return { ...state };
    case CaseReducerAction.CANCEL_QUERY_POT:
      const otherPots = state.pots.filter((p: CasePot) => p.id !== action.value.id);
      const anyOthersInQuery = otherPots.some((p: CasePot) => p.status === CasePotStatus.QUERY);
      state.pots.forEach((p: CasePot) => {
        if (p.id === action.value.id) {
          p.status = CasePotStatus.INITIAL;
          p.reason = '';
        } else if (![CasePotStatus.INITIAL, CasePotStatus.QUERY].includes(p.status)) {
          p.status = anyOthersInQuery ? CasePotStatus.HOLDING : CasePotStatus.CHECKED_IN;
        }
      });
      return { ...state };
    case CaseReducerAction.CHECK_IN_POT:
      const anyInQuery = state.pots.some((p: CasePot) => p.status === CasePotStatus.QUERY);
      const ptIndex = getPotIndex(state.pots, action.value.id);
      if (ptIndex >= 0) {
        state.pots[ptIndex].status = anyInQuery ? CasePotStatus.HOLDING : CasePotStatus.CHECKED_IN;
      }
      return { ...state };
    case CaseReducerAction.UPDATE_POT_REASON:
      const pIndex = getPotIndex(state.pots, action.value.id);
      if (pIndex >= 0) {
        state.pots[pIndex].reason = action.value.reason || '';
      }
      return { ...state };
    case CaseReducerAction.INIT:
      const { id, accession_id, created_at, mrn, note, enum_case_priority, patient, organisation, surgical_pathways, surgical_pathway_version, lab, pots, case_events } =
        action.value;
      const obj: Case = {
        id,
        accession_id,
        created_at: new Date(created_at),
        mrn,
        note,
        patient,
        priority: enum_case_priority.description,
        lab: lab,
        organisation: organisation,
        surgical_pathways,
        speciality: {
          name: surgical_pathway_version.surgical_pathways.name,
          icon: surgical_pathway_version.surgical_pathways.category.icon,
        },
        pots: pots.map((i: UnknownObject, index: number) => ({
          id: i.id,
          name: i.name,
          pathway: i.pathway.map((p: CasePathway) => ({
            ...p,
            component_type: EnumPathwayStepComopnentType.READ_ONLY,
          })),
          note: i.note || '',
          reason: i.queries[0]?.message || '',
          status: (i.enum_pot_status?.status as CasePotStatus) || CasePotStatus.INITIAL,
          selected: index === 0,
        })),
        case_events,
      };
      return { ...obj };
    default:
      throw new Error();
  }
};
