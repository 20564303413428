import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    actions: {
      '@media print': {
        display: 'none',
      },
    },
  });

export default styles;
