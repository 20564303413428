import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {},
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  toolbar: {
    marginTop: theme.spacing(2),
  },
  title: {

  },
  counter: {
    marginRight: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  collapseCell: {
    width: 60,
  },
  lastRow: {
    borderBottomWidth: 0,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    '& > button': {
      marginLeft: theme.spacing(1),
    },
  },
});

export default styles;
