import React, { useState, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import GeneralLayout from '../../../../../layouts/General';

import CustomBackdrop from '../../../../../components/CustomBackdrop';
import Steps from '../Common/components/Steps';
import { categoryReducer } from '../Common';

import { createCategory } from './mutations';
import { createCategoriesInitQuery, Speciality } from './queries';



const CreateCategory = (): React.ReactElement => {
  const navigate = useNavigate();
  const { speciality_id } = useParams();

  const [category, dispatchCategory] = useReducer(categoryReducer, {
    name: '',
    description: '',
    icon: '',
  });

  const [speciality, setSpeciality] = useState<Speciality | null>(null);
  const [saving, setSaving] = useState<boolean>(false);

  const { data: createCategoriesInitData } = useQuery(createCategoriesInitQuery(speciality_id!));

  useEffect(() => {
    let mounted = true;
    if (mounted && createCategoriesInitData) {
      setSpeciality(createCategoriesInitData.specialities_by_pk);
    }
    return () => { mounted = false; };
  }, [createCategoriesInitData]);

  const handleSave = async () => {
    setSaving(true);

    const insertCategoryVariables = {
      object: {
        speciality_id,
        ...category,
        icon: category.icon || null,
      },
    };
    const category_id: string | undefined = await createCategory(insertCategoryVariables);
    if (category_id) {
      setSaving(false);
      handleFinish(category_id);
    }
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/specialities/${speciality_id}/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Specialities', link: '/admin/specialities' },
          { label: `${speciality?.name}`, link: `/admin/specialities/${speciality_id}` },
          { label: 'Create Category' },
        ]}>
        <Steps
          completeLabel="Create"
          category={category}
          dispatch={dispatchCategory}
          handleSave={handleSave}/>
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Category" />}
    </>
  );
};

export default CreateCategory;
