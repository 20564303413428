import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';


import ReadOnlyBlock from '../../../../ReadOnlyBlock';
import Pathway, { PathwayStep, PathwayStepOption } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  pathway: Pathway,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const PathwayReview = ({ classes, pathway, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          ...pathway.steps.map((i: PathwayStep) => ({ label: i.name, value: i.description, list: i.options.map((o: PathwayStepOption) => o.label) })),
        ]}
        handleEdit={() => setStep(0)} />
    </>
  );
};

export default withStyles(styles)(PathwayReview);
