import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

export interface Speciality {
  name: string,
  description: string,
  icon: string,
}

const updateSpecialityOrderMutation = gql`
  mutation ($objects: [specialities_insert_input!]!) {
    insert_specialities(objects: $objects, on_conflict: {
      constraint: specialities_pkey,
      update_columns: order_index
    }) {
      returning {
        id
        order_index
      }
    }
  }
`;

export const editSpecialityOrder = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_specialities: {
      returning: [{
        id: string,
        order_index: number,
      }]
    }
  }>({ mutation: updateSpecialityOrderMutation!, variables });
}
