import React, { useContext } from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
// import { Logo } from '../../../components/App/logo';
import { Header, HeaderIcon, LogoContainer, Root } from './styles';
import { AuthContext } from '../../../components/AuthProvider';
import { wordsToAcronym } from '@blumtechgroup/blum-ui-utils/dist/cjs/utils/stringUtils';
import { Notifications as NotificationsIcon } from '@mui/icons-material';

const LayoutHeader = (): React.ReactElement => {
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }

  const { userRole, user } = authContext;

  return (
    <Root position="fixed">
      <Header container xs>
        <Grid item>
          <Grid alignItems={'center'} container direction="row">
            <LogoContainer item>
              {/*<Logo />*/}
            </LogoContainer>
          </Grid>
        </Grid>
        <Grid item alignItems={'center'} display={'flex'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box lineHeight={1} textAlign={'end'} mx={2}>
              <Typography lineHeight={1.5} component="p">
                {user?.full_name}
              </Typography>
              <Typography sx={(theme) => ({ color: `${theme.palette.primary.contrastText}70` })} lineHeight={1}>
                {userRole}
              </Typography>
            </Box>
            <Avatar sx={(theme) => ({ background: theme.palette.primary.dark })} variant="circular">
              {user ? wordsToAcronym(user.full_name) : ''}
            </Avatar>
            <Box ml={2}>
              <HeaderIcon size="small" aria-label="notifications">
                <NotificationsIcon />
              </HeaderIcon>
            </Box>
          </Box>
        </Grid>
      </Header>
    </Root>
  );
};

export default LayoutHeader;
