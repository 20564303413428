import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../../react-app-env';

import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../../../layouts/General';
import PathwayView from '../../../../../../components/PathwayBuilder/View';
import { pathwayReducer, PathwayReducerAction, pathwayValueReducer } from '../../../../../../components/PathwayBuilder/Common';

import { selectSurgicalPathwayInitQuery, Category, Speciality } from './queries';

const ViewSurgicalPathway = (): React.ReactElement => {
  const { speciality_id, category_id, surgical_pathway_id } = useParams();

  const [speciality, setSpeciality] = useState<Speciality | UnknownObject>({});
  const [category, setCategory] = useState<Category | UnknownObject>({});

  const [pathway, dispatchPathway] = useReducer(pathwayReducer, {
    id: '',
    name: '',
    steps: [],
  });

  const [, dispatchPathwayValues] = useReducer(pathwayValueReducer, {});

  const { data: surgicalPathwayInitData } = useQuery(selectSurgicalPathwayInitQuery(speciality_id!, category_id!, surgical_pathway_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && surgicalPathwayInitData) {
      setSpeciality(surgicalPathwayInitData.specialities_by_pk);
      setCategory(surgicalPathwayInitData.speciality_categories_by_pk);
      dispatchPathway({ type: PathwayReducerAction.INIT, value: surgicalPathwayInitData.surgical_pathways_by_pk });
    }
    return () => { mounted = false; };
  }, [surgicalPathwayInitData]);

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Specialities', link: '/admin/specialities' },
        { label: `${speciality?.name}`, link: `/admin/specialities/${speciality_id}` },
        { label: `${category?.name}`, link: `/admin/specialities/${speciality_id}/${category_id}` },
        { label: `Surgical Pathway` },
      ]}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <PathwayView
            pathway={pathway}
            validate={() => {}}
            dispatch={dispatchPathwayValues} />
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ViewSurgicalPathway;
