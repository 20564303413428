import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  TextField,
} from '@mui/material';

import { Speciality, ValidationErrors, validationErrors, SpecialityReducerAction } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  speciality: Speciality,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: SpecialityReducerAction, value: any, }>,
}

const StepDetails = ({ classes, speciality, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'name') {
      errorItems.name = !speciality.name ? validationErrors.name : false;
    }
    if (field === 'description') {
      errorItems.description = !speciality.description ? validationErrors.description : false;
    }
    setErrors({...errors, ...errorItems});
  }, [speciality, errors, setErrors]);


  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={speciality.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SpecialityReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}/>
        <TextField
          className={classes.textField}
          data-qa="description-textfield"
          required
          multiline
          label="Description"
          variant="outlined"
          error={errors.description as boolean}
          helperText={errors.description}
          value={speciality.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SpecialityReducerAction.DESCRIPTION, value: event.target.value })}
          onKeyUp={() => validate('description')}
          onBlur={() => validate('description')} />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
