import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';


import CustomTransferList, { CustomTransferListItem } from '../../../../../../../components/CustomTransferList';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  labs: CustomTransferListItem[],
  assignedLabs: CustomTransferListItem[],
  handleLabAssignment: (left: CustomTransferListItem[], right: CustomTransferListItem[]) => void,
}

const StepLabAssignment = ({ classes, labs, assignedLabs, handleLabAssignment }: Props): React.ReactElement => {
  return (
    <>
      <CustomTransferList
        labelLeft="Unassigned Lab"
        labelRight="Assigned Lab"
        description="Assign labs to the user"
        allItems={labs}
        currentItems={assignedLabs}
        handleTransfer={handleLabAssignment} />
    </>
  );
};

export default withStyles(styles)(StepLabAssignment);
