import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';

import {
  TextField,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import { ReceiverUser, EnumReceiverType, ValidationErrors, validationErrors, ReceiverUserReducerAction } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  user: ReceiverUser,
  userTypes: EnumReceiverType[],
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: ReceiverUserReducerAction, value: any }>,
}

const StepUserRole = ({ classes, user, userTypes = [], errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(() => {
    const errorItems: UnknownObject = {}
    errorItems.user_role = !user.receiver_type ? validationErrors.user_role : false;
    setErrors({...errors, ...errorItems});
  }, [user, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <Autocomplete
          data-qa="user-role-list"
          options={userTypes}
          getOptionLabel={(option: EnumReceiverType) => option.description}
          value={userTypes.find(i => i.receiver_type === user.receiver_type)}
          autoComplete
          onBlur={() => validate()}
          onChange={(_: unknown, newValue: EnumReceiverType | null) => {
            const value = newValue ? newValue.receiver_type : '';
            dispatch({ type: ReceiverUserReducerAction.USER_ROLE, value });
          }}
          renderInput={(params) =>
            <TextField
              data-qa="user-role-input"
              {...params}
              label="User role"
              name="user-role"
              error={errors.user_role as boolean}
              helperText={errors.user_role} />}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepUserRole);
