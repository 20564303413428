import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../../components/ReadOnlyBlock';
import { Category } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  category: Category,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, category, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Category name', value: category.name },
          { label: 'Description', value: category.description }
        ]}
        handleEdit={() => setStep(0)} />
      <ReadOnlyBlock
        items={[
          {
            label: 'Icon',
            icon: category.icon ? `data:image/svg+xml;base64,${btoa(category.icon)}` : undefined,
            value: !category.icon ? 'No Icon' : undefined,
          }
        ]}
        handleEdit={() => setStep(1)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
