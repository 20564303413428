import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import CustomStepper from '../../../../../../components/CustomStepper';
import { CustomTransferListItem } from '../../../../../../components/CustomTransferList';
import { Organisation, OrganisationType, validationErrors, ValidationErrors, Lab, OrganisationReducerAction } from '../../../Common';
import StepDetails from '../StepDetails';
import StepOrganisationType from '../StepOrganisationType';
import StepLabAssignment from '../StepLabAssignment';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  organisation: Organisation,
  assignedLabs: Lab[],
  labs: CustomTransferListItem[],
  organisationTypes: OrganisationType[],
  dispatch: React.Dispatch<{ type: OrganisationReducerAction, value: any }>,
  setIsNewOrganisationType: React.Dispatch<React.SetStateAction<boolean>>,
  setAssignedLabs: React.Dispatch<React.SetStateAction<Lab[]>>,
  handleSave: () => void,
}

const OrganisationSteps = ({ classes, completeLabel, organisation, labs, organisationTypes, assignedLabs, dispatch, setAssignedLabs, setIsNewOrganisationType, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    address_line_1: false,
    address_city: false,
    address_postcode: false,
    organisation_type: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = !organisation.name ? validationErrors.name : false;
      errorItems.address_line_1 = !organisation.address_line_1 ? validationErrors.address_line_1 : false;
      errorItems.address_city = !organisation.address_city ? validationErrors.address_city : false;
      errorItems.address_postcode = !organisation.address_postcode ? validationErrors.address_postcode : false;
    }
    if (_step === 1) {
      errorItems.organisation_type = !organisation.organisation_type ? validationErrors.organisation_type : false;
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [organisation, errors]);

  const handleLabAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    setAssignedLabs(assigned.map((i) => ({ id: i.id, name: i.label })));
  }

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                organisation={organisation}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Organisation type',
            component:
              <StepOrganisationType
                organisation={organisation}
                errors={errors}
                organisationTypes={organisationTypes}
                setErrors={setErrors}
                setIsNew={setIsNewOrganisationType}
                dispatch={dispatch} />
          },
          {
            label: 'Lab assignment',
            gridSize: 8,
            component:
              <StepLabAssignment
                labs={labs}
                assignedLabs={assignedLabs.map((lab: Lab) => ({ id: lab.id, label: lab.name }))}
                handleLabAssignment={handleLabAssignment} />
          },
          {
            label: 'Summary',
            component:
              <StepReview
                  organisation={organisation}
                  organisationTypes={organisationTypes}
                  assignedLabs={assignedLabs.map((lab: Lab) => lab.name)}
                  setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationSteps);
