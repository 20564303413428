import { gql } from '@apollo/client';
import gqlClient from '../../../../../../utils/apolloClient';

const insertSurgicalPathwayMutation = gql`
  mutation ($object: surgical_pathways_insert_input!) {
    insert_surgical_pathways_one(object: $object) {
      id
    }
  }
`;

const insertSurgicalPathwayStepsMutation = gql`
  mutation ($objects: [surgical_pathway_steps_insert_input!]!) {
    insert_surgical_pathway_steps(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const createSurgicalPathway = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    insert_surgical_pathways_one: {
      id: string,
    }
  }>({ mutation: insertSurgicalPathwayMutation!, variables });
  return res.data?.insert_surgical_pathways_one.id;
}

export const createSurgicalPathwaySteps = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_surgical_pathway_steps: {
      id: string,
    }
  }>({ mutation: insertSurgicalPathwayStepsMutation!, variables });
}
