import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

const insertLabUserMutation = gql`
  mutation ($object: users_receiver_insert_input!) {
    insert_users_receiver_one(object: $object) {
      id
    }
  }
`;

const insertUserLabAssignmentMutation = gql`
  mutation ($assignments: [user_lab_assignments_insert_input!]!) {
    insert_user_lab_assignments(objects: $assignments) {
      returning {
        id
      }
    }
  }
`;

export const createLabUser = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_users_receiver_one: {
      id: string,
    }
  }>({ mutation: insertLabUserMutation!, variables });
  return res.data?.insert_users_receiver_one.id;
}

export const createUserLabAssignments = async (assignments: object[]) => {
  return await gqlClient.mutate<{
    insert_user_lab_assignments: {
      returning: [{
        id: string,
      }],
    }
  }>({ mutation: insertUserLabAssignmentMutation!, variables: { assignments } });
}
