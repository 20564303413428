import { params, subscription, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

export const limsSubscription = gql`${subscription({
  case_events: params({
    where: { completed: { _eq: false } },
    order_by: { created_at: 'asc' },
    limit: 1
  },
    {
      id: types.string,
      case_id: types.string,
      accession_number: types.string,
      event_data: types.custom(),
      event_type: types.string,
    })
})}`;