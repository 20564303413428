import React, { useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { Fade } from '@mui/material';
import { useNavigate } from 'react-router';

import { Logo } from '../../../components/Logos';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const Splash = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        navigate('/sign-in');
      }, 5000);
    }
    return () => { mounted = false; };
  }, [navigate]);

  return (
    <div className={classes.root}>
      <Fade in timeout={2700}>
        <div className={classes.icon}>
          <Logo />
        </div>
      </Fade>
    </div>
  );
};

export default withStyles(styles)(Splash);
