import React, { useEffect, useState, useContext, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import useLocalStorage from '../../../../hooks/useLocalStorage';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
  Switch
} from '@mui/material';

import { WithStyles, withStyles } from '@mui/styles';

import {
  HomeOutlined as HomeIcon,
  Home as HomeActiveIcon,
  DoubleArrowOutlined as PathwayIcon,
  DoubleArrow as PathwayActiveIcon,
  HomeWorkOutlined as OrganisationIcon,
  HomeWork as OrganisationActiveIcon,
  ColorizeOutlined as LabIcon,
  Colorize as LabActiveIcon,
  GroupAddOutlined as UesrsIcon,
  GroupAdd as UesrsActiveIcon,
  AssignmentOutlined as CaseIcon,
  Assignment as CaseActiveIcon,
  QueuePlayNextOutlined as LimsIcon,
  QueuePlayNext as LimsActiveIcon,
  ChevronLeftOutlined as CollapseIcon,
  ChevronRightOutlined as ExpandIcon,
  SettingsOutlined as SettingsIcon,
} from '@mui/icons-material';

import { AuthContext } from '../../../../components/AuthProvider';
import { CustomThemeContext } from '../../../../components/CustomThemeProvider';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {}

interface ListItemRole {
  admin: ListItemRoleItem[];
  sender_admin: ListItemRoleItem[];
  sender_lead: ListItemRoleItem[];
  sender_member: ListItemRoleItem[];
  sender_readonly: ListItemRoleItem[];
  receiver_admin: ListItemRoleItem[];
  receiver_lead: ListItemRoleItem[];
  receiver_member: ListItemRoleItem[];
  receiver_readonly: ListItemRoleItem[];
}
interface ListItemRoleItem {
  label: string;
  icon: React.ReactElement;
  iconActive: React.ReactElement;
  to: string;
}

const Sidebar = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole, signOut } = useContext(AuthContext)!;
  const { darkMode, toggleDarkMode } = useContext(CustomThemeContext)!;

  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const settingsAnchorRef = useRef<HTMLButtonElement>(null);
  const settingsMenuRef = useRef(isSettingsMenuOpen);
  const [isExpanded, setIsExpanded] = useLocalStorage<boolean>('sidebar-expanded', true);

  const handleExpandToggle = () => {
    setIsExpanded((i) => !i);
  };

  useEffect(() => {
    if (settingsMenuRef.current === true && isSettingsMenuOpen === false) {
      settingsAnchorRef.current!.focus();
    }
    settingsMenuRef.current = isSettingsMenuOpen;
  }, [isSettingsMenuOpen]);

  const handleSettingsMenuToggle = () => {
    setIsSettingsMenuOpen((i) => !i);
  };

  const handleSettingsMenuClose = (event: any) => {
    if (settingsAnchorRef.current && settingsAnchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setIsSettingsMenuOpen(false);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/sign-in',  { replace: true });
  };

  const senderDefaultItems: ListItemRoleItem[] = [
    { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/org/home' },
    { label: 'Cases', icon: <CaseIcon />, iconActive: <CaseActiveIcon />, to: '/org/cases' },
  ];

  const receiverDefaultItems: ListItemRoleItem[] = [
    { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/lab/home' },
    { label: 'Cases', icon: <CaseIcon />, iconActive: <CaseActiveIcon />, to: '/lab/cases' },
  ];

  const listItems: ListItemRole = {
    admin: [
      { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/admin/home' },
      { label: 'Organisations', icon: <OrganisationIcon />, iconActive: <OrganisationActiveIcon />, to: '/admin/organisations' },
      { label: 'Labs', icon: <LabIcon />, iconActive: <LabActiveIcon />, to: '/admin/labs' },
      { label: 'Pathways', icon: <PathwayIcon />, iconActive: <PathwayActiveIcon />, to: '/admin/specialities' },
      { label: 'Users', icon: <UesrsIcon />, iconActive: <UesrsActiveIcon />, to: '/admin/users' },
      { label: 'LIMS', icon: <LimsIcon />, iconActive: <LimsActiveIcon />, to: '/admin/lims' },
    ],
    sender_admin: [...senderDefaultItems],
    sender_lead: [...senderDefaultItems],
    sender_member: [...senderDefaultItems],
    sender_readonly: [...senderDefaultItems],
    receiver_admin: [...receiverDefaultItems],
    receiver_lead: [...receiverDefaultItems],
    receiver_member: [...receiverDefaultItems],
    receiver_readonly: [...receiverDefaultItems],
  };

  return (
    <nav className={classes.root}>
      <Paper square elevation={0} className={`${classes.container} ${isExpanded ? classes.containerExpand : classes.containerCollapse}`}>
        <div className={classes.listContainer}>
          <List className={classes.list} component="div" disablePadding>
            {userRole !== null &&
              listItems[userRole as keyof typeof listItems].map((item: ListItemRoleItem) => (
                <ListItem
                  key={item.label}
                  className={`${classes.listItem} ${location.pathname.startsWith(item.to) ? classes.activeListItem : ''}`}
                >
                  <NavLink to={item.to} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', width: '100%' }}>
                    {isExpanded && (
                      <>
                        <ListItemIcon className={classes.listItemIcon}>
                          {location.pathname.startsWith(item.to) ? item.iconActive : item.icon}
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText} primary={item.label} />
                      </>
                    )}
                    {!isExpanded && (
                      <Tooltip title={item.label} placement="right">
                        <ListItemIcon className={classes.listItemIcon}>
                          {location.pathname.startsWith(item.to) ? item.iconActive : item.icon}
                        </ListItemIcon>
                      </Tooltip>
                    )}
                  </NavLink>
                </ListItem>
              ))}
          </List>
        </div>
        <div className={classes.settingsContainer}>
          <Divider className={classes.listDivider} />
          <List className={classes.list} component="div" disablePadding>
            <ListItem onClick={handleSettingsMenuToggle} className={classes.listItem}>
              {isExpanded && (
                <>
                  <ListItemIcon className={classes.listItemIcon}>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    classes={{ primary: classes.listItemText }}
                    primary="Settings"
                    ref={settingsAnchorRef}
                    aria-controls={isSettingsMenuOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                  />
                </>
              )}
              {!isExpanded && (
                <>
                  <Tooltip title="Settings" placement="right" ref={settingsAnchorRef} aria-controls={isSettingsMenuOpen ? 'menu-list-grow' : undefined} aria-haspopup="true">
                    <ListItemIcon className={classes.listItemIcon}>
                      <SettingsIcon />
                    </ListItemIcon>
                  </Tooltip>
                </>
              )}
              <Popper
                open={isSettingsMenuOpen}
                anchorEl={settingsAnchorRef.current}
                className={classes.settingsMenu}
                role={undefined}
                placement="right-end"
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps} style={{ transformOrigin: placement }}>
                    <Paper variant="outlined">
                      <ClickAwayListener onClickAway={handleSettingsMenuClose}>
                        <MenuList className={classes.settingsMenuList} autoFocusItem={isSettingsMenuOpen} id="settings-menu-list">
                          <MenuItem onClick={handleSettingsMenuClose}>
                            <Typography variant="inherit" noWrap>
                              My account
                            </Typography>
                          </MenuItem>
                          <MenuItem onClick={handleSignOut}>
                            <Typography variant="inherit" noWrap>
                              Sign out
                            </Typography>
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={toggleDarkMode}>
                            <Typography variant="inherit" noWrap>
                              Toggle dark mode
                            </Typography>
                            <ListItemIcon>
                              <Switch checked={darkMode} color="primary" />
                            </ListItemIcon>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </ListItem>
          </List>
        </div>
      </Paper>
      <div className={classes.expandContainer} onClick={handleExpandToggle}>
        <Divider orientation="vertical" className={classes.expandDivider} />
        <Tooltip title={isExpanded ? 'Collapse' : 'Expand'} placement="bottom">
          <div className={classes.expandToggle}>
            {isExpanded && <CollapseIcon />}
            {!isExpanded && <ExpandIcon />}
          </div>
        </Tooltip>
      </div>
    </nav>
  );
};

export default withStyles(styles)(Sidebar);
