import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { drawerWidth, hiddenSidebarSpacing } from '../Sidebar/common';
import { EmptyObject } from '@blumtechgroup/blum-ui-utils/dist/cjs/types';
import { Link as RouterLink } from 'react-router-dom';
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { generateShortUuid } from '@blumtechgroup/blum-ui-utils/dist/cjs/utils/stringUtils';

interface Props extends React.PropsWithChildren<EmptyObject> {
  open: boolean;
  breadcrumbs: { label: string; link?: string }[];
  currentPage?: string;
  endContent?: React.ReactElement;
}

const Content = ({ children, open, breadcrumbs, currentPage, endContent }: Props) => {
  return (
    <Grid
      container
      spacing={2}
      sx={(theme) => ({
        height: '100%',
        padding: `${theme.spacing(10)} ${theme.spacing(3)}`,
        flexGrow: 1,
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ml: open ? `${drawerWidth}px` : `calc(${theme.spacing(hiddenSidebarSpacing + 1)} + 1px)`,
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
        ...(!open && {
          width: `calc(100% - ${drawerWidth / 3}px)`,
        }),
      })}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Breadcrumbs
                  sx={{ mb: breadcrumbs.length > 0 ? 2 : 0 }}
                  separator={<NavigateNextIcon key={generateShortUuid()} fontSize="small" />}
                  itemsAfterCollapse={2}
                  aria-label="breadcrumb">
                  {breadcrumbs.map((item) => (
                    <Link component={RouterLink} underline="hover" color="inherit" to={item.link!}>
                      {item.label}
                    </Link>
                  ))}
                </Breadcrumbs>
              </Grid>
              {endContent && <Grid item>{endContent}</Grid>}
            </Grid>
          </Grid>
          {currentPage && (
            <Grid item xs={12}>
              <Typography variant="h5">{currentPage}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={(theme) => ({ height: '100%', '& .MuiDataGrid-root': { background: theme.palette.primary.contrastText } })}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Content;
