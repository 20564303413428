import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';

import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { CheckCircleOutlined, ErrorOutlineOutlined } from '@mui/icons-material';

import PathwayReadOnlyView from '../../../../../../../components/PathwayBuilder/View/readonly';
import SpecialityCard from '../../../../../../../components/SpecialityCard';
import {
  Case,
  CasePot,
  CasePotStatusRequiresReason,
  CaseReducerAction,
  EnumPotStatus,
  validation,
} from '../../case';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  caseData: Case | UnknownObject,
  potStatuses: EnumPotStatus[],
  validate: () => void,
  dispatch: React.Dispatch<{ type: CaseReducerAction, value: any}>,
}

const StepPots = ({ classes, caseData, potStatuses, validate, dispatch }: Props): React.ReactElement => {
  const getSelectedPot = () => caseData.pots.find((i: CasePot) => i.selected);

  const handleSomthingIsWrong = (pot: CasePot) => {
    dispatch({ type: CaseReducerAction.QUERY_POT, value: { id: pot.id } });
  }

  const handleCancelQuery = (pot: CasePot) => {
    dispatch({ type: CaseReducerAction.CANCEL_QUERY_POT, value: { id: pot.id } });
  }

  const handleCheckIn = (pot: CasePot) => {
    dispatch({ type: CaseReducerAction.CHECK_IN_POT, value: { id: pot.id } });
  }

  const getPotContent = () => {
    const pot = getSelectedPot();
    if (pot) {
      return (
        <>
          <Grid container>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.headerTitle} variant="h5">{pot.name}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <PathwayReadOnlyView
                    pathway={pot.pathway} />
                    {pot.note && (
                      <TextField
                        multiline
                        fullWidth
                        disabled
                        variant="outlined"
                        label="Additional information"
                        value={pot.note}
                        rows={4}
                        InputLabelProps={{ shrink: true }} />
                    )}
                </Grid>

                <Grid item xs={12}>
                  {!CasePotStatusRequiresReason.includes(pot.status) && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleCheckIn(pot)}>
                          Check-In
                      </Button>
                      <Button
                        className={classes.reasonButton}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => handleSomthingIsWrong(pot)}>
                          Something is wrong
                      </Button>
                    </>
                  )}

                  {CasePotStatusRequiresReason.includes(pot.status) && (
                    <div className={classes.query}>
                      <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        label="Query"
                        placeholder="Type query here"
                        value={pot.reason}
                        rows={8}
                        maxRows={12}
                        error={!pot.reason}
                        helperText={!pot.reason ? 'Please provide a query' : ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CaseReducerAction.UPDATE_POT_REASON, value: { reason: event.target.value, id: pot.id }})} />
                      <Button
                        className={classes.reasonButton}
                        variant="outlined"
                        fullWidth
                        onClick={() => handleCancelQuery(pot)}>
                          Cancel
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
    return <Typography variant="subtitle1">Pot not selected</Typography>
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h4">Pot Information</Typography>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div className={classes.pots}>
              <div className={classes.potsList}>
                {caseData.pots.map((pot: CasePot) => (
                  <SpecialityCard
                    key={pot.id}
                    small
                    gridSize={12}
                    title={pot.name}
                    subTitle={potStatuses.find((i) => i.status === pot.status)?.description || undefined}
                    description={pot.pathway.map((pathway) => pathway.label).join(', ')}
                    icon={caseData.speciality.icon}
                    statusIcon={validation.isPotValid(pot) ? <CheckCircleOutlined/> : <ErrorOutlineOutlined />}
                    statusIconColour={validation.isPotValid(pot) ? 'success' : 'error'}
                    selected={pot.selected}
                    onClick={() => dispatch({ type: CaseReducerAction.SELECT_POT, value: { id: pot.id } })} />
                ))}
              </div>
              <Divider orientation="vertical" />
            </div>
          </Grid>
          <Grid item xs={8}>
            {getPotContent()}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(StepPots);
