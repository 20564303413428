
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLabInitQuery = (id: string) => ({
  labs_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      address_line_1: types.string,
      address_line_2: types.string,
      address_city: types.string,
      address_postcode: types.string,
      contact: types.string,
      organisation_assignments: {
        organisation: {
          id: types.string,
          name: types.string,
        },
      },
      users_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    },
  ),
});

type X = Unpacked<typeof _selectLabInitQuery>
export type Lab = X['labs_by_pk'];
export type OrganisationAssignment = Lab['organisation_assignments'];
export type Organisation = OrganisationAssignment['organisation'];

export const selectLabInitQuery = (id: string) => gql`${query(_selectLabInitQuery(id))}`;
