
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _editCategoriesInitQuery = (speciality_id: string, category_id: string) => ({
  specialities_by_pk: params(
    { id: rawString(speciality_id) },
    {
      id: types.string,
      name: types.string,
    },
  ),
  speciality_categories_by_pk: params(
    { id: rawString(category_id) },
    {
      name: types.string,
      description: types.string,
      icon: types.string,
    },
  ),
});

type X = Unpacked<typeof _editCategoriesInitQuery>
export type Speciality = X['specialities_by_pk'];
export type Category = X['speciality_categories_by_pk'];

export const editCategoriesInitQuery = (speciality_id: string, category_id: string) => gql`${query(_editCategoriesInitQuery(speciality_id, category_id))}`;
