import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

const updateSenderUserMutation = gql`
  mutation ($pk_columns: users_sender_pk_columns_input!, $set: users_sender_set_input!) {
    update_users_sender_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

const updateUserOrganisationAssignmentMutation = gql`
  mutation ($assignments: [user_organisation_assignments_insert_input!]!, $remove: [uuid!]!) {
    insert_user_organisation_assignments(objects: $assignments) {
      returning {
        id
      }
    }
    delete_user_organisation_assignments(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const updateSenderUser = async (variables: object) => {
  return await gqlClient.mutate<{
    update_users_sender_by_pk: {
      id: string,
    }
  }>({ mutation: updateSenderUserMutation!, variables });
}

export const updateUserOrganisationAssignments = async (assignments: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    update_user_organisation_assignments: {
      returning: [{
        id: string,
      }],
    }
    delete_user_organisation_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateUserOrganisationAssignmentMutation!, variables: { assignments, remove } });
}

