import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  fieldset: {
    flexGrow: 1
  },
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 180,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  component: {
    width: '100%',
    paddingLeft: theme.spacing(2),
  },
});

export default styles;
