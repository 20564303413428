import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import CustomTransferList, { CustomTransferListItem } from '../../../../../../../components/CustomTransferList';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  organisations: CustomTransferListItem[],
  assignedOrganisations: CustomTransferListItem[],
  handleLabAssignment: (left: CustomTransferListItem[], right: CustomTransferListItem[]) => void,
}

const StepOrganisationAssignment = ({ classes, organisations, assignedOrganisations, handleLabAssignment }: Props): React.ReactElement => {
  return (
    <>
      <CustomTransferList
        labelLeft="Unassigned Organisation"
        labelRight="Assigned Organisation"
        description="Assign organisations to the user"
        allItems={organisations}
        currentItems={assignedOrganisations}
        handleTransfer={handleLabAssignment} />
    </>
  );
};

export default withStyles(styles)(StepOrganisationAssignment);
