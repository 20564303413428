import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import {
  Grid,
} from '@mui/material';


import GridCard from '../../../components/Grid/GridCard';

import { homeInitQuery } from './queries';
import { GeneralLayout } from '../../../layouts';

const Home = (): React.ReactElement => {
  const navigate = useNavigate();
  const [casesCount, setCasesCount] = useState<number>(0);

  const { data: homeInitData } = useQuery(homeInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && homeInitData) {
      setCasesCount(homeInitData.cases_aggregate.aggregate.count);
    }
    return () => { mounted = false; };
  }, [homeInitData]);

  const handleAction = (link: string) => {
    navigate(link);
  }

  const actions = [
    {
      title: 'Cases',
      counter: casesCount,
      viewLink: '/lab/cases',
    }
  ];

  return (
    <GeneralLayout currentPage="Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            key={action.title}
            gridSize={4}
            title={action.title}
            counter={action.counter}
            handleView={() => handleAction(action.viewLink)} />
        ))}
      </Grid>
    </GeneralLayout>
  );
};

export default Home;
