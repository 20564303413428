import React from 'react';
import { ThemeProvider, createTheme, Theme, StyledEngineProvider } from '@mui/material/styles';


import theme from '../CustomThemeProvider/theme';
import { EmptyObject } from '../../react-app-env';
import useLocalStorage from '../../hooks/useLocalStorage';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props extends React.PropsWithChildren<EmptyObject> {}

interface CutsomThemeContextItems {
  darkMode: boolean,
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  toggleDarkMode: () => void;
}

export const CustomThemeContext = React.createContext<CutsomThemeContextItems | null>(null);

const CutsomThemeProvider = ({ children }: Props): React.ReactElement => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useLocalStorage<boolean>('dark-mode', false);


  const mainTheme = createTheme(theme(darkMode) as any);


  const handleToggleDarkMode = () => {
    setDarkMode(i => !i);
  };

  const contextItems: CutsomThemeContextItems = {
    darkMode,
    setDarkMode,
    toggleDarkMode: handleToggleDarkMode,
  };

  return (
    <CustomThemeContext.Provider value={contextItems}>
      <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        {children}
      </ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
}

export default CutsomThemeProvider;
