import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import UsersList from '../../../../components/Users/List';

import { selectAdminUsersInitQuery, AdminUser } from './queries';

const ListOrganisationUsers = (): React.ReactElement => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<AdminUser[]>([]);

  const { data: adminUserInitQuery } = useQuery(selectAdminUsersInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && adminUserInitQuery) {
      const { users_admin } = adminUserInitQuery;
      setUsers(users_admin);
    }
    return () => { mounted = false; };
  }, [adminUserInitQuery]);

  const handleCreate = () => {
    navigate(`/admin/users/create`);
  };

  const handleView = (id: string) => {
    navigate(`/admin/users/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/users/${id}/edit`);
  };

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Admin', },
        { label: 'Users' },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UsersList
            users={users.map((i) => ({ id: i.id, full_name: i.full_name, email: i.email }))}
            userType="Admin"
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleView={handleView}
          />
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ListOrganisationUsers;
