
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editLabInitQuery = (id: string) => ({
  labs_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      address_line_1: types.string,
      address_line_2: types.string,
      address_city: types.string,
      address_postcode: types.string,
      contact: types.string,
      organisation_assignments: {
        id: types.string,
        organisation: {
          id: types.string,
          name: types.string,
        },
      },
    },
  ),
  organisations: [{
    id: types.string,
    name: types.string,
  }],
});

type X = Unpacked<typeof _editLabInitQuery>
export type Lab = X['labs_by_pk'];
export type OrganisationAssignment = Lab['organisation_assignments'];
export type Organisation = X['organisations'][0];

export const editLabInitQuery = (id: string) => gql`${query(_editLabInitQuery(id))}`;
