import { gql } from '@apollo/client';
import gqlClient from '../../../../../../utils/apolloClient';

const updateSurgicalPathwayMutation = gql`
  mutation ($pk_columns: surgical_pathways_pk_columns_input!, $set: surgical_pathways_set_input!) {
    update_surgical_pathways_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

const updateSurgicalPathwayStepsMutation = gql`
  mutation ($objects: [surgical_pathway_steps_insert_input!]!, $remove: [uuid!]!) {
    insert_surgical_pathway_steps( objects: $objects, on_conflict: { constraint: surgical_pathway_steps_pkey, update_columns: [order_index, component_type, name, description, mandatory, options] }) {
      returning {
        id
      }
    }
    delete_surgical_pathway_steps(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const editSurgicalPathway = async (variables: object) => {
  const res =  await gqlClient.mutate<{
    update_surgical_pathways_by_pk: {
      id: string,
    }
  }>({ mutation: updateSurgicalPathwayMutation!, variables });
  return res.data?.update_surgical_pathways_by_pk.id;
}


export const editSurgicalPathwaySteps = async (variables: object) => {
  return await gqlClient.mutate<{
    update_surgical_pathway_steps_by_pk: {
      returning: {
        id: string,
      }
    }
  }>({ mutation: updateSurgicalPathwayStepsMutation!, variables });
}
