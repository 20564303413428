import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertCaseMutation = gql`
  mutation ($object: cases_insert_input!) {
    insert_cases_one(object: $object) {
      id
    }
  }
`;

export const createCase = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_cases_one: {
      id: string,
    }
  }>({ mutation: insertCaseMutation!, variables });
  return res.data?.insert_cases_one.id;
}


const insertCaseEventMutation = gql`
  mutation ($object: case_events_insert_input!) {
    insert_case_events_one(object: $object) {
      id
    }
  }
`;

export const createCaseEvent = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_case_events_one: {
      id: string,
    }
  }>({ mutation: insertCaseEventMutation!, variables });
  return res.data?.insert_case_events_one.id;
}


const updateCaseEventMutation = gql`
  mutation ($pk_columns: case_events_pk_columns_input!, $set: case_events_set_input) {
    update_case_events_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateCaseEvent = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    update_case_events_by_pk: {
      id: string,
    }
  }>({ mutation: updateCaseEventMutation!, variables });
  return res.data?.update_case_events_by_pk.id;
}


const insertPotPathwayVersion = gql`
  mutation ($object: pot_pathway_versions_insert_input!) {
    insert_pot_pathway_versions_one(object: $object) {
      id
    }
  }
`;

export const createPotPathwayVersion = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_pot_pathway_versions_one: {
      id: string,
    }
  }>({ mutation: insertPotPathwayVersion!, variables });
  return res.data?.insert_pot_pathway_versions_one.id || '';
}
