import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';

import GeneralLayout from '../../../../layouts/General';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { labReducer} from '../Common';
import Steps from '../Common/components/Steps';

import { createLabInitQuery, Organisation } from './queries';
import { createLab, createOrganisationLabAssignments } from './mutations';
import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const CreateLab = ({ classes }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const [lab, dispatchLab] = useReducer(labReducer, {
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_postcode: '',
    contact: '',
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [assignedOrganisations, setAssignedOrganisations] = useState<Organisation[]>([]);

  const { data: createOrganisationInitData } = useQuery(createLabInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createOrganisationInitData) {
      setOrganisations(createOrganisationInitData.organisations);
    }
    return () => { mounted = false; };
  }, [createOrganisationInitData]);

  const handleSave = async () => {
    setSaving(true);

    const insertLabVariables = {
      object: lab,
    };
    const lab_id: string | undefined = await createLab(insertLabVariables);

    if (lab_id) {
      if (assignedOrganisations.length > 0) {
        const organisationLabAssignments = assignedOrganisations.map((org: Organisation) => ({ organisation_id: org.id, lab_id }));
        await createOrganisationLabAssignments(organisationLabAssignments);
      }

      setSaving(false);
      handleFinish(lab_id);
    }
  };

  const handleFinish = (id: string) => {
    navigate(`/admin/labs/${id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Labs', link: '/admin/labs' },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          lab={lab}
          assignedOrganisations={assignedOrganisations}
          organisations={organisations.map((i: Organisation) => ({ id: i.id, label: i.name }))}
          dispatch={dispatchLab}
          setAssignedOrganisations={setAssignedOrganisations}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Creating Lab" />}
    </>
  );
};

export default withStyles(styles)(CreateLab);
