import React from 'react';

import {
  WithStyles,
  withStyles,
} from '@mui/styles';
import { EmptyObject } from '../../react-app-env';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  center?: boolean
}

const ButtonContainer = ({ classes, children, center = false }: Props): React.ReactElement => (
  <div className={`${classes.root} ${center ? classes.center : ''}`}>{children}</div>
);

export default withStyles(styles)(ButtonContainer);
