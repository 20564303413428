import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { stringFromArray } from '../../../../../utils/stringUtils';
import { UnknownObject } from '../../../../../react-app-env';
import { useNavigate } from 'react-router';

import GeneralLayout from '../../../.././../layouts/General';
import CustomBackdrop from '../../../.././../components/CustomBackdrop';
import { receiverUserReducer, ReceiverUserReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { editLabUserInitQuery, EnumReceiverType, Lab, LabAssignment } from './queries';
import { updateLabUser, updateUserLabAssignments } from './mutations';



const EditLabUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const { lab_id, lab_user_id } = useParams();

  const [user, dispatchUser] = useReducer(receiverUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    receiver_type: '',
  });

  const [lab, setLab] = useState<Lab | UnknownObject>({});
  const [labs, setLabs] = useState<Lab[]>([]);
  const [assignedLabs, setAssignedLabs] = useState<Lab[]>([]);
  const [assignedOriginalLabs, setAssignedOriginalLabs] = useState<LabAssignment[]>([]);
  const [userTypes, setUserTypes] = useState<EnumReceiverType[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editReceiverUserInitQueryData } = useQuery(editLabUserInitQuery(lab_id!, lab_user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editReceiverUserInitQueryData) {
      dispatchUser({ type: ReceiverUserReducerAction.INIT, value: editReceiverUserInitQueryData.users_receiver_by_pk });
      setLab(editReceiverUserInitQueryData.labs_by_pk);
      setLabs(editReceiverUserInitQueryData.labs);
      setAssignedLabs(editReceiverUserInitQueryData.users_receiver_by_pk.labs.map((i: LabAssignment) => ({ id: i.lab.id, name: i.lab.name })));
      setUserTypes(editReceiverUserInitQueryData.enum_receiver_types);
      setAssignedOriginalLabs(editReceiverUserInitQueryData.users_receiver_by_pk.labs);
    }
    return () => { mounted = false; };
  }, [editReceiverUserInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: lab_user_id,
      },
      set: user,
    };
    await updateLabUser(updateVariables);

    const labAssignments = assignedLabs.filter((lab: Lab) => !assignedOriginalLabs.some((i: LabAssignment) => lab.id === i.lab.id)).map((lab: Lab) => ({ user_id: lab_user_id, lab_id: lab.id }));
    const labAssignmentsRemove = assignedOriginalLabs.filter((i: LabAssignment) => !assignedLabs.some((lab: Lab) => lab.id === i.lab.id)).map((i: LabAssignment) => i.id);

    await updateUserLabAssignments(labAssignments, labAssignmentsRemove);

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/labs/${lab_id}/users/${lab_user_id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Labs', link: '/admin/labs' },
          { label: lab.name, link: `/admin/labs/${lab_id}` },
          { label: 'Users', link: `/admin/labs/${lab_id}/users` },
          { label: stringFromArray([user.first_name, user.last_name]), link: `/admin/labs/users/${lab_user_id}` },
          { label: 'Edit' },
        ]}>
        <Steps
          completeLabel="Save"
          user={user}
          userTypes={userTypes}
          labs={labs.map((i: Lab) => ({ id: i.id, label: i.name }))}
          assignedLabs={assignedLabs.map((i: Lab) => ({ id: i.id, label: i.name }))}
          setAssignedLabs={setAssignedLabs}
          dispatch={dispatchUser}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default EditLabUser;
