import { Routes, Route, useNavigate } from 'react-router-dom';

// Admin
import {
  Home as AdminHome,

  LimsWait as AdminLimsWait,
  LimsNewCase as AdminLimsNewCase,
  LimsCaseData as AdminLimsCaseData,

  AdminUsers as AdminListUsers,
  CreateAdminUser as AdminCreateUser,
  ViewAdminUser as AdminViewUser,
  EditAdminUser as AdminEditUser,

  Labs as AdminLabs,
  CreateLab as AdminCreateLab,
  ViewLab as AdminViewLab,
  EditLab as AdminEditLab,

  Organisations as AdminOrganisations,
  CreateOrganisation as AdminCreateOrganisation,
  ViewOrganisation as AdminViewOrganisation,
  EditOrganisation as AdminEditOrganisation,

  OrganisationUsers as AdminListOrganisationUser,
  CreateOrganisationUser as AdminCreateOrganisationUser,
  ViewOrganisationUser as AdminViewOrganisationUser,
  EditOrganisationUser as AdminEditOrganisationUser,

  LabUsers as AdminListLabUsers,
  CreateLabUser as AdminCreateLabUser,
  ViewLabUser as AdminViewLabUser,
  EditLabUser as AdminEditLabUser,

  Specialities as AdminSpecialities,
  CreateSpeciality as AdminCreateSpeciality,
  ViewSpeciality as AdminViewSpeciality,
  EditSpeciality as AdminEditSpeciality,

  // Categories as AdminCategories,
  CreateCategory as AdminCreateCategory,
  ViewCategory as AdminViewCategory,
  EditCategory as AdminEditCategory,

  CreateSurgicalPathway as AdminCreateSurgicalPathway,
  ViewSurgicalPathway as AdminViewSurgicalPathway,
  EditSurgicalPathway as AdminEditSurgicalPathway,

  CreatePotPathway as AdminCreatePotPathway,
  ViewPotPathway as AdminViewPotPathway,
  EditPotPathway as AdminEditPotPathway,

} from './views/Admin';


// Organisation
import {
  Home as OrgHome,
  CreateCase as OrgCreateCase,
  Cases as OrgCases,
  ViewCase as OrgViewCase,
  SearchCases as OrgSearchCases,

} from './views/Organisation';


// Lab
import {
  Home as LabHome,
  Cases as LabCases,
  ViewCase as LabViewCase,
  SearchCases as LabSearchCases,
  CheckInCases as LabCheckInCases,

} from './views/Lab';

// Public
import {
  SignIn,
  InitialSignIn,
  Splash,
} from './views/Public';
import { NotAuthorised, NotFound, ProtectedRoleRoute } from '@blumtechgroup/blum-react-core-components';

import { adminRoles, senderRoles, receiverRoles, AuthContext } from './components/AuthProvider';
import { useContext } from 'react';

const AppRoutes = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }

  const { userRole } = authContext;
  return <Routes>

    {/* ---------- public ---------- */}
    <Route path='/sign-in' element={<SignIn />} />
    <Route path='/initial-sign-in' element={<InitialSignIn />} />
    <Route  path="/" element={<Splash/>} />


    {/* ---------- admin ---------- */}
    <Route
      element={
        <ProtectedRoleRoute
          fallbackComponent={<NotAuthorised goBack={navigate} />}
          allowedRoles={adminRoles}
          userRole={userRole} />
      }
    >
      <Route path='/admin/home' element={<AdminHome />} />
      <Route path='/admin/lims' element={<AdminLimsWait />} />
      <Route path='/admin/lims/new-case' element={<AdminLimsNewCase />} />
      <Route path='/admin/lims/case-data' element={<AdminLimsCaseData />} />
      {/* Admin Users */}
      <Route path='/admin/users' element={<AdminListUsers />} />
      <Route path='/admin/users/create' element={<AdminCreateUser />} />
      <Route path='/admin/users/:admin_user_id' element={<AdminViewUser />} />
      <Route path='/admin/users/:admin_user_id/edit' element={<AdminEditUser />} />
      {/* Labs */}
      <Route path='/admin/labs' element={<AdminLabs />} />
      <Route path='/admin/labs/create' element={<AdminCreateLab />} />
      <Route path='/admin/labs/:lab_id' element={<AdminViewLab />} />
      <Route path='/admin/labs/:lab_id/edit' element={<AdminEditLab />} />
      {/* Lab Users */}
      <Route path='/admin/labs/:lab_id/users' element={<AdminListLabUsers />} />
      <Route path='/admin/labs/:lab_id/users/create' element={<AdminCreateLabUser />} />
      <Route path='/admin/labs/:lab_id/users/:lab_user_id' element={<AdminViewLabUser />} />
      <Route path='/admin/labs/:lab_id/users/:lab_user_id/edit' element={<AdminEditLabUser />} />
      {/* Organisations */}
      <Route path='/admin/organisations' element={<AdminOrganisations />} />
      <Route path='/admin/organisations/create' element={<AdminCreateOrganisation />} />
      <Route path='/admin/organisations/:organisation_id' element={<AdminViewOrganisation />} />
      <Route path='/admin/organisations/:organisation_id/edit' element={<AdminEditOrganisation />} />
      {/* Organisation Users */}
      <Route path='/admin/organisations/:organisation_id/users' element={<AdminListOrganisationUser />} />
      <Route path='/admin/organisations/:organisation_id/users/create' element={<AdminCreateOrganisationUser />} />
      <Route path='/admin/organisations/:organisation_id/users/:organisation_user_id'
             element={<AdminViewOrganisationUser />} />
      <Route path='/admin/organisations/:organisation_id/users/:organisation_user_id/edit'
             element={<AdminEditOrganisationUser />} />
      {/* Specialities and Categories */}
      <Route path='/admin/specialities' element={<AdminSpecialities />} />
      <Route path='/admin/specialities/create' element={<AdminCreateSpeciality />} />
      <Route path='/admin/specialities/:speciality_id' element={<AdminViewSpeciality />} />
      <Route path='/admin/specialities/:speciality_id/edit' element={<AdminEditSpeciality />} />

      <Route  path="/admin/specialities/:speciality_id/create" element={<AdminCreateCategory/>} />
      <Route  path="/admin/specialities/:speciality_id/:category_id" element={<AdminViewCategory/>} />
      <Route  path="/admin/specialities/:speciality_id/:category_id/edit" element={<AdminEditCategory/>} />

      {/* Pathways */}
      <Route path='/admin/specialities/:speciality_id/:category_id/surgical_pathway/create'
             element={<AdminCreateSurgicalPathway />} />
      <Route path='/admin/specialities/:speciality_id/:category_id/surgical_pathway/:surgical_pathway_id'
             element={<AdminViewSurgicalPathway />} />
      <Route
        path='/admin/specialities/:speciality_id/:category_id/surgical_pathway/:surgical_pathway_id/edit'
        element={<AdminEditSurgicalPathway />} />
      <Route path='/admin/specialities/:speciality_id/:category_id/pot_pathway/create'
             element={<AdminCreatePotPathway />} />
      <Route path='/admin/specialities/:speciality_id/:category_id/pot_pathway/:pot_pathway_id'
             element={<AdminViewPotPathway />} />
      <Route path='/admin/specialities/:speciality_id/:category_id/pot_pathway/:pot_pathway_id/edit'
             element={<AdminEditPotPathway />} />
    </Route>

    {/* --------------------------------- Organisation --------------------------------- */}
    <Route
      element={
        <ProtectedRoleRoute
          fallbackComponent={<NotAuthorised goBack={navigate} />}
          allowedRoles={senderRoles}
          userRole={userRole}
        />
      }
    >
      <Route path='/org/home' element={<OrgHome />} />
      <Route path='/org/cases' element={<OrgCases />} />
      <Route path='/org/cases/create' element={<OrgCreateCase />} />
      <Route path='/org/cases/search' element={<OrgSearchCases classes={{}} />} />
      <Route path='/org/cases/:case_id' element={<OrgViewCase classes={{}} />} />
    </Route>

    {/* --------------------------------- Lab --------------------------------- */}
    <Route
      element={
        <ProtectedRoleRoute
          fallbackComponent={<NotAuthorised goBack={navigate} />}
          allowedRoles={receiverRoles}
          userRole={userRole}
        />
      }
    >
      <Route path='/lab/home' element={<LabHome />} />
      <Route path='/lab/cases' element={<LabCases />} />
      <Route path='/lab/cases/search' element={<LabSearchCases />} />
      <Route path='/lab/cases/check-in' element={<LabCheckInCases />} />
      <Route path='/lab/cases/:case_id' element={<LabViewCase />} />
    </Route>


    {/* --------------------------------- Not Authorised & Not Found --------------------------------- */}
    {/*<Route path="/not-authorised" element={<NotAuthorised />} />*/}
    <Route path='*' element={<NotFound goBack={navigate} />} />

  </Routes>;
};

export default AppRoutes;
