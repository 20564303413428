import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { stringFromArray } from '../../../../../utils/stringUtils';
import { UnknownObject } from '../../../../../react-app-env';

import GeneralLayout from '../../../.././../layouts/General';
import CustomBackdrop from '../../../.././../components/CustomBackdrop';
import { senderUserReducer, SenderUserReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { useNavigate } from 'react-router';
import { editSenderUserInitQuery, Organisation, EnumSenderType, OrganisationAssignment } from './queries';
import { updateSenderUser, updateUserOrganisationAssignments } from './mutations';


const EditOrganisationUser = (): React.ReactElement => {
  const navigate = useNavigate();
  const { organisation_id, organisation_user_id } = useParams();

  const [user, dispatchUser] = useReducer(senderUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    sender_type: '',
  });

  const [organisation, setOrganisation] = useState<Organisation | UnknownObject>({});
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [assignedOrganisations, setAssignedOrganisations] = useState<Organisation[]>([]);
  const [assignedOriginalOrganisations, setAssignedOriginalOrganisations] = useState<OrganisationAssignment[]>([]);
  const [userTypes, setUserTypes] = useState<EnumSenderType[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editOrganisationUserInitQueryData } = useQuery(editSenderUserInitQuery(organisation_id!, organisation_user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editOrganisationUserInitQueryData) {
      dispatchUser({ type: SenderUserReducerAction.INIT, value: editOrganisationUserInitQueryData.users_sender_by_pk });
      setOrganisation(editOrganisationUserInitQueryData.organisations_by_pk);
      setOrganisations(editOrganisationUserInitQueryData.organisations);
      setAssignedOrganisations(editOrganisationUserInitQueryData.users_sender_by_pk.organisations.map((i: OrganisationAssignment) => ({ id: i.organisation.id, name: i.organisation.name })));
      setAssignedOriginalOrganisations(editOrganisationUserInitQueryData.users_sender_by_pk.organisations);
      setUserTypes(editOrganisationUserInitQueryData.enum_sender_types);
    }
    return () => { mounted = false; };
  }, [editOrganisationUserInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: organisation_user_id,
      },
      set: user,
    };
    await updateSenderUser(updateVariables);

    const organisationAssignments = assignedOrganisations.filter((org: Organisation) => !assignedOriginalOrganisations.some((i: OrganisationAssignment) => org.id === i.organisation.id)).map((org: Organisation) => ({ user_id: organisation_user_id, organisation_id: org.id }));
    const organisationAssignmentsRemove = assignedOriginalOrganisations.filter((i: OrganisationAssignment) => !assignedOrganisations.some((organisation: Organisation) => organisation.id === i.organisation.id)).map((i: OrganisationAssignment) => i.id);

    await updateUserOrganisationAssignments(organisationAssignments, organisationAssignmentsRemove);



    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/organisations/${organisation_id}/users/${organisation_user_id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Users' },
          { label: stringFromArray([user.first_name, user.last_name]), link: `/admin/organisations/${organisation_id}/users/${organisation_user_id}` },
          { label: 'Edit' },
        ]}>
        <Steps
          completeLabel="Save"
          user={user}
          userTypes={userTypes}
          organisations={organisations.map((i: Organisation) => ({ id: i.id, label: i.name }))}
          assignedOrganisations={assignedOrganisations.map((i: Organisation) => ({ id: i.id, label: i.name }))}
          setAssignedOrganisations={setAssignedOrganisations}
          dispatch={dispatchUser}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default EditOrganisationUser;
