import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Speciality } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  speciality: Speciality,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, speciality, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Category name', value: speciality.name },
          { label: 'Description', value: speciality.description }
        ]}
        handleEdit={() => setStep(0)} />
      <ReadOnlyBlock
        items={[
          { label: 'Icon', icon: speciality.icon ? `data:image/svg+xml;base64,${btoa(speciality.icon)}` : undefined, value: !speciality.icon ? 'No Icon' : undefined }
        ]}
        handleEdit={() => setStep(1)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
