import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(4),
    '&:only-child': {
      marginBottom: 0,
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 160,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  options: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  optionsHeader: {
    marginBottom: theme.spacing(2),
  },
  remove: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignSelf: 'flex-start',
  },
  switch: {
    marginTop: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
  },
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
});

export default styles;
