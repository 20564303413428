import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:not($noHover):not($selected):hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  noHover: {
    cursor: 'default',
  },
  small: {
    borderRadius: theme.spacing(1),
    '& > $icon': {
      padding: theme.spacing(1),
      width: 60,
      height: 60,
      borderRadius: theme.spacing(1),
      '& > img': {
        width: 42,
        height: 42,
      },
    },
    '& $title': {
      fontSize: 16,
    },
    '& $description': {
      fontSize: 14,
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
  },
  icon: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 100,
    height: 100,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    '& > img': {
      filter: 'invert(1)',
      width: 70,
      height: 70,
    },
  },
  titleContainer: {
    width: '100%',
    position: 'relative',
  },
  status: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  title: {},
  subTitle: {
    fontSize: 15,
    color: theme.palette.primary.main,
  },
  description: {},
});

export default styles;
