const spacing = 8;
const light = {
  background: {
    paper: '#FFFFFF',
    default: '#f4f4f4',
  },
  text: {
    primary: '#000000DE',
    secondary: '#000000D2',
  }
}
const dark = {
  background: {
    paper: '#222b36',
    default: '#171c24',
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFFB3',
  }
}

const theme = (prefersDarkMode: boolean) => ({
  spacing,
  palette: {
    mode: prefersDarkMode ? 'dark' : 'light',
    primary: {
      main: '#E39190',
      light: '#E3929170',
      contrastText: '#FFFFFF',
    },
    primaryVariant: {
      main: '#E3929170',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#C1709F',
      contrastText: '#FFFFFF',
    },
    secondaryVariant: {
      main: '#E65100',
      contrastText: '#FFFFFF',
    },
    neutral: {
      main: '#BDBDBD',
      contrastText: '#FFFFFF',
    },
    surface: {
      main: '#FFFFFF',
      contrastText: '#1F384D',
    },
    listItem: {
      main: '#1F384D1F',
      contrastText: '#E39190',
    },
    text: {
      primary: prefersDarkMode ? dark.text.primary : light.text.primary,
      secondary: prefersDarkMode ? dark.text.secondary : light.text.secondary,
      disabled: '#B4B4B4D2',
    },
    background: {
      paper: prefersDarkMode ? dark.background.paper : light.background.paper,
      default: prefersDarkMode ? dark.background.default : light.background.default,
    }
  },
  props: {
    MuiPaper: {
      elevation: 0,
      style: {
        borderRadius: spacing,
      },
    },
    MuiAppBar: {
      style: {
        borderRadius: 0,
      },
    },
    MuiGrid: {
      spacing: 4,
    },
    MuiTab: {
      style: {
        borderRadius: 0,
      },
    },
    MuiInputBase: {
      style: {
        borderRadius: spacing / 2,
      },
    },
    MuiButtonBase: {
      style: {
        borderRadius: spacing / 2,
      },
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiIconButton: {
      style: {
        borderRadius: '50%',
      },
    },
    MuiMenuItem: {
      style: {
        borderRadius: 0,
      },
    },
    MuiToggleButton: {
      style: {
        '&:not:(first-of-type):not(:last-of-type)': {
          borderRadius: 0,
        },
        '&:first-of-type': {
          borderTopLeftRadius: `${spacing}px !important`,
          borderBottomLeftRadius: `${spacing}px !important`,
        },
        '&:last-of-type': {
          borderTopRightRadius: `${spacing}px !important`,
          borderBottomRightRadius: `${spacing}px !important`,
        },
      },
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      }
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          caretColor: 'none !important',
          '-webkit-box-shadow': `0 0 0 100px ${prefersDarkMode ? dark.background.paper : light.background.paper} inset !important`,
          '-webkit-text-fill-color': `${prefersDarkMode ? '#FFFFFF' : '#000000DE'} !important`,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, Nunito, Arial',
    h1: {
      fontFamily: 'Roboto',
      fontSize: 98,
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: -0.015625,
    },
    h2: {
      fontFamily: 'Roboto',
      fontSize: 62,
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: -0.008333334,
    },
    h3: {
      fontFamily: 'Roboto',
      fontSize: 50,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.0,
    },
    h4: {
      fontFamily: 'Roboto',
      fontSize: 36,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.007352941,
    },
    h5: {
      fontFamily: 'Roboto',
      fontSize: 26,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0.0,
    },
    h6: {
      fontFamily: 'Roboto',
      fontSize: 22,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0.0125,
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: 18,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.009375,
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: 15,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.007142857,
    },
    body1: {
      fontFamily: 'Nunito',
      fontSize: 18,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.03125,
    },
    body2: {
      fontFamily: 'Nunito',
      fontSize: 16,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.017857144,
    },
    button: {
      fontFamily: 'Roboto',
      fontSize: 15,
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: 0.08928572,
    },
    caption: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0.033333335,
    },
    overline: {
      fontName: 'Roboto',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0.16666667,
    },
  },
});

export default theme;
