import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AssignmentOutlined as CaseIcon,
  ColorizeOutlined as LabIcon,
  DoubleArrowOutlined as PathwayIcon,
  GroupAddOutlined as UsersIcon,
  HomeOutlined as HomeIcon,
  HomeWorkOutlined as OrganisationIcon,
  QueuePlayNextOutlined as LimsIcon,
} from '@mui/icons-material';
import React from 'react';


export function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} history={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const senderDefaultItems = [
  { label: 'Home', icon: HomeIcon, to: '/org/home' },
  { label: 'Cases', icon: CaseIcon, to: '/org/cases' },
];

const receiverDefaultItems = [
  { label: 'Home', icon: HomeIcon, to: '/lab/home' },
  { label: 'Cases', icon: CaseIcon, to: '/lab/cases' },
];

export const listItems = [
  {
    name: 'admin',
    payload: {
      key: 'admin',
      title: 'Admin',
      items: [
        { label: 'Home', icon: HomeIcon, to: '/admin/home' },
        { label: 'Organisations', icon: OrganisationIcon, to: '/admin/organisations' },
        { label: 'Labs', icon: LabIcon, to: '/admin/labs' },
        { label: 'Pathways', icon: PathwayIcon, to: '/admin/specialities' },
        { label: 'Users', icon: UsersIcon, to: '/admin/users' },
        { label: 'LIMS', icon: LimsIcon, to: '/admin/lims' },
      ],
    },
  },
  {
    name: 'sender_admin',
    payload: {
      key: 'sender admin',
      title: 'Sender Admin',
      items: [...senderDefaultItems]
    },
  },
  {
    name: 'sender_lead',
    payload: {
      key: 'sender lead',
      title: 'Sender Lead',
      items: [...senderDefaultItems]
    },
  },
  {
    name: 'sender_member',
    payload: {
      key: 'sender member',
      title: 'Sender Member',
      items: [...senderDefaultItems]
    },
  },
  {
    name: 'sender_readonly',
    payload: {
      key: 'sender readonly',
      title: 'Sender ReadOnly',
      items: [...senderDefaultItems]
    },
  },
  {
    name: 'receiver_admin',
    payload: {
      key: 'receiver admin',
      title: 'Receiver ReadOnly',
      items: [...receiverDefaultItems]
    },
  },
  {
    name: 'receiver_lead',
    payload: {
      key: 'receiver lead',
      title: 'Receiver Lead',
      items: [...receiverDefaultItems]
    },
  },
  {
    name: 'receiver_member',
    payload: {
      key: 'receiver member',
      title: 'Receiver Member',
      items: [...receiverDefaultItems]
    },
  },
  {
    name: 'receiver_readonly',
    payload: {
      key: 'receiver readonly',
      title: 'Receiver ReadOnly',
      items: [...receiverDefaultItems]
    },
  },
]
