import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _createLabUserInitQuery = (id: string) => ({
  labs_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    },
  ),
  labs: params(
    { order_by: { name: 'asc' }},
    [{
      id: types.string,
      name: types.string,
    }],
  ),
  enum_receiver_types: [{
  	receiver_type: types.string,
    description: types.string,
  }],
});

type X = Unpacked<typeof _createLabUserInitQuery>
export type Lab = X['labs'][0];
export type EnumReceiverType = X['enum_receiver_types'][0];

export const createLabUserInitQuery = (id: string) => gql`${query(_createLabUserInitQuery(id))}`;
