import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import { Grid, Typography } from '@mui/material';

import { ReceiverUser, SenderUser, User } from '../../../../../../react-app-env';
import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import Pathway from '../../../../../../components/PathwayBuilder/Common';
import SpecialityCard from '../../../../../../components/SpecialityCard';

import { Patient } from '../../patient';
import { Pot } from '../../pots';
import { Speciality } from '../../speciality';
import { SpecialityPathway } from '../../speciality_pathway';
import { Organisation } from '../../organisation';
import { Lab } from '../../lab';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  patient: Patient;
  speciality: Speciality;
  specialityPathway: Pathway;
  specialityPathwayValues: SpecialityPathway;
  accessionNumber?: string;
  pots: Pot[];
  potPathway: Pathway;
  organisation?: Organisation;
  lab?: Lab;
  user: User | ReceiverUser | SenderUser | null;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({
  classes,
  patient,
  speciality,
  specialityPathway,
  specialityPathwayValues,
  accessionNumber,
  pots,
  potPathway,
  organisation,
  lab,
  user,
  setStep,
}: Props): React.ReactElement => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ReadOnlyBlock
                fill
                title="Patient Details"
                items={[
                  {
                    label: 'Name',
                    value: `${patient.first_name} ${patient.last_name}`,
                  },
                  { label: 'NHS number', value: patient.nhs_number },
                  { label: 'Medical Record Number', value: patient.mrn },
                  {
                    label: 'Date of Birth',
                    value: patient.dob?.toLocaleDateString(),
                  },
                ]}
                handleEdit={() => setStep(0)}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyBlock
                fill
                title="Sender Details"
                items={[
                  { label: 'Organisation', value: organisation?.name },
                  { label: 'Lab', value: lab?.name },
                  { label: 'Requester', value: user?.full_name },
                  { label: 'Accession Number', value: accessionNumber || '' },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ReadOnlyBlock
                fill
                title="Clinical Details"
                components={[
                  <div className={classes.reviewDetailsContainer}>
                    <SpecialityCard small divider gridSize={12} title={speciality.name} description={speciality.category?.name} icon={speciality.icon} />
                    <div className={classes.specialityDetails}>
                      <Grid container spacing={2}>
                        {specialityPathway.steps.map((step) => {
                          const valueId = specialityPathwayValues.pathway[step.id];
                          return (
                            <Grid key={step.id} item xs={6}>
                              <div className={classes.specialityStep}>
                                <Typography variant="subtitle2" color="textSecondary">
                                  {step.name}
                                </Typography>
                                <Typography className={classes.stepValue} variant="body1">
                                  {step.options.find((o) => o.id === valueId)?.label}
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </div>,
                ]}
                handleEdit={() => setStep(2)}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyBlock
                fill
                title="Pot Details"
                components={[
                  <div className={classes.reviewDetailsContainer}>
                    {pots.map((pot, index) => (
                      <div className={classes.potContainer}>
                        <Typography variant="subtitle1" color="textSecondary">{`Pot ${index + 1}`}</Typography>
                        <Typography className={classes.stepValue} variant="body1">
                          {potPathway.steps
                            .map((step) => {
                              const cStep = pot.pathway[step.id];
                              if (cStep) {
                                return step.options.find((o) => o.id === cStep)?.label;
                              }
                              return null;
                            })
                            .filter((i) => i)
                            .join(', ')}
                        </Typography>
                      </div>
                    ))}
                  </div>,
                ]}
                handleEdit={() => setStep(3)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(StepReview);
