import React, { useCallback, useReducer, useState } from 'react';
import { WithStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../react-app-env';
import { getKeyValue } from '../../../../utils/stringUtils';
import { useNavigate } from 'react-router';

import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import SortableTable, { SortableTableHeader } from '../../../../components/SortableTable';

import { casesSearchQuery, Case } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

interface CaseSearch {
  nhs_number: string,
  mrn: string,
  accession_number: string,
}

enum CaseSearchReducerAction {
  NHS_NUMBER,
  MRN,
  ACCESSION_NUMBER,
}

interface ValidationErrors {
  nhs_number: string | boolean,
  mrn: string | boolean,
  incomplete: boolean,
  no_cases: boolean,
}

const validationErrors = {
  incomplete: 'At lease one field is required',
  no_cases: 'No cases found for search criteria',
  nhs_number: 'Please provide an NHS number',
  nhs_number_length: 'NHS number must be 10 characters long',
  mrn: 'Please provide an MRN',
  mrn_length: 'MRN must be no more than 20 characters long',
}

interface Validation {
  nhs_number: (value: string) => string | boolean,
  mrn: (value: string) => string | boolean,
}

const validation: Validation = {
  nhs_number: (value) => {
    if (value.length < 10 || value.length > 10) {
      return validationErrors.nhs_number_length;
    }
    return false;
  },
  mrn: (value) => {
    if (value.length > 20) {
      return validationErrors.mrn_length;
    }
    return false;
  },
}

const caseSearchReducer = (state: CaseSearch, action: { type: CaseSearchReducerAction, value: any }): CaseSearch =>  {
  switch (action.type) {
    case CaseSearchReducerAction.NHS_NUMBER:
      return { ...state, nhs_number: `${action.value.replace(/[\D]/g, '')}` };
    case CaseSearchReducerAction.MRN:
      return { ...state, mrn: `${action.value.replace(/[^A-Za-z0-9]/g, '')}` };
    case CaseSearchReducerAction.ACCESSION_NUMBER:
      return { ...state, accession_number: action.value };
    default:
      throw new Error();
  }
}

const SearchCases = ({ classes  }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const [cases, setCases] = useState<Case[]>([]);
  const [errors, setErrors] = useState<ValidationErrors>({
    nhs_number: false,
    mrn: false,
    incomplete: true,
    no_cases: false,
  });

  const [caseSearch, dispatch] = useReducer(caseSearchReducer, {
    nhs_number: '',
    mrn: '',
    accession_number: '',
  });

  const handleSearch = useCallback(async () => {
    const where: UnknownObject[] = [];
    if (caseSearch.nhs_number) {
      where.push({
        field: 'patient.nhs_id',
        value: caseSearch.nhs_number,
      });
    }
    if (caseSearch.mrn) {
      where.push({
        field: 'mrn',
        value: caseSearch.mrn,
      });
    }
    if (caseSearch.accession_number) {
      where.push({
        field: 'accession_id',
        value: caseSearch.accession_number,
      });
    }
    const c = await casesSearchQuery(where);
    setCases(c);
    if (c.length < 1) {
      setErrors((i) => ({ ...i, no_cases: true }));
    }
  }, [caseSearch]);

  const validate = useCallback((field: string, key?: string) => {
    const errs: ValidationErrors = {
      no_cases: false,
      incomplete: !caseSearch.nhs_number && !caseSearch.mrn && !caseSearch.accession_number,
      nhs_number: false,
      mrn: false,
    }
    if (field === 'nhs_number') {
      errs.nhs_number = validation.nhs_number(caseSearch.nhs_number);
    }
    if (field === 'mrn') {
      errs.mrn = validation.mrn(caseSearch.mrn);
    }
    errs.incomplete = errs.incomplete || errs.nhs_number as boolean || errs.mrn as boolean;
    if (key && key === 'Enter' && Object.values(errs).every((e) => e === false)) {
      handleSearch();
    }
    setErrors(errs);
  }, [caseSearch, setErrors, handleSearch]);

  const handleView = (id: string) => {
    navigate(`/org/cases/${id}`);
  };

  const tableHeaders: SortableTableHeader[] = [
    { key: 'accession_id', label: 'Accession Id' },
    { key: 'patient.nhs_id', label: 'NHS number' },
    { key: 'mrn', label: 'MRN' },
    { key: 'pots_aggregate.aggregate.count', label: 'Pots' },
    { key: 'lab.name', label: 'Lab' },
    { key: 'enum_case_priority.description', label: 'Priority' }
  ];

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Cases', link: '/org/cases' },
        { label: 'Search' },
      ]}>
      <div className={classes.header}>
        <Typography variant="h4">Search for a case</Typography>
      </div>

      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <div className={classes.textFieldGroup}>
                <TextField
                  className={classes.textField}
                  data-qa="nhs_number-textfield"
                  label="NHS number"
                  variant="outlined"
                  value={caseSearch.nhs_number}
                  error={errors.nhs_number as boolean}
                  helperText={errors.nhs_number}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CaseSearchReducerAction.NHS_NUMBER, value: event.target.value })}
                  onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => validate('nhs_number', event.key)}
                  onBlur={() => validate('nhs_number')} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.textFieldGroup}>
                <TextField
                  className={classes.textField}
                  data-qa="mrn-textfield"
                  label="Medical record number"
                  variant="outlined"
                  value={caseSearch.mrn}
                  error={errors.mrn as boolean}
                  helperText={errors.mrn}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CaseSearchReducerAction.MRN, value: event.target.value })}
                  onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => validate('mrn', event.key)}
                  onBlur={() => validate('mrn')} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.textFieldGroup}>
                <TextField
                  className={classes.textField}
                  data-qa="accession_number-textfield"
                  label="Accession number"
                  variant="outlined"
                  value={caseSearch.accession_number}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CaseSearchReducerAction.ACCESSION_NUMBER, value: event.target.value })}
                  onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => validate('accession_number', event.key)}
                  onBlur={() => validate('accession_number')} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.searchButtonContainer}>
                <Button disabled={errors.incomplete as boolean} size="large" variant="contained" color="primary" onClick={handleSearch}>Search</Button>
                {errors.incomplete && (
                  <FormHelperText className={classes.searchErrorText} error>{validationErrors.incomplete}</FormHelperText>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={12}>
                  {cases.length > 0 && (
                    <SortableTable
                      title={`Cases found - ${cases.length}`}
                      headers={tableHeaders}
                      rows={cases.map((c: Case) => ({
                        key: c.id,
                        onClick: () => handleView(c.id),
                        columns: tableHeaders.map((t) => ({
                          key: t.key,
                          label: getKeyValue(c, t.key),
                        })),
                      }))} />
                  )}
                  {errors.no_cases && (
                    <FormHelperText error>{validationErrors.no_cases}</FormHelperText>
                  )}
                </Grid>
              </Grid>
          </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default SearchCases;
