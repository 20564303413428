import React, { useState, useEffect } from 'react';

import {
  DropResult,
} from "react-beautiful-dnd";

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import {
  SaveOutlined,
  CancelOutlined,
} from '@mui/icons-material';

import {
  Grid
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import {
  GridActionCard,
  GridCard,
  SortableGrid,
 } from '../../../../components/Grid';
import EditGridIcon from '../../../../components/Icons/EditGridIcon';

import { specialitiesInitQuery, Speciality } from './queries';
import { editSpecialityOrder } from './mutations';


const Specialities = (): React.ReactElement => {
  const navigate = useNavigate();

  const [specialities, setSpecialities] = useState<Speciality[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [original, setOriginal] = useState<Speciality[]>([]);

  const { data: specialitiesInitData } = useQuery(specialitiesInitQuery);

  useEffect(() => {
    let mounted = true;
    if (mounted && specialitiesInitData) {
      setSpecialities(specialitiesInitData.specialities);
      setOriginal(specialitiesInitData.specialities);
    }
    return () => { mounted = false; };
  }, [specialitiesInitData]);

  const handleCreate = () => {
    navigate('/admin/specialities/create');
  };

  const handleView = (id: string) => {
    navigate(`/admin/specialities/${id}`);
  }

  const handleEdit = (id: string) => {
    navigate(`/admin/specialities/${id}/edit`);
  }

  const handleEditLayout = () => {
    setEditMode(true);
  }

  const handleSave = async () => {

    const variables = {
      objects: specialities.map((i, order_index) => ({
        id: i.id,
        name: i.name,
        description: i.description,
        order_index,
    }))};

    await editSpecialityOrder(variables);
    setOriginal([...specialities]);
    setEditMode(false);
  }

  const handleCancel = () => {
    setSpecialities([...original]);
    setEditMode(false);
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (destination && destination.index >= 0) {
      setSpecialities((items) => {
        const item = items[source.index];
        const spliced = items.filter((i) => i.id !== item.id);
        spliced.splice(destination.index, 0, item);
        return spliced;
      });
    }
  }

  return (
    <GeneralLayout currentPage="Specialities">
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {!editMode && (
              <>
                <GridActionCard
                  fill
                  gridSize={6}
                  label="Create speciality"
                  onClick={handleCreate} />
                <GridActionCard
                  fill
                  gridSize={6}
                  label="Edit ordering"
                  icon={<EditGridIcon color="primary" />}
                  onClick={handleEditLayout} />
              </>
            )}
            {editMode && (
              <>
                <GridActionCard
                  fill
                  gridSize={6}
                  label="Save"
                  icon={<SaveOutlined />}
                  onClick={handleSave} />
                <GridActionCard
                  fill
                  gridSize={6}
                  label="Cancel"
                  icon={<CancelOutlined />}
                  onClick={handleCancel} />
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SortableGrid
            isEdit={editMode}
            onDragEnd={onDragEnd}>
              {specialities.map((speciality: Speciality, index) => ({
                key: speciality.id,
                component:
                  <GridCard
                    fill
                    noGrid
                    gridSize={6}
                    key={speciality.id}
                    title={speciality.name}
                    description={speciality.description}
                    svg={speciality.icon ? `data:image/svg+xml;base64,${btoa(speciality.icon)}` : undefined}
                    counter={editMode ? index + 1 : speciality.categories_aggregate.aggregate.count}
                    counterLabel="Categories"
                    handleEdit={() => handleEdit(speciality.id)}
                    handleView={() => handleView(speciality.id)} />
              }))}
          </SortableGrid>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default Specialities;
