import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  Button,
  Grid,
  GridSize,
  Typography,
} from '@mui/material';

import {
  Edit as EditIcon,
  VisibilityOutlined as ViewIcon,
} from '@mui/icons-material';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  title?: string,
  fill?: boolean,
  center?: boolean,
  gridSize?: GridSize,
  items?: BlockItem[],
  components?: React.Component[] | any[],
  handleEdit?: () => void,
  handleView?: () => void,
}

type BlockItem = ReadOnlyBlockItem | null;

interface ReadOnlyBlockItem {
  label: string,
  hideLabel?: boolean,
  value?: string,
  image?: string,
  icon?: string,
  components?: React.Component[] | any[],
  list?: string[],
}

const ReadOnlyBlock = ({ classes, title, fill = false, center = false, gridSize = 12, items = [], components = [], handleEdit, handleView }: Props): React.ReactElement => {

  return (
    <div className={`${classes.root} ${fill ? classes.fill : ''} ${center ? classes.center : ''}`}>
      {title && (
        <Typography className={classes.label} variant="h5">{title}</Typography>
      )}
    {/*HERE */}
    {/* @ts-ignore*/}
      <div className={classes.content}>
        <Grid container spacing={4}>
          {items.filter((i) => i !== null).map((i) => i as ReadOnlyBlockItem).map((i: ReadOnlyBlockItem) => (
            <Grid key={i.label} item xs={gridSize}>
              {/* @ts-ignore*/}
              <div className={classes.item} key={i.label}>
                {!i.hideLabel && (
                  <Typography className={classes.label} variant="subtitle2">{i.label}:</Typography>
                )}
                {i.value && (
                  <Typography className={classes.value} variant="body1">{i.value}</Typography>
                )}
                {i.image && (
                  <img className={classes.image} src={i.image} alt={i.label} />
                )}
                {i.icon && (
                  <img className={classes.icon} src={i.icon} alt={i.label} />
                )}
                {i.list && (
                  <div className={classes.list}>
                    {i.list.map((i) => <div key={i}>{i}</div>)}
                  </div>
                )}
                {i.components}
              </div>
            </Grid>
          ))}
        </Grid>
        {components}
      </div>
      {(handleEdit || handleView) && (
        <div className={classes.actions}>
          {handleView && (
            <Button variant="outlined" color="primary" startIcon={<ViewIcon />} onClick={handleView}>View</Button>
          )}
          {handleEdit && (
            <Button variant="outlined" color="primary" startIcon={<EditIcon />} onClick={handleEdit}>Edit</Button>
          )}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ReadOnlyBlock);
