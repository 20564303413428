import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../../react-app-env';

import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import GeneralLayout from '../../../../../../layouts/General';
import PathwayView from '../../../../../../components/PathwayBuilder/View';
import { pathwayReducer, PathwayReducerAction, pathwayValueReducer } from '../../../../../../components/PathwayBuilder/Common';

import { selectPotPathwayInitQuery, Category, Speciality } from './queries';


const ViewPotPathway = (): React.ReactElement => {
  const { speciality_id, category_id, pot_pathway_id } = useParams();

  const [speciality, setSpeciality] = useState<Speciality | UnknownObject>({});
  const [category, setCategory] = useState<Category | UnknownObject>({});

  const [pathway, dispatchPathway] = useReducer(pathwayReducer, {
    id: '',
    name: '',
    steps: [],
  });

  const [, dispatchPathwayValues] = useReducer(pathwayValueReducer, {});

  const { data: potPathwayInitData } = useQuery(selectPotPathwayInitQuery(speciality_id!, category_id!, pot_pathway_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && potPathwayInitData) {
      setSpeciality(potPathwayInitData.specialities_by_pk);
      setCategory(potPathwayInitData.speciality_categories_by_pk);
      dispatchPathway({ type: PathwayReducerAction.INIT, value: potPathwayInitData.pot_pathways_by_pk });
    }
    return () => { mounted = false; };
  }, [potPathwayInitData]);

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Specialities', link: '/admin/specialities' },
        { label: `${speciality?.name}`, link: `/admin/specialities/${speciality_id}` },
        { label: `${category?.name}`, link: `/admin/specialities/${speciality_id}/${category_id}` },
        { label: `Pot Pathway` },
      ]}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <PathwayView
            pathway={pathway}
            validate={() => {}}
            dispatch={dispatchPathwayValues} />
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ViewPotPathway;
