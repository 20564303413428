import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  TextField,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import { Organisation, OrganisationType, ValidationErrors, validationErrors, OrganisationReducerAction } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  organisation: Organisation,
  organisationTypes: OrganisationType[],
  errors: ValidationErrors,
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: OrganisationReducerAction, value: any }>,
}

const StepOrganisationType = ({ classes, organisation, organisationTypes, errors, setIsNew, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(() => {
    const errorItems: UnknownObject = {}
    errorItems.organisation_type = !organisation.organisation_type ? validationErrors.organisation_type : false;
    setErrors({...errors, ...errorItems});
  }, [organisation, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <Autocomplete
          className={classes.textField}
          id="organisationTypeList"
          options={organisationTypes}
          getOptionLabel={(i: OrganisationType) => i.description}
          value={organisationTypes.find((i) => i.organisation_type === organisation.organisation_type) || null}
          onBlur={() => validate()}
          onChange={(_: unknown, newValue: OrganisationType | null) => {
            const value = newValue ? newValue.organisation_type : '';
            dispatch({ type: OrganisationReducerAction.ORGANISATION_TYPE, value });
          }}
          renderInput={(params) =>
            <TextField
              data-qa="organisation-type-input"
              {...params}
              label="Organisation type"
              name="organisationType"
              error={errors.organisation_type as boolean}
              helperText={errors.organisation_type} />}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepOrganisationType);
