import React, { useState, useEffect } from 'react';
import {  useParams } from 'react-router-dom';

import {
  DropResult,
} from "react-beautiful-dnd";

import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import {
  SaveOutlined,
  CancelOutlined,
} from '@mui/icons-material';

import {
  Grid,
  Typography,
} from '@mui/material';

import GeneralLayout from '../../../../layouts/General';
import {
  GridActionCard,
  GridCard,
  SortableGrid,
 } from '../../../../components/Grid';
import EditGridIcon from '../../../../components/Icons/EditGridIcon';

import { categoriesInitQuery, Category, Speciality } from './queries';
import { editCategoriesOrder } from './mutations';

const ViewSpeciality = (): React.ReactElement => {
  const navigate = useNavigate();
  const { speciality_id } = useParams();

  const [speciality, setSpeciality] = useState<Speciality | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [original, setOriginal] = useState<Category[]>([]);

  const { data: categoriesInitData } = useQuery(categoriesInitQuery(speciality_id!));

  useEffect(() => {
    let mounted = true;
    if (mounted && categoriesInitData) {
      setCategories(categoriesInitData.speciality_categories);
      setOriginal(categoriesInitData.speciality_categories);
      setSpeciality(categoriesInitData.specialities_by_pk);
    }
    return () => { mounted = false; };
  }, [categoriesInitData]);

  const handleView = (id: string) => {
    navigate(`/admin/specialities/${speciality_id}/${id}`);
  };

  const handleCreate = () => {
    navigate(`/admin/specialities/${speciality_id}/create`);
  };

  const handleEdit = () => {
    navigate(`/admin/specialities/${speciality_id}/edit`);
  }

  const handleEditCategory = (id: string) => {
    navigate(`/admin/specialities/${speciality_id}/${id}/edit`);
  }

  const handleEditLayout = () => {
    setEditMode(true);
  }

  const handleSave = async () => {
    const variables = {
      objects: categories.map((i, order_index) => ({
        id: i.id,
        speciality_id,
        name: i.name,
        description: i.description,
        order_index,
    }))};
    await editCategoriesOrder(variables);
    setOriginal([...categories]);
    setEditMode(false);
  }

  const handleCancel = () => {
    setCategories([...original]);
    setEditMode(false);
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (destination && destination.index >= 0) {
      setCategories((items) => {
        const item = items[source.index];
        const spliced = items.filter((i) => i.id !== item.id);
        spliced.splice(destination.index, 0, item);
        return spliced;
      });
    }
  }

  return (
    <GeneralLayout
      breadcrumbs={[
        { label: 'Specialities', link: '/admin/specialities' },
        { label: speciality?.name || '' },
      ]}>
      <Grid container alignItems="stretch">
        <GridCard
          title={speciality?.name || ''}
          description={speciality?.description}
          svg={speciality?.icon ? `data:image/svg+xml;base64,${btoa(speciality?.icon)}` : undefined}
          gridSize={12}
          handleEdit={handleEdit} />
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">Categories</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="stretch">
                <Grid item xs={12}>
                  <Grid container>
                    {!editMode && (
                      <>
                        <GridActionCard
                          fill
                          gridSize={6}
                          label="Create new category"
                          onClick={handleCreate} />
                        {categories.length > 1 && (
                          <GridActionCard
                            fill
                            gridSize={6}
                            label="Edit ordering"
                            icon={<EditGridIcon color="primary" />}
                            onClick={handleEditLayout} />
                        )}
                      </>
                    )}
                    {editMode && (
                      <>
                        <GridActionCard
                          fill
                          gridSize={6}
                          label="Save"
                          icon={<SaveOutlined />}
                          onClick={handleSave} />
                        <GridActionCard
                          fill
                          gridSize={6}
                          label="Cancel"
                          icon={<CancelOutlined />}
                          onClick={handleCancel} />
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <SortableGrid
                    isEdit={editMode}
                    onDragEnd={onDragEnd}>
                      {categories.map((category, index) => ({
                        key: category.id,
                        component:
                          <GridCard
                            fill
                            noGrid
                            gridSize={6}
                            key={category.id}
                            title={category.name}
                            description={category.description}
                            svg={category.icon ? `data:image/svg+xml;base64,${btoa(category.icon)}` : undefined}
                            counter={editMode ? index + 1 : undefined}
                            counterLabel="Categories"
                            handleEdit={() => handleEditCategory(category.id)}
                            handleView={() => handleView(category.id)} />
                      }))}
                  </SortableGrid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GeneralLayout>
  );
};

export default ViewSpeciality;
