import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  GridSize,
  Tooltip,
  Typography,
} from '@mui/material';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  gridSize?: GridSize,
  noGrid?: boolean,
  fill?: boolean,
  title: string,
  subTitle?: string,
  description?: string,
  counter?: number,
  counterLabel?: string,
  svg?: string,
  icon?: React.ReactElement,
  editLabel?: string,
  viewLabel?: string,
  handleEdit?: () => void,
  handleView?: () => void,
}

const GridCard = ({ classes, gridSize = 3, noGrid = false, fill, title, subTitle, description, counter, counterLabel, svg, icon, editLabel = 'Edit', viewLabel = 'View', handleEdit, handleView }: Props): React.ReactElement => {
  const gridWrapper = (card: React.ReactElement) => {
    if (!noGrid) {
      return (
        <Grid item xs={gridSize} zeroMinWidth>
          {card}
        </Grid>
      );
    }
    return card;
  }

  return (
    gridWrapper(
      <Card className={`${classes.root} ${fill ? classes.fill : ''}`} variant="outlined">
        <CardContent className={classes.content}>
          {svg && (
            <div className={classes.icon}>
              <img src={svg} alt="icon" />
            </div>
          )}
          {icon && (
            <div className={classes.icon}>
              {icon}
            </div>
          )}
          <div className={classes.titleContainer}>
            <Typography className={classes.title} variant="h5" component="h2">{title}</Typography>
            <Typography className={classes.subTitle} variant="subtitle1">{subTitle}</Typography>
            <Typography className={classes.description} variant="subtitle2" color="textSecondary">{description}</Typography>
          </div>
          {counter !== null && (
            counterLabel
            ? <Tooltip className={classes.counterTooltip} title={counterLabel} placement="left">
                <Typography className={classes.counter} variant="h2" color="textSecondary">{counter}</Typography>
              </Tooltip>
            : <Typography className={classes.counter} variant="h2" color="textSecondary">{counter}</Typography>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          {handleView && (
            <Button size="small" variant="text" color="primary" onClick={handleView}>{viewLabel}</Button>
          )}
          {handleEdit && (
            <Button size="small" variant="text" color="primary" onClick={handleEdit}>{editLabel}</Button>
          )}
        </CardActions>
      </Card>)
  );
};

export default withStyles(styles)(GridCard);
