import React, { useContext } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import {
  Avatar,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { AuthContext } from '../../../../components/AuthProvider';
import { Breadcrum } from '../../../Dashboard';
import { wordsToAcronym } from '../../../../utils/stringUtils';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  title?: string,
  breadcrumbs?: Breadcrum[],
}

const Topbar = ({ classes, title, breadcrumbs }: Props): React.ReactElement => {
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }

  const { user } = authContext;

  return (
    <>
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          {title && (
            <Typography data-qa="topbar-title" className={classes.title} variant="h2">{title}</Typography>
          )}
          {breadcrumbs && (
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs.slice(0, -1).map((b) => {
                return b.link
                  ? <Link key={b.label} underline="hover" color="inherit" href={b.link}>{b.label}</Link>
                  : <Typography key={b.label} color="inherit">{b.label}</Typography>
              })}
              <Typography color="textPrimary" aria-current="page">{breadcrumbs.slice(-1)[0].label}</Typography>
            </Breadcrumbs>
          )}
          <div className={classes.toolbarButtons}>
            <Typography color="textPrimary">{user?.full_name}</Typography>
            <Avatar className={classes.toolbarAvatar} variant="rounded">
              {user ? wordsToAcronym(user.full_name) : ''}
            </Avatar>
          </div>
        </Toolbar>
      </div>
    </>
  );
};

export default withStyles(styles)(Topbar);
