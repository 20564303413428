import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import UploadFileContainer from '../../../../../../../components/UploadFileContainer';
import { Category, CategoryReducerAction } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  category: Category,
  dispatch: React.Dispatch<{ type: CategoryReducerAction, value: any }>,
}

const StepIcon = ({ classes, category, dispatch }: Props): React.ReactElement => {
  const handleIconFile = (file: string) => {
    if (file) {
      const bin = file.replace('data:image/svg+xml;base64,', '');
      const nodeString = atob(bin);
      const div = document.createElement('div');
      div.innerHTML = nodeString;
      const svg = div.children[0];
      svg.removeAttribute('width');
      svg.removeAttribute('height');
      svg.querySelectorAll('path, rect, line, polygon, circle, ellipse').forEach((i) => {
        i.setAttribute('fill', '#000000');
        let style = i.getAttribute('style');
        if (style) {
          style = style.replace(/(fill:)([#a-zA-z0-9]*)(;)/g, '$1#000000$3').replace(/(stroke:)([#a-zA-z0-9]*)(;)/g, '$1#FFFFFF$3');
          i.setAttribute('style', style);
        }
      });
      dispatch({ type: CategoryReducerAction.ICON, value: svg.outerHTML });
    };
  }

  return (
    <>
      <UploadFileContainer
        accept="image/svg+xml"
        label="Upload SVG icon"
        handleUpload={handleIconFile}>
        {category.icon && (<img className={classes.icon} src={`data:image/svg+xml;base64,${btoa(category.icon)}`} alt=""/>)}
      </UploadFileContainer>
    </>
  );
};

export default withStyles(styles)(StepIcon);
