import React from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';


const Logo = (): React.ReactElement => {
  const [darkMode] = useLocalStorage<boolean>('dark-mode', false);

  const textColour = darkMode ? '#FFFFFF' : '#020202';

  return (
    <svg id="AG_LOGO" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 130">
      <path style={{ fill: textColour }} d="M154.5,50.4c0.7,0.7,1.1,1.7,1.1,2.9v42.4c0,7.3-1.9,12.8-5.6,16.5c-3.7,3.7-9.3,5.5-16.6,5.5c-7.1,0-13.1-1.4-17.8-4.2c-2.4-1.3-3.6-2.9-3.6-4.6c0-0.9,0.2-1.7,0.8-2.3c0.5-0.6,1.1-0.9,2-0.9c0.7,0,1.9,0.5,3.6,1.4c2.3,1.2,4.6,2.2,6.9,2.9c2.3,0.7,5,1.1,8.1,1.1c4.7,0,8.3-1.2,10.7-3.8s3.6-6.2,3.6-11v-8.6c-1.4,3.1-3.6,5.5-6.5,7.2c-2.9,1.7-6.3,2.5-10.2,2.5c-4.3,0-8-1-11.4-3c-3.3-2-5.9-4.8-7.7-8.5c-1.8-3.7-2.8-7.9-2.8-12.7c0-4.8,0.9-9,2.8-12.7c1.8-3.7,4.4-6.5,7.7-8.5c3.3-2,7.1-3,11.4-3c3.9,0,7.2,0.8,10.1,2.5c2.9,1.7,5,4,6.5,7.1v-5.4c0-1.2,0.4-2.2,1-2.9c0.7-0.7,1.7-1.1,3-1.1C152.7,49.3,153.7,49.7,154.5,50.4z M143.5,86.2c2.7-3.1,4-7.4,4-12.9c0-5.4-1.3-9.6-4-12.7c-2.6-3.1-6.3-4.6-11-4.6c-4.7,0-8.5,1.6-11.1,4.6c-2.7,3.1-4.1,7.4-4.1,12.7c0,5.5,1.4,9.8,4.1,12.9c2.7,3.1,6.4,4.6,11.1,4.6C137.1,90.8,140.8,89.3,143.5,86.2z"/>
      <path style={{ fill: textColour }} d="M170.8,37.4c-1-0.9-1.5-2.2-1.5-3.7c0-1.5,0.5-2.7,1.5-3.6c1-0.9,2.3-1.4,3.9-1.4c1.6,0,2.9,0.5,3.9,1.4c1,0.9,1.5,2.1,1.5,3.6c0,1.5-0.5,2.8-1.5,3.7c-1,0.9-2.3,1.4-3.9,1.4C173.1,38.8,171.8,38.3,170.8,37.4z M171.7,98.5c-0.8-0.7-1.2-1.7-1.2-3V53.3c0-1.3,0.4-2.2,1.2-2.9c0.8-0.7,1.8-1.1,2.9-1.1c1.2,0,2.2,0.4,2.9,1.1c0.7,0.7,1.1,1.7,1.1,2.9v42.2c0,1.3-0.4,2.3-1.1,3c-0.7,0.7-1.7,1-2.9,1C173.5,99.5,172.5,99.2,171.7,98.5z"/>
      <path style={{ fill: textColour }} d="M194.9,98.5c-0.8-0.7-1.2-1.7-1.2-3V32c0-1.3,0.4-2.2,1.2-3c0.8-0.7,1.8-1,2.9-1c1.1,0,2.1,0.4,2.9,1c0.8,0.7,1.1,1.7,1.1,3v63.5c0,1.3-0.4,2.2-1.1,3c-0.8,0.7-1.7,1-2.9,1C196.7,99.5,195.7,99.2,194.9,98.5z"/>
      <path style={{ fill: textColour }} d="M256.6,88.6c0.5,0.6,0.8,1.4,0.8,2.3c0,1.7-1.2,3.2-3.5,4.6c-2.4,1.4-4.9,2.5-7.6,3.2c-2.7,0.7-5.3,1-7.8,1c-7.5,0-13.5-2.2-17.8-6.7c-4.4-4.5-6.6-10.6-6.6-18.5c0-5,1-9.4,2.9-13.2c1.9-3.8,4.7-6.8,8.2-9c3.5-2.1,7.5-3.2,11.9-3.2c6.3,0,11.4,2.1,15.1,6.2c3.7,4.1,5.6,9.7,5.6,16.8c0,1.3-0.3,2.3-0.8,2.9c-0.5,0.6-1.4,0.9-2.6,0.9h-32.2c0.6,11.5,6,17.2,16.3,17.2c2.6,0,4.8-0.4,6.7-1.1c1.9-0.7,3.9-1.6,6-2.8c1.7-1,2.9-1.5,3.5-1.5C255.4,87.7,256,88,256.6,88.6z M226.9,59.4c-2.6,2.7-4.1,6.4-4.5,11.3h28.3c-0.1-4.9-1.4-8.7-3.7-11.4c-2.3-2.6-5.6-4-9.8-4C232.9,55.4,229.5,56.7,226.9,59.4z"/>
      <path style={{ fill: textColour }} d="M396.1,68.3v27.2c0,1.2-0.4,2.2-1,2.9c-0.7,0.7-1.7,1.1-2.9,1.1s-2.2-0.4-2.9-1.1c-0.7-0.7-1.1-1.7-1.1-2.9v-4.8c-1.3,2.9-3.2,5.1-5.8,6.7s-5.6,2.3-9,2.3c-3.1,0-6-0.6-8.7-1.9c-2.6-1.3-4.7-3-6.2-5.3c-1.5-2.3-2.3-4.8-2.3-7.5c0-3.7,0.9-6.5,2.8-8.5c1.9-2,4.9-3.5,9.2-4.4c4.3-0.9,10.2-1.3,17.8-1.3h2.2v-3.3c0-4-0.8-6.9-2.5-8.8c-1.6-1.9-4.3-2.8-7.9-2.8c-4.5,0-9.1,1.2-13.8,3.7c-1.8,1.1-3.1,1.7-3.8,1.7c-0.8,0-1.5-0.3-2-0.9c-0.5-0.6-0.8-1.4-0.8-2.3c0-0.9,0.3-1.8,0.8-2.5c0.6-0.7,1.5-1.4,2.8-2.1c2.3-1.3,5-2.4,8-3.1c3-0.8,5.9-1.1,8.9-1.1C390,49.1,396.1,55.5,396.1,68.3z M384.3,89.3c2.6-2.7,3.8-6.2,3.8-10.5v-2.9h-1.8c-5.9,0-10.3,0.2-13.4,0.8s-5.2,1.3-6.5,2.5c-1.3,1.2-2,2.9-2,5.1c0,2.6,1,4.8,2.9,6.5c1.9,1.7,4.3,2.5,7.1,2.5C378.5,93.3,381.7,92,384.3,89.3z"/>
      <path style={{ fill: textColour }} d="M436.9,96.3c0,1.1-0.4,2-1.2,2.6c-0.8,0.6-2.1,0.8-3.8,0.6l-2.7-0.2c-5.3-0.4-9.3-2-11.8-4.8c-2.5-2.8-3.8-7-3.8-12.7V56.7h-6.7c-1.1,0-2-0.3-2.7-0.9c-0.6-0.6-0.9-1.3-0.9-2.2c0-1,0.3-1.8,0.9-2.4c0.6-0.6,1.5-0.9,2.7-0.9h6.7V39c0-1.3,0.4-2.2,1.1-3c0.7-0.7,1.7-1,3-1c1.2,0,2.2,0.4,2.9,1c0.7,0.7,1.1,1.7,1.1,3v11.3h11.1c1.1,0,1.9,0.3,2.5,0.9c0.6,0.6,1,1.4,1,2.4c0,0.9-0.3,1.7-1,2.2c-0.6,0.6-1.5,0.9-2.5,0.9h-11.1v25.5c0,3.7,0.8,6.3,2.2,8c1.5,1.6,3.8,2.5,6.8,2.8l2.7,0.2C435.7,93.3,436.9,94.4,436.9,96.3z"/>
      <path style={{ fill: textColour }} d="M482.5,54c2.9,3.2,4.4,8,4.4,14.4v27.1c0,1.3-0.4,2.2-1.1,3c-0.7,0.7-1.7,1-2.9,1c-1.3,0-2.3-0.3-3-1c-0.7-0.7-1.1-1.7-1.1-3V68.6c0-4.3-0.9-7.5-2.7-9.6c-1.8-2.1-4.6-3.1-8.5-3.1c-4.5,0-8,1.4-10.7,4.1c-2.7,2.7-4,6.4-4,11.1v24.4c0,1.3-0.3,2.2-1,3s-1.7,1-3,1c-1.3,0-2.3-0.3-3-1c-0.7-0.7-1.1-1.7-1.1-3V31.9c0-1.1,0.4-2.1,1.2-2.8c0.8-0.7,1.8-1.1,2.9-1.1c1.3,0,2.2,0.4,3,1c0.7,0.7,1,1.7,1,2.9v26.2c1.6-2.9,3.8-5.2,6.7-6.7c2.9-1.5,6.1-2.3,9.8-2.3C475.2,49.1,479.5,50.7,482.5,54z"/>
      <path style={{ fill: textColour }} d="M302.7,58.8c6.4,0,12.8,0,19.2,0c4.1-0.1,7.9-1.3,10.8-4.4c1.9-2,3.3-4.4,3.7-7.1c0.6-4.4-0.5-8.4-3.6-11.7c-1.7-1.8-3.7-3.2-6.2-3.8c-1.4-0.3-2.9-0.6-4.3-0.6c-2.3,0-4.6,0-7.1,0c-3.2,0-5.7-2.3-6.6-4.9c-1.5-4,2.1-8.8,6.4-8.8c3.1,0,6,0,8.9,0c5.3,0,10.1,1.6,14.4,4.6c4.8,3.4,8.3,7.8,10.4,13.3c0.9,2.5,1.4,5.1,1.5,7.8c0.1,3.3-0.1,6.5-1,9.6c-1.1,3.6-2.7,6.9-5.2,9.6c-2.3,2.4-4.5,4.9-7.5,6.6c-3.6,2-7.5,3.4-11.6,3.4c-9,0.1-18,0-27,0c-0.4,0-0.7,0-1.1,0c-1.3,0-1.6,0.3-1.6,1.7c0,4,0,8,0,12c0,4.1,0,8.2,0,12.3c0,1.5-0.2,1.7-1.8,1.7c-3.4,0-6.8,0-10.2,0c-1.4,0-1.6-0.2-1.6-1.6c0-12.7,0-25.4,0-38.1c0-1.4,0.2-1.6,1.6-1.6C289.6,58.8,296.1,58.8,302.7,58.8z"/>
      <path style={{ fill: textColour }} d="M89.1,63.6c0.4,5.2-5,8.5-9.8,6.3c-1.5-0.7-2.5-1.9-3.3-3.3C73,60.5,70,54.4,66.9,48.4c-0.2-0.4-0.6-0.8-0.9-1.2c-0.3,0.4-0.7,0.7-0.9,1.1c-2,3.9-4,7.8-5.9,11.7C53,72.3,46.8,84.7,40.6,97c-0.4,0.9-1,1.3-2,1.3c-4.1,0-8.2,0-12.3,0c-1.3,0-1.6-0.4-0.9-1.6c2.6-5.2,5.2-10.5,7.8-15.7c1.4-2.8,2.8-5.6,4.2-8.4c1.5-2.9,3-5.8,4.5-8.7c1.6-3.1,3.1-6.3,4.7-9.5c1.9-3.8,3.8-7.5,5.7-11.2c2-4,3.9-8,5.8-12c1-1.9,2-3.8,3-5.7c1.4-2.7,2.7-5.4,4.1-8.1c0.1-0.2,0.2-0.4,0.3-0.6c0.3-0.5,0.9-0.5,1.2,0.1c0.6,1.1,1.2,2.2,1.8,3.4c2.2,4.4,4.4,8.7,6.5,13.1c2.3,4.7,4.7,9.4,7,14.1c2.1,4.3,4.3,8.6,6.4,12.9C88.9,61.4,89.3,62.5,89.1,63.6z"/>
      <circle style={{ fill: textColour }} cx="288.3" cy="24.2" r="6.7"/>
      <circle style={{ fill: textColour }} cx="94.3" cy="91.1" r="6.7"/>
    </svg>
  );
};

export default Logo;
