import React, { useState, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';

import GeneralLayout from '../../../../../layouts/General';

import CustomBackdrop from '../../../../../components/CustomBackdrop';
import Steps from '../Common/components/Steps';
import { categoryReducer, CategoryReducerAction } from '../Common';

import { editCategory } from './mutations';
import { editCategoriesInitQuery, Speciality } from './queries';

const EditCategory = (): React.ReactElement => {
  const navigate = useNavigate();
  const { speciality_id, category_id } = useParams();

  const [category, dispatchCategory] = useReducer(categoryReducer, {
    name: '',
    description: '',
    icon: '',
  });

  const [speciality, setSpeciality] = useState<Speciality | null>(null);
  const [saving, setSaving] = useState<boolean>(false);

  const { data: editCategoriesInitData } = useQuery(editCategoriesInitQuery(speciality_id!, category_id!));

  useEffect(() => {
    let mounted = true;
    if (mounted && editCategoriesInitData) {
      setSpeciality(editCategoriesInitData.specialities_by_pk);
      dispatchCategory({ type: CategoryReducerAction.INIT, value: editCategoriesInitData.speciality_categories_by_pk });
    }
    return () => { mounted = false; };
  }, [editCategoriesInitData]);

  const handleSave = async () => {
    setSaving(true);
    const updateVariables = {
      pk_columns: {
        id: category_id,
      },
      set: category,
    };

    await editCategory(updateVariables);

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/specialities/${speciality_id}/${category_id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Specialities', link: '/admin/specialities' },
          { label: `${speciality?.name}`, link: `/admin/specialities/${speciality_id}` },
          { label: `${category?.name}`, link: `/admin/specialities/${speciality_id}/${category_id}` },
          { label: 'Edit' },
        ]}>
        <Steps
          completeLabel="Save"
          category={category}
          dispatch={dispatchCategory}
          handleSave={handleSave}/>
      </GeneralLayout>
      {saving && <CustomBackdrop label="Saving Category" />}
    </>
  );
};

export default EditCategory;
