import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import GeneralLayout from '../../../../layouts/General';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { organisationReducer, OrganisationReducerAction, OrganisationType } from '../Common';
import Steps from '../Common/components/Steps';

import { editOrganisationInitQuery, Lab, LabAssignment } from './queries';
import { updateOrganisation, updateOrganisationLabAssignments, createOrganisationType } from './mutations';

const EditOrganisation = (): React.ReactElement => {
  const navigate = useNavigate();
  const { organisation_id } = useParams();

  const [organisation, dispatchOrganisation] = useReducer(organisationReducer, {
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_postcode: '',
    contact: '',
    organisation_type: '',
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [labs, setLabs] = useState<Lab[]>([]);
  const [assignedLabs, setAssignedLabs] = useState<Lab[]>([]);
  const [assignedOriginalLabs, setAssignedOriginalLabs] = useState<LabAssignment[]>([]);
  const [organisationTypes, setOrganisationTypes] = useState<OrganisationType[]>([]);
  const [isNewOrganisationType, setIsNewOrganisationType] = useState<boolean>(false);

  const { data: editOrganisationInitData } = useQuery(editOrganisationInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editOrganisationInitData) {
      const org = editOrganisationInitData.organisations_by_pk;

      dispatchOrganisation({ type: OrganisationReducerAction.INIT, value: org });
      setLabs(editOrganisationInitData.labs);
      setAssignedLabs(org.lab_assignments.map((i: LabAssignment) => i.lab));
      setAssignedOriginalLabs(org.lab_assignments);
      setOrganisationTypes(editOrganisationInitData.enum_organisation_types);
    }
    return () => { mounted = false; };
  }, [editOrganisationInitData]);

  const handleSave = async () => {
    setSaving(true);

    if (isNewOrganisationType) {
      const updateOrganisationTypeVariables = {
        object: {
          organisation_type: organisation.organisation_type,
        },
      }
      await createOrganisationType(updateOrganisationTypeVariables);
    }

    const updateOrganisationVariables = {
      pk_columns: {
        id: organisation_id,
      },
      set: organisation,
    };
    await updateOrganisation(updateOrganisationVariables);

    const organisationLabAssignments = assignedLabs.filter((lab: Lab) => !assignedOriginalLabs.some((i: LabAssignment) => lab.id === i.lab.id)).map((lab: Lab) => ({ lab_id: lab.id, organisation_id }));
    const organisationLabAssignmentsRemove = assignedOriginalLabs.filter((i: LabAssignment) => !assignedLabs.some((lab: Lab) => lab.id === i.lab.id)).map((i: LabAssignment) => i.id);

    await updateOrganisationLabAssignments(organisationLabAssignments, organisationLabAssignmentsRemove);

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    navigate(`/admin/organisations/${organisation_id}`);
  };

  return (
    <>
      <GeneralLayout
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Edit' },
        ]}>
        <Steps
          completeLabel="Save"
          organisation={organisation}
          assignedLabs={assignedLabs}
          labs={labs.map((i: Lab) => ({ id: i.id, label: i.name }))}
          organisationTypes={organisationTypes}
          dispatch={dispatchOrganisation}
          setIsNewOrganisationType={setIsNewOrganisationType}
          setAssignedLabs={setAssignedLabs}
          handleSave={handleSave} />
      </GeneralLayout>
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default EditOrganisation;
