import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectLabUserInitQuery = (lab_id: string, user_id: string) => ({
  labs_by_pk: params(
    { id: rawString(lab_id) },
    {
      name: types.string,
    },
  ),
  users_receiver_by_pk: params(
    { id: rawString(user_id) },
    {
      id: types.string,
      full_name: types.string,
      email: types.string,
      hcpc_number: types.string,
      dob: types.string,
      gender: types.string,
      labs: [{
        lab: {
          name: types.string,
        },
      }],
      enum_receiver_type: {
        receiver_type: types.string,
        description: types.string,
      },
    },
  ),
  enum_receiver_types: [{
  	receiver_type: types.string,
    description: types.string,
  }],
});

type X = Unpacked<typeof _selectLabUserInitQuery>
export type Lab = X['labs_by_pk'];
export type LabUser = X['users_receiver_by_pk'];
export type LabAssignment = LabUser['labs'][0];
export type EnumReceiverType = X['enum_receiver_types'][0];

export const selectLabUserInitQuery = (lab_id: string, user_id: string) => gql`${query(_selectLabUserInitQuery(lab_id, user_id))}`;
