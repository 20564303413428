export interface Patient {
  nhs_number: string,
  mrn: string,
  first_name: string,
  last_name: string,
  dob: Date | null,
}

export interface ValidationErrors {
  nhs_number: string | boolean,
  nhs_number_length: string | boolean,
  mrn: string | boolean,
  mrn_length: string | boolean,
  first_name: string | boolean,
  last_name: string | boolean,
  dob: string | boolean,
}

export const validationErrors = {
  nhs_number: 'Please provide an NHS number',
  nhs_number_length: 'NHS number must be 10 characters long',
  nhs_number_details: 'Unable to fetch patient details for this NHS number',
  mrn: 'Please provide an MRN',
  mrn_length: 'MRN must be no more than 20 characters long',
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  dob: 'Please provide a date of birth',
}

export interface Validation {
  nhs_number: (value: string) => string | boolean,
  mrn: (value: string) => string | boolean,
  first_name: (value: string) => string | boolean,
  last_name: (value: string) => string | boolean,
  dob: (value: Date | null) => string | boolean,
}

export const validation: Validation = {
  nhs_number: (value) => {
    if (!value) {
      return validationErrors.nhs_number;
    }
    if (value.length < 10 || value.length > 10) {
      return validationErrors.nhs_number_length;
    }
    return false;
  },
  mrn: (value) => {
    if (!value) {
      return validationErrors.mrn;
    }
    if (value.length > 20) {
      return validationErrors.mrn_length;
    }
    return false;
  },
  first_name: (value) => {
    if (!value) {
      return validationErrors.first_name;
    }
    return false;
  },
  last_name: (value) => {
    if (!value) {
      return validationErrors.last_name;
    }
    return false;
  },
  dob: (value) => {
    if (!value) {
      return validationErrors.dob;
    }
    return false;
  },
}

export enum PatientReducerAction {
  NHS_NUMBER,
  MRN,
  FIRST_NAME,
  LAST_NAME,
  DOB,
}

export const patientReducer = (state: Patient, action: { type: PatientReducerAction, value: any }): Patient =>  {
  switch (action.type) {
    case PatientReducerAction.NHS_NUMBER:
      return { ...state, nhs_number: `${action.value.replace(/[\D]/g, '')}` };
    case PatientReducerAction.MRN:
      return { ...state, mrn: `${action.value.replace(/[^A-Za-z0-9]/g, '')}` };
    case PatientReducerAction.FIRST_NAME:
      return { ...state, first_name: action.value };
    case PatientReducerAction.LAST_NAME:
      return { ...state, last_name: action.value };
    case PatientReducerAction.DOB:
      return { ...state, dob: action.value };
    default:
      throw new Error();
  }
}
