
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import gqlClient from '../../utils/apolloClient';

const _getUserRoleQuery = (id: string) => ({
  user_roles: params(
    { where: { user_id: { _eq: rawString(id) } } },
    [{
      role: types.string,
    }],
  )
});

export const getUserRoleQuery = async (id: string) => await gqlClient.query<{
  user_roles: [{
    role: string,
  }]
}>({ query: gql`${query(_getUserRoleQuery(id))}`! });

const _getAdminUserQuery = (id: string) => ({
  users_admin: params(
    { where: { user_id: { _eq: rawString(id) } } },
    [{
      id: types.string,
      email: types.string,
      first_name: types.string,
      last_name: types.string,
      full_name: types.string,
      gender: types.string,
      user_id: types.string,
    }],
  )
});

export const getAdminUserQuery = async (id: string) => await gqlClient.query<{
  users_admin: [{
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    full_name: string,
    gender: string,
    user_id: string,
  }]
}>({ query: gql`${query(_getAdminUserQuery(id))}`! });

const _getReceiverUserQuery = (id: string) => ({
  users_receiver: params(
    { where: { user_id: { _eq: rawString(id) } } },
    [{
      id: types.string,
      email: types.string,
      first_name: types.string,
      last_name: types.string,
      full_name: types.string,
      gender: types.string,
      user_id: types.string,
    }],
  )
});

export const getReceiverUserQuery = async (id: string) => await gqlClient.query<{
  users_receiver: [{
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    full_name: string,
    gender: string,
    user_id: string,
  }]
}>({ query: gql`${query(_getReceiverUserQuery(id))}`! });

const _getSenderUserQuery = (id: string) => ({
  users_sender: params(
    { where: { user_id: { _eq: rawString(id) } } },
    [{
      id: types.string,
      email: types.string,
      first_name: types.string,
      last_name: types.string,
      full_name: types.string,
      gender: types.string,
      user_id: types.string,
    }],
  )
});

export const getSenderUserQuery = async (id: string) => await gqlClient.query<{
  users_sender: [{
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    full_name: string,
    gender: string,
    user_id: string,
  }]
}>({ query: gql`${query(_getSenderUserQuery(id))}`! });
